import {useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import classnames from "classnames";
import dayjs from "dayjs";
import {useLocalStorage} from "react-use";

import {CompanyPost} from "./company-post";
import {PersonalPost, postCookieDeserializer} from "./personal-post";
import {
	clearTypename,
	GET_SHARE,
	loadShare,
	OpenGraphs,
	Service,
	services,
	Share,
	useMyUser,
} from "../../../data";
import {useToast} from "../../../toast";
import {Span} from "../../../components/text";
import {ContentLibrary} from "../components/library";
import {Button} from "../../../components/input";
import {Icon} from "../../../components/images";
import {Loading} from "../../../components/loading";
import {GET_RSS_FEED_POST} from "../../../data/rssFeed";
import {Page} from "../../../layout";
import {useWindowDeviceSize} from "../../../device-size";
import {PostsPreview} from "../components/posts-preview";
import {FormValues, Schedule} from "./form/types";
import {useDebounceCallback} from "../../../hooks/use-debounce-callback";

import styles from "./post.module.scss";

const bStyles = classnames.bind(styles);

const clearOpengraphTypename = ogs => {
	if (!ogs) return {};
	const ogsCleared = clearTypename(ogs);
	return Object.keys(ogsCleared).reduce((acc, el) => {
		if (!ogsCleared[el]) return acc;
		const og = {...ogsCleared[el]};
		if (og.images) {
			og.images = og.images.map(el => clearTypename(el));
		}
		return {...acc, [el]: og};
	}, {});
};

export const Post = () => {
	const {id} = useParams<{id: `${number}` | "new"}>();
	const {state} = useLocation();
	const navigate = useNavigate();
	const toast = useToast();
	const windowSize = useWindowDeviceSize();
	const [advanced, setAdvanced] = useLocalStorage("post-builder-advanced", false);
	const [advancedTab, setAdvancedTab] = useState<"library" | "preview">("library");
	const [shareFromUrl, setShareFromUrl] = useState<string | undefined>(state?.url);
	const [postData, setPostData] = useState<
		{opengraphs: OpenGraphs; networks: Service[]; schedule?: Schedule; url?: string | null} | undefined
	>();
	const isShareAction = state?.action === "share";
	const isNew = id === "new" || isShareAction;
	const fromPostId = state?.id;
	const rss = state?.rss;
	const me = useMyUser();

	const {data, loading} = useQuery(GET_SHARE, {
		variables: {id},
		fetchPolicy: "cache-first",
		skip: isNew,
		onError: error => toast({color: "red", text: error.message}),
	});
	const {data: parentPost, loading: loadingParentPost} = useQuery(rss ? GET_RSS_FEED_POST : GET_SHARE, {
		variables: {id: fromPostId},
		fetchPolicy: "network-only",
		skip: !fromPostId,
		onError: error => toast({color: "red", text: error.message}),
	});
	const parentPostUrl = useMemo(
		() => (parentPost ? (rss ? parentPost.rssFeedPost?.url : parentPost.share.url) : undefined),
		[parentPost, rss]
	);
	const parentPostOpengraph = useMemo(
		() =>
			parentPost ? (rss ? {general: parentPost.rssFeedPost?.item} : parentPost.share?.opengraphs) : undefined,
		[parentPost, rss]
	);
	const availableNetworks = useMemo(
		() => services.filter(s => me.connections[s === "facebook" ? "facebookPage" : s]?.connected),
		[me.connections]
	);

	const [newPostCookie] = useLocalStorage(`new-post-personal-${me.id}`, null, {
		raw: false,
		serializer: JSON.stringify,
		deserializer: postCookieDeserializer({[me.id]: availableNetworks}),
	});

	const loadedShare = useMemo(
		() =>
			(data?.share && !isShareAction
				? loadShare(data.share)
				: {
						url: parentPostUrl,
						opengraphs: {
							general: {
								comment: "",
							},
							...clearOpengraphTypename(parentPostOpengraph),
						},
						shareEvents: [],
				  }) as Share,
		[data?.share, parentPostOpengraph, parentPostUrl, isShareAction]
	);
	const isExpired = useMemo(() => data?.share?.expiresAt && dayjs(data.share.expiresAt).isBefore(dayjs()), [
		data?.share?.expiresAt,
	]);

	const [newPostTypeCookie, setNewPostTypeCookie] = useLocalStorage<"personal" | "company" | undefined>(
		`new-post-type`,
		"personal"
	);

	const personal = useMemo(() => {
		if (!isNew) return !!loadedShare.userId;
		if (state?.type) return state?.type === "personal";
		return newPostTypeCookie === "personal";
	}, [isNew, loadedShare.userId, state?.type, newPostTypeCookie]);

	useEffect(() => {
		if (state?.type && state?.type !== newPostTypeCookie) {
			setNewPostTypeCookie(state?.type);
		}
	}, [state?.type, newPostTypeCookie, setNewPostTypeCookie]);

	const excludedUrls = useMemo(
		() => [loadedShare.url, shareFromUrl, newPostCookie?.url].filter(Boolean) as string[],
		[loadedShare.url, shareFromUrl, newPostCookie?.url]
	);
	const goBack = useCallback(() => navigate("/collections/posts"), [navigate]);
	const isLoading = useMemo(() => loading || (fromPostId ? loadingParentPost : false), [
		loading,
		loadingParentPost,
		fromPostId,
	]);
	const askLoadUnsaved = state?.askLoadUnsaved;

	const onPostDataChange = useCallback((data: FormValues) => {
		setPostData({
			opengraphs: data.opengraphs,
			url: data.url,
			networks: services.filter(s => !!data?.schedule?.[s]),
			schedule: data.schedule,
		});
	}, []);
	const onPostDataChangeDebounced = useDebounceCallback(onPostDataChange, 1000);
	const showPostsPreview = useMemo(
		() =>
			me.org?.options?.upcomingFeaturesEnabled &&
			me.connections?.tiktok?.connected &&
			postData?.networks?.includes("tiktok"),
		[me.org?.options, me.connections, postData]
	);

	return (
		<Page
			className={styles.postPage}
			title={isNew || isLoading ? "Builder" : `${personal ? "Personal" : "Company"} Post`}
			parentPath="/collections"
			{...(isNew
				? {
						tabs: [
							...(me.role === "admin" ? [{name: "Collections", path: "/collections/list"}] : []),
							{name: "Posts", path: "/collections/posts"},
						],
				  }
				: {returnTo: "/collections/posts"})}
			fullWidth={true}
		>
			<div className={styles.postContainer}>
				{isLoading && <Loading />}
				{!isLoading && (
					<>
						<div className={styles.post}>
							{isNew && (
								<div className={styles.control}>
									<Span className={styles.postHeader}>{`Create a ${
										personal ? "Personal" : "Company"
									} Post`}</Span>
									<Button
										invert
										border={false}
										onClick={() => setAdvanced(!advanced)}
										value={
											<div className={bStyles(styles.advancedBtn, {[styles.advanced]: advanced})}>
												<Icon
													color={"blue"}
													className={styles.advancedBtnIcon}
													icon={advanced ? "chevron-up" : "chevron-down"}
												/>
												<Span className={styles.advancedBtnText}>
													{advanced ? "Simple Builder" : "Advanced Builder"}
												</Span>
											</div>
										}
									/>
								</div>
							)}
							{personal ? (
								<PersonalPost
									askLoadUnsaved={askLoadUnsaved}
									share={loadedShare}
									shareFromUrl={shareFromUrl}
									setShareFromUrl={setShareFromUrl}
									onDataChange={onPostDataChangeDebounced}
									onComplete={goBack}
									disabled={isExpired}
									disableContentEditing={
										me.role === "user" &&
										me.org.options?.preventPostEditing &&
										(data?.share?.fromCollection || parentPost?.share?.fromCollection)
									}
								/>
							) : (
								<CompanyPost
									askLoadUnsaved={askLoadUnsaved}
									share={loadedShare}
									shareFromUrl={shareFromUrl}
									setShareFromUrl={setShareFromUrl}
									onDataChange={onPostDataChangeDebounced}
									onComplete={goBack}
									disabled={isExpired}
								/>
							)}
						</div>
						{isNew && advanced && (
							<div
								className={bStyles(styles.hidableContainer, {[styles.floating]: windowSize !== "desktop"})}
							>
								<div className={styles.advancedTabs}>
									<Button
										invert
										color={advancedTab === "library" ? "pink" : "grey"}
										border={false}
										onClick={() => setAdvancedTab("library")}
										value={"Content Library"}
									/>
									{showPostsPreview && (
										<>
											<div className={styles.separator} />
											<Button
												invert
												color={advancedTab === "preview" ? "pink" : "grey"}
												border={false}
												onClick={() => setAdvancedTab("preview")}
												value={"Post Previews"}
											/>
										</>
									)}
								</div>
								{advancedTab === "preview" ? (
									<PostsPreview post={postData} />
								) : (
									<ContentLibrary
										type={"Post"}
										onAdd={({url}) => setShareFromUrl(url)}
										excludedUrls={excludedUrls}
									/>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</Page>
	);
};
