import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {Dayjs} from "dayjs";

import {User} from "./user";
import {Option} from "../components/input";

export interface Group {
	id: number;
	name: string;
	active: boolean;
	approveCollections: boolean;
	createCollections: boolean;
	sendAll: boolean;
	addContent: boolean;
	setPrimaryAdmin: boolean;
	manageGroups: boolean;
	manageSocialMediaConnection: boolean;
	editCompanyBranding: boolean;
	manageCompanyIds: number[];
	queueCompanyIds: number[];
	addUserGroupIds: number[];
	queueGroupIds: number[];
	resendLoginGroupIds: number[];
	users: Partial<User>[];
	queues: number;
	vas: number;
	followers: number;
	editors: number;
	editorsVAS: number;
	followersVAS: number;
	lastCollection?: Dayjs;
	allowSubscription: boolean;
}

export const GROUP_FRAGMENT = gql`
	fragment GroupFields on Group {
		id
		name

		approveCollections
		createCollections
		sendAll
		addContent
		manageGroups
		manageCompanyIds
		manageSocialMediaConnection
		editCompanyBranding
		queueCompanyIds
		addUserGroupIds
		queueGroupIds
		resendLoginGroupIds
		approveCollections
		users {
			id
			firstName
			lastName
			role
			virtualAssistant
			profilePic
		}
		setPrimaryAdmin
		allowSubscription
	}
`;

export const SIMPLE_GROUP_FRAGMENT = gql`
	fragment SimpleGroupFields on Group {
		id
		name
		followers
		editors
		editorsVAS
		followersVAS
		lastCollection
		queues
		vas
	}
`;

export const GET_GROUPS = gql`
	query Groups(
		$limit: PositiveInt
		$cursor: String
		$filter: [GroupFilterTypes!]
		$search: String
		$sort: GroupSort
	) {
		groupsPaginated(limit: $limit, cursor: $cursor, filter: $filter, search: $search, sort: $sort) {
			items {
				...SimpleGroupFields
			}
			cursor
		}
	}
	${SIMPLE_GROUP_FRAGMENT}
`;

export const GET_GROUP = gql`
	query Group($id: PositiveInt!) {
		group(id: $id) {
			...GroupFields
		}
	}
	${GROUP_FRAGMENT}
`;

export const GET_SIMPLE_GROUPS = gql`
	query SimpleGroups {
		groupsPaginated(limit: null) {
			items {
				name
				id
				queueGroupIds
				queueCompanyIds
				allowSubscription
			}
		}
	}
`;

export const CREATE_GROUP = gql`
	mutation CreateGroup($name: String!) {
		createGroup(name: $name) {
			...SimpleGroupFields
		}
	}
	${SIMPLE_GROUP_FRAGMENT}
`;

export const UPDATE_GROUP = gql`
	mutation UpdateGroup($id: PositiveInt!, $changes: UpdateGroup!) {
		updateGroup(id: $id, changes: $changes) {
			...GroupFields
		}
	}
	${GROUP_FRAGMENT}
`;

export const DELETE_GROUP = gql`
	mutation DeleteGroup($id: PositiveInt!) {
		deleteGroup(id: $id) {
			...GroupFields
		}
	}
	${GROUP_FRAGMENT}
`;

export const useSimpleGroup = (): {
	groups: (Option<number> & {allowSubscription?: boolean})[];
	loading: boolean;
} => {
	const {data, loading, error} = useQuery(GET_SIMPLE_GROUPS);

	const groups = useMemo(() => {
		if (loading || error || !data?.groupsPaginated) {
			return [];
		}

		return data.groupsPaginated.items.map(u => ({
			value: Number(u.id),
			label: u?.name ?? "Loading",
			allowSubscription: u.allowSubscription,
		}));
	}, [data, loading, error]);

	return {groups, loading};
};
