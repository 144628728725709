import React, {useState} from "react";
import {Mention, MentionsInput} from "react-mentions";

import {Collapsible} from "../../components/card";
import {InputRow, Text, validEmail, required} from "../../components/input";
import {EmojiPickerInput} from "../../components/input/emoji-picker";
import {AITextPicker} from "../../components/input/ai-text-picker";

export const DemoTextFields = (): React.ReactElement => {
	const [text, setText] = useState("");
	const [text2, setText2] = useState("");
	const [text3, setText3] = useState("");
	const [text4, setText4] = useState("");
	const [text5, setText5] = useState(
		"Our easy-to-use employee advocacy software helps your team share your content on their social media platforms with easy prompts, email reminders, and one-click activation"
	);
	const [text6, setText6] = useState(
		"AWS AppSync field-level resolvers: Enhancing GraphQL API development | Amazon Web Services"
	);
	const [text7, setText7] = useState("");

	const [emojiText, setEmojiText] = useState("");

	return (
		<Collapsible header={<>Text Fields</>}>
			<Text
				label="Text, Max length 100"
				maxLength={100}
				value={text}
				onChange={setText}
				placeholder="Value?"
			/>
			<Text label="Text with icon" icon="search" value={text2} onChange={setText2} placeholder="Value?" />
			<Text label="Disabled Text" value="I am disabled" disabled onChange={() => undefined} />
			<Text label="Text requiring input" value={text3} onChange={setText3} validate={required} />
			<Text
				label="Text requiring email"
				value={text4}
				onChange={setText4}
				validate={[required, validEmail]}
			/>
			<Text
				size={4}
				bold
				placeholder="Add a title."
				type="textarea"
				maxRows={4}
				minRows={1}
				maxLength={300}
				value={text6}
				onChange={setText6}
			/>
			<MentionsInput
				placeholder="Tag someone..."
				value={text7}
				onChange={e => setText7(e.target.value)}
				style={{
					control: {
						minHeight: 115,
					},
					"&multiLine": {
						input: {
							padding: "7px 15px",
							margin: 0,
							border: "1px solid transparent",
						},
						highlighter: {
							padding: "7px 15px",
						},
					},
				}}
			>
				<Mention
					trigger="@"
					data={[
						{id: 1, display: "John Doe"},
						{id: 2, display: "Jane Doe"},
					]}
					appendSpaceOnAdd={true}
				/>
			</MentionsInput>

			<InputRow>
				<label htmlFor="test1" id="test1">
					Test 1 empty
				</label>
				<textarea style={{width: "100%"}} name="test1"></textarea>
			</InputRow>
			<InputRow>
				<label htmlFor="test2" id="test2">
					Test 2 filled
				</label>
				<textarea style={{width: "100%"}} name="test2">
					Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eligendi deleniti, dolorum placeat ipsa
					vitae quis, quibusdam mollitia consequatur nobis ea facilis reprehenderit dignissimos saepe unde,
					optio numquam laudantium quod sequi?
				</textarea>
			</InputRow>

			<InputRow>
				<Text onChange={setEmojiText} value={emojiText} label="Emojy Picker" />
				<EmojiPickerInput value={emojiText} onChange={setEmojiText} />
			</InputRow>

			<InputRow>
				<Text onChange={setText5} value={text5} label="AI Text Picker" />
				<AITextPicker onChange={setText5} value={text5} label="Demo Text" />
			</InputRow>
		</Collapsible>
	);
};
