import React, {useCallback, useMemo, useState} from "react";
import {useFormikContext} from "formik";
import {useQuery} from "@apollo/client";
import classNames from "classnames";

import {FormValues} from "./types";
import {Span1, Span2, Span4, Span5} from "../../../../components/text";
import {Icon} from "../../../../components/images";
import {Checkbox, DropdownButton, InputRow, Switch} from "../../../../components/input";
import {GET_USER_CONNECTION_PROFILES} from "../../../../data";
import {HoverTooltip} from "../../../../components/tooltip";

import styles from "./styles.module.scss";

const tiktokPrivacyLevelMapping = {
	PUBLIC_TO_EVERYONE: "Public",
	FOLLOWER_OF_CREATOR: "Followers",
	MUTUAL_FOLLOW_FRIENDS: "Friends (Followers you follow back)",
	SELF_ONLY: "Only you",
};

const TikTokOptions = () => {
	const [collapsed, setCollapsed] = useState(true);
	const {values, setFieldValue, errors} = useFormikContext<FormValues>();
	const {recipient, opengraphs} = values;
	const hasVideo = opengraphs?.tiktok?.video || opengraphs?.general?.video;
	const options = recipient?.networksOptions?.tiktok;
	const [discloseContent, setDiscloseContent] = useState(
		options?.brandContentToggle || options?.brandOrganicToggle
	);
	const {data: profiles} = useQuery(GET_USER_CONNECTION_PROFILES);
	const tiktokProfile = profiles?.userConnectionProfiles?.tiktok;
	const tiktokOptionsErrors = (errors?.recipient?.networksOptions?.tiktok || {}) as {
		privacyLevel: string;
		brandContentToggle?: boolean;
		brandOrganicToggle?: boolean;
		contentDisclosure?: boolean;
	};

	const onChange = useCallback(
		data => {
			setFieldValue("recipient.networksOptions.tiktok", {
				...options,
				...data,
			});
		},
		[setFieldValue, options]
	);

	const dropdownOptions = useMemo(() => {
		const privacyLevelOptions = tiktokProfile?.options?.privacy_level_options || [];
		const isPrivateDisabled = opt => options?.brandContentToggle && opt === "SELF_ONLY";

		return privacyLevelOptions.map(o => ({
			label: tiktokPrivacyLevelMapping[o],
			disabled: isPrivateDisabled(o),
			hint: isPrivateDisabled(o) ? (
				<HoverTooltip text={"Branded content visibility cannot be set to private."}>
					<Icon width={16} icon={"information"} />
				</HoverTooltip>
			) : null,
			onClick: () => onChange({privacyLevel: o}),
		}));
	}, [tiktokProfile, onChange, options]);

	const onDisclosureContentSelect = useCallback(() => {
		onChange({brandOrganicToggle: false, brandContentToggle: false, contentDisclosure: !discloseContent});
		setDiscloseContent(!discloseContent);
	}, [setDiscloseContent, discloseContent, onChange]);

	return (
		<>
			<div
				className={classNames(
					styles.optionsContainer,
					Object.values(tiktokOptionsErrors).length && styles.hasError
				)}
			>
				<div className={styles.optionsHeader} onClick={() => setCollapsed(!collapsed)}>
					<Span1 bold>TikTok Options</Span1>
					<div className={styles.collapseBtn}>
						<Icon icon={collapsed ? "chevron-down" : "chevron-up"} color="black" />
					</div>
				</div>
				{!collapsed && (
					<div className={styles.optionsCt}>
						{tiktokProfile && (
							<div className={styles.profile}>
								<img src={tiktokProfile.imageUrl} alt={tiktokProfile.fullName} />
								<Span2 className={styles.accountName}>
									<a href={tiktokProfile.url}>
										<Span1 bold>{tiktokProfile.fullName}</Span1>{" "}
										{tiktokProfile.url ? `(@${tiktokProfile.url.split("@").pop()})` : ""}
									</a>
								</Span2>
							</div>
						)}
						<div>
							<div className={styles.title}>Allow users to</div>
							<div className={styles.optionsGroup}>
								<Checkbox
									label={"Comment"}
									value={options?.allowComment}
									onChange={value => onChange({allowComment: value})}
								/>
								{hasVideo && (
									<>
										<Checkbox
											label={"Stitch"}
											value={options?.allowStitch}
											disabled={tiktokProfile?.options?.stitch_disabled}
											onChange={value => onChange({allowStitch: value})}
										/>
										<Checkbox
											label={"Duet"}
											value={options?.allowDuet}
											disabled={tiktokProfile?.options?.duet_disabled}
											onChange={value => onChange({allowDuet: value})}
										/>
									</>
								)}
							</div>
						</div>
						<div>
							<div className={styles.title}>Who can view this post</div>
							<DropdownButton
								className={styles.dropdownButton}
								value={tiktokPrivacyLevelMapping[options?.privacyLevel || ""] ?? "Select"}
								color={"black"}
								invert
								size={"small"}
								border={false}
								options={dropdownOptions}
								arrow
							/>
							{tiktokOptionsErrors?.privacyLevel && <Span5 color={"red"}>This field is required</Span5>}
						</div>
						<div className={styles.disclosureOptionCt}>
							<div className={styles.disclosureOption}>
								<Switch
									label={<Span2 bold>Disclosure post content &nbsp;</Span2>}
									value={discloseContent || false}
									onChange={onDisclosureContentSelect}
								/>
								{discloseContent && (!!options?.brandContentToggle || !!options?.brandOrganicToggle) ? (
									<div className={styles.disclosureOptionInfo}>
										<Icon icon={"information"} color={"blue"} />
										<Span4 color={"grey"}>{`Your photo/video will be labeled ‘‘${
											options?.brandContentToggle ? "Paid partnership" : "Promotional content"
										}’’. This cannot be changed once your photo/video is posted.`}</Span4>
									</div>
								) : (
									<Span4 color={"grey"}>
										Turn on to disclose that this photo/video promotes goods or services in exchange for
										something of value. Your photo/video could promote yourself, a third party, or both.
									</Span4>
								)}
							</div>
							{discloseContent && (
								<>
									<div className={styles.group}>
										<Checkbox
											label={<Span2 bold>Your brand</Span2>}
											value={options?.brandOrganicToggle ?? false}
											onChange={value => onChange({brandOrganicToggle: value})}
										/>
										<Span4 color={"grey"}>
											You are promoting yourself or your own business. This photo/video will be classified as
											Brand Organic.
										</Span4>
										<Checkbox
											label={
												<InputRow>
													<Span2 bold>Branded content</Span2>
													{options?.privacyLevel === "SELF_ONLY" && (
														<HoverTooltip text={"Branded content visibility cannot be set to private."}>
															&nbsp;
															<Icon icon={"information"} width={16} color={"grey"} />
														</HoverTooltip>
													)}
												</InputRow>
											}
											value={options?.brandContentToggle ?? false}
											disabled={options?.privacyLevel === "SELF_ONLY"}
											onChange={value => onChange({brandContentToggle: value})}
										/>
										<Span4 color={"grey"}>
											You are promoting yourself or your own business. This photo/video will be classified as
											Brand Organic.
										</Span4>
										{discloseContent &&
											(!!tiktokOptionsErrors?.brandOrganicToggle ||
												!!tiktokOptionsErrors?.brandContentToggle) && (
												<Span5 color={"red"}>
													{tiktokOptionsErrors?.brandOrganicToggle || tiktokOptionsErrors?.brandContentToggle}
												</Span5>
											)}
									</div>
									<Span4>
										By posting, you agree to TikTok&apos;s{" "}
										{options?.brandContentToggle && (
											<>
												<Span4 href={"https://www.tiktok.com/legal/page/global/bc-policy/en"}>
													Branded Content Policy
												</Span4>{" "}
												and{" "}
											</>
										)}
										<Span4 href={"https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"}>
											Music Usage Confirmation
										</Span4>
									</Span4>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export const SharingOptions = () => {
	const {values} = useFormikContext<FormValues>();
	const {recipient} = values;

	return (
		<div className={styles.networkOptions}>
			{recipient?.networks?.includes("tiktok") && <TikTokOptions />}
		</div>
	);
};
