import {ReactElement, useCallback, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {useDropzone} from "react-dropzone";
import html2canvas from "html2canvas";

import {P, P1, P2, P4, Span} from "../../../components/text";
import {useMutationToast, useToast} from "../../../toast";
import {useMyUser} from "../../../data";
import {Button, Checkbox, InputRow, RadioGroup, Separator} from "../../../components/input";
import {UPDATE_ORG} from "../../../data/org";
import {Icon} from "../../../components/images";
import {UserAvatar} from "../../../components/user-avatar";

import styles from "./templates.module.scss";

const settingsMap = {
	imageLogo: "emailLogo",
	logoSize: "emailLogoSize",
	colorButton: "queueButtonColor",
	colorLink: "loginLinkColor",
	asCompany: "suppressAdminSpoof",
	cobrand: "queueCoBranding",
	hideTips: "hideTips",
};

const defaultImage = "https://d3k6n5v1u6h8v8.cloudfront.net/CVSLogo.FullColor.RGB+(1).png";

const pxToPercent = (px?: number) => {
	const defaultPx = 275;
	const maxPx = 482;
	const minPx = 24;
	const percent = (((px ?? defaultPx) - minPx) / (maxPx - minPx)) * 100;
	return Math.round(percent);
};

const calculateSizeInPx = (percent: number) => {
	const minPercent = 5;
	const maxPercent = 100;
	const maxPx = 482;
	const actualPercent = minPercent + (percent / 100) * (maxPercent - minPercent);
	return Math.round((maxPx * actualPercent) / 100);
};

export const Templates = (): ReactElement => {
	const me = useMyUser();
	const settings = me?.org?.settings;

	const defaultPercentage = useMemo(() => pxToPercent(settings?.emailLogoSize), [settings?.emailLogoSize]);
	const toast = useToast();

	const [imageLogo, setImageLogo] = useState<string | null>(settings.emailLogo ?? null);
	const [file, setFile] = useState<File | null>(null);
	const [logoSize, setLogoSize] = useState(defaultPercentage);
	const [updateOrg, {loading}] = useMutationToast(UPDATE_ORG, {
		onCompleted: changes => {
			const settings = changes?.updateOrg?.settings;
			setImageLogo(settings.emailLogo);
			setLogoSize(pxToPercent(settings.emailLogoSize));
		},
	});
	const shareButtonWrapperRef = useRef<HTMLDivElement>(null);
	const shareAllButtonWrapperRef = useRef<HTMLDivElement>(null);
	const [colorButton, setColorButton] = useState<string>(settings.queueButtonColor ?? "#D32B79");
	const [colorLink, setColorLink] = useState<string>(settings.loginLinkColor ?? "#0997D9");
	const [asCompany, setAsCompany] = useState(settings.suppressAdminSpoof ?? false);
	const [cobrand, setCobrand] = useState(settings.queueCoBranding ?? false);
	const [hideTips, setHideTips] = useState(settings.hideTips ?? true);
	const changedFields = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const fields: {[key: string]: any}[] = [];
		const vars = {
			imageLogo,
			colorButton,
			colorLink,
			asCompany,
			cobrand,
			hideTips,
		};
		for (const key in vars) {
			if (vars[key] !== settings[settingsMap[key]]) {
				fields.push({
					[key]: vars[key],
				});
			}
		}
		if (logoSize !== defaultPercentage) {
			fields.push({
				logoSize: logoSize,
			});
		}

		return fields;
	}, [
		imageLogo,
		logoSize,
		colorButton,
		colorLink,
		asCompany,
		cobrand,
		hideTips,
		settings,
		defaultPercentage,
	]);

	const logoSizeInPx = useMemo(() => calculateSizeInPx(logoSize), [logoSize]);

	const onSave = useCallback(async () => {
		let fileShare, fileShareAll;
		const {changes, deletions} = changedFields.reduce(
			(acc, field) => {
				if (Object.keys(field)[0] === "logoSize") {
					return {
						...acc,
						changes: {
							...acc.changes,
							emailLogoSize: calculateSizeInPx(field.logoSize),
						},
					};
				}
				if (Object.keys(field)[0] === "imageLogo") {
					if (file) {
						return {
							...acc,
							changes: {
								...acc.changes,
								emailLogo: file,
							},
						};
					} else {
						return {
							...acc,
							deletions: {
								emailLogo: true,
							},
						};
					}
				}
				return {
					...acc,
					changes: {
						...acc.changes,
						[settingsMap[Object.keys(field)[0]]]: Object.values(field)[0],
					},
				};
			},
			{
				changes: {},
				deletions: {},
			}
		);
		if (changes.queueButtonColor && shareButtonWrapperRef.current && shareAllButtonWrapperRef.current) {
			const shareButton = await html2canvas(shareButtonWrapperRef.current, {scale: 2});
			const shareAllButton = await html2canvas(shareAllButtonWrapperRef.current, {scale: 2});
			const blobShare: Blob = await new Promise((resolve, reject) =>
				shareButton.toBlob(blob => (blob ? resolve(blob) : reject()), "image/png")
			);
			const blobShareAll: Blob = await new Promise((resolve, reject) =>
				shareAllButton.toBlob(blob => (blob ? resolve(blob) : reject()), "image/png")
			);
			if (blobShare && blobShareAll) {
				fileShare = new File([blobShare], "shareButtonImage.png", {type: "image/png"});
				fileShareAll = new File([blobShareAll], "shareAllButtonImage.png", {type: "image/png"});
			}
		}
		updateOrg({
			variables: {
				changes: {
					...changes,
					...(changes.emailLogo ? {emailLogo: file} : {}),
					...(changes.queueButtonColor && {
						shareButtonImage: fileShare,
						shareAllButtonImage: fileShareAll,
					}),
				},
				deletions,
			},
		});
		return undefined;
	}, [changedFields, file, updateOrg]);

	const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setLogoSize(Number(e.target.value));
	}, []);
	const addFivePercent = useCallback(() => {
		setLogoSize(prev => (prev !== 100 ? prev + 5 : 100));
	}, []);
	const removeFivePercent = useCallback(() => {
		setLogoSize(prev => (prev === 0 ? 0 : prev - 5));
	}, []);

	const onColorChange = useCallback((color: string, label: string) => {
		if (label === "Button Color") {
			setColorButton(color);
		} else {
			setColorLink(color);
		}
	}, []);

	const onDiscard = useCallback(() => {
		setColorButton(settings.queueButtonColor ?? "#D32B79");
		setColorLink(settings.loginLinkColor ?? "#0997D9");
		setAsCompany(settings.suppressAdminSpoof ?? false);
		setCobrand(settings.queueCoBranding ?? false);
		setHideTips(settings.hideTips ?? false);
		setLogoSize(pxToPercent(settings.emailLogoSize));
		setImageLogo(settings.emailLogo ?? "");
		return undefined;
	}, [settings]);
	const isDirty = useMemo(() => changedFields.length > 0, [changedFields]);

	const onDrop = useCallback(
		acceptedFiles => {
			if (acceptedFiles.length === 0) {
				toast({
					color: "red",
					text: "Invalid file type.",
				});
			}
			setImageLogo(URL.createObjectURL(acceptedFiles[0]));
			setFile(acceptedFiles[0]);
		},
		[toast]
	);
	const inputRef = useRef<HTMLInputElement>(null);
	const accept = {"image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"]};
	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept});
	const rootPropsWithoutOnClick = useMemo(() => {
		const rootProps = getRootProps();
		delete rootProps.onClick;
		return rootProps;
	}, [getRootProps]);
	const resetLogo = useCallback(() => {
		setImageLogo(null);
		setFile(null);
	}, []);
	return (
		<>
			<div className={styles.container}>
				<div className={styles.emailContainer}>
					<div className={styles.leftContainer}>
						<div className={styles.logoContainer}>
							<P1 bold>Header Image or Logo</P1>
							<div
								className={classnames(styles.logoDropZone, isDragActive && styles.active)}
								{...rootPropsWithoutOnClick}
							>
								{imageLogo ? (
									<>
										<img src={imageLogo} alt="logo" />
										<P4 color="pink" onClick={resetLogo}>
											Remove Current Logo
										</P4>
									</>
								) : (
									<div className={styles.newImage}>
										<input {...getInputProps()} ref={inputRef} accept={Object.keys(accept).join(",")} />
										<P color="blue" bold>
											Drag and drop image
										</P>
										<P4 className={styles.noSpace}>Supports .png, .jpg, .jpeg files</P4>
										<P4 color="grey" className={styles.or}>
											OR
										</P4>
										<Button
											value="Upload File"
											onClick={() => {
												if (inputRef.current) {
													inputRef.current.click();
												}
											}}
										/>
									</div>
								)}
							</div>
							<div className={styles.sliderContainer}>
								<Icon
									viewBox={"0 0 16 16"}
									icon="minus"
									color="grey"
									width={16}
									height={16}
									onClick={removeFivePercent}
								/>
								<div className={styles.slider}>
									<span
										style={{
											width: `${logoSize}%`,
										}}
										className={styles.leftPart}
									/>
									<input
										type="range"
										min="0"
										max="100"
										id="myRange"
										onChange={onInputChange}
										value={logoSize}
									/>
									<span
										className={styles.bullet}
										style={{
											left: `calc( (100% - 16px) * ${logoSize / 100} )`,
										}}
									/>
								</div>
								<Icon icon="add" width={16} height={16} color="grey" onClick={addFivePercent} />
							</div>
						</div>
						<Separator className={styles.largeSpace} horizontal />
						<div className={styles.articleStyling}>
							<P1 bold>Article Styling</P1>
							<InputRow position="between">
								<div className={styles.inputContainer}>
									<input
										type="color"
										id="colorButton"
										name="colorButton"
										value={colorButton}
										onChange={e => onColorChange(e.target.value, "Button Color")}
									/>
									<div
										style={{
											backgroundColor: colorButton,
										}}
										className={styles.colorBox}
									/>

									<label htmlFor="colorButton">Button Color</label>
								</div>
								{colorButton !== "#D32B79" && (
									<P2 color="blue" onClick={() => setColorButton("#D32B79")}>
										Use Default
									</P2>
								)}
							</InputRow>
							<InputRow position="between">
								<div className={styles.inputContainer}>
									<input
										type="color"
										id="colorLink"
										name="colorLink"
										value={colorLink}
										onChange={e => onColorChange(e.target.value, "Link Color")}
									/>
									<div
										style={{
											backgroundColor: colorLink,
										}}
										className={styles.colorBox}
									/>
									<label htmlFor="colorLink">Link color</label>
								</div>
								{colorLink !== "#0997D9" && (
									<P2 color="blue" onClick={() => setColorLink("#0997D9")}>
										Use Default
									</P2>
								)}
							</InputRow>
						</div>
						<Separator className={styles.largeSpace} horizontal />
						<div>
							<P1 bold>More Options</P1>
							<InputRow>
								<Span className="space">Send as</Span>
								<RadioGroup
									horizontal={true}
									value={asCompany}
									onChange={setAsCompany}
									options={[
										{label: "Admin", value: false},
										{label: "Company", value: true},
									]}
								/>
							</InputRow>
							<Checkbox value={cobrand} onChange={setCobrand} label="ClearView Co-Branding" />
							<Checkbox
								value={!hideTips}
								onChange={val => {
									setHideTips(!val);
								}}
								label="Include Daily Social Media Tip"
							/>
						</div>
					</div>
					<div className={styles.rightContainer}>
						<div className={styles.emailHeader}>
							<img src={imageLogo ?? defaultImage} alt="logo" width={`${logoSizeInPx}`} />
							<P2> This is the title of your collection</P2>
							{cobrand && (
								<div className={styles.by}>
									<p>By</p> <img src="/svgs/clearview-logo-small.svg" width={"20px"} height={"20px"} />
								</div>
							)}
						</div>
						<div className={styles.authorMessage}>
							<div className={styles.avatar}>
								<UserAvatar />
								<P size={5}>[Name of avatar]</P>
							</div>
							<div className={styles.message}>
								<p>
									Please help us spread the word by sharing this content on your social media profiles! Every
									share counts and could be the key to unlocking our success!
								</p>
								<p>Thanks.</p>
							</div>
						</div>
						<div ref={shareAllButtonWrapperRef}>
							<button className={styles.shareAll} style={{color: colorButton}}>
								Share All
							</button>
						</div>
						<div className={styles.posts}>
							<InputRow>
								<img className="space" src={"/post-image-1.png"} width={"160px"} height={"83px"} />
								<P size={2}>
									<span style={{color: colorLink, cursor: "pointer"}}>
										An article title is the specific name or heading given to a written piece, aiming to
										briefly convey the subject
									</span>
								</P>
							</InputRow>
							<P className={styles.postDescription}>
								An article description refers to a summary or an introductory passage that outlines the
								content of an article, clarifying its key points, themes, and subjects to provide readers with
								an overview before delving into the full text.
							</P>
							<InputRow position="right">
								<div ref={shareButtonWrapperRef}>
									<button className={styles.shareButton} style={{color: colorButton}}>
										Share
									</button>
								</div>
							</InputRow>
							<Separator horizontal />
							<InputRow>
								<img className="space" src={"/post-image-2.png"} width={"160px"} height={"83px"} />
								<P size={2}>
									<span style={{color: colorLink, cursor: "pointer"}}>
										The title serves as the unique name or heading for a written piece, intending to
										succinctly captures the main topic
									</span>
								</P>
							</InputRow>
							<P className={styles.postDescription}>
								The article description is a brief summary of the main points, themes, and subjects covered in
								an article. It allows readers to understand the essence of the content before delving into the
								full article, aiding in a quick grasp of its key elements.
							</P>
							<InputRow position="right">
								<button className={styles.shareButton} style={{color: colorButton}}>
									Share
								</button>
							</InputRow>
							<div className="space">
								<Separator className={styles.noSpace} horizontal />
							</div>
						</div>
						<div className={styles.alignCenter}>
							<P>Feeling generous? Share every one of them</P>
							<button className={classnames(styles.shareAll, "space")} style={{color: colorButton}}>
								Share All
							</button>
						</div>
						<Separator className={classnames(styles.noSpace, !hideTips && styles.blue)} horizontal />
						{!hideTips && (
							<>
								<div>
									<InputRow position="center">
										<P2 bold>Daily Social Media Tip</P2>
									</InputRow>
									<P>
										A daily tip is a helpful piece of advice or suggestion intended to improve daily life or
										productivity. These tips can cover various topics, such as health, organization, or
										self-improvement, and are often shared to inspire positive change. #DailyTip #Advice
										#SelfImprovement
									</P>
								</div>
								<Separator className={classnames(styles.noSpace, styles.blue)} horizontal />
							</>
						)}
						<div className={styles.alignCenter}>
							<P>Admins Only</P>
							<P2 bold>Queue Engagement</P2>
						</div>
						<div className={styles.engagement}>
							<P2 bold>Clearview Social Sharing Content - 26 Aug 2024</P2>
							<div className={styles.numbers}>
								<div>
									<h3>This Collection was sent to 28 people</h3>
								</div>
								<div>
									<h3>$0.00</h3>
									<P>Earned Media Value</P>
								</div>
							</div>
						</div>
						<P>Clearview Social / 77 Goodell Street / Suite 430 / Buffalo, NY 14203</P>
						<div>
							<P>
								<span className={styles.fakeLink} style={{color: colorLink, cursor: "pointer"}}>
									Click here
								</span>{" "}
								to unsubscribe from Clearview Social emails.
							</P>
						</div>
					</div>
				</div>
			</div>
			<InputRow className={styles.sticky}>
				<Button onClick={onSave} disabled={!isDirty} loading={loading} value="Save" />
				<Button onClick={onDiscard} disabled={!isDirty || loading} invert value="Discard" />
			</InputRow>
		</>
	);
};
