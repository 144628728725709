import React, {useState} from "react";
import classNames from "classnames";

import {TikTokPost} from "./tiktok-post";
import {accountNames, OpenGraphs, Service, useMyUser} from "../../../../data";
import {Icon} from "../../../../components/images";
import {Span1, Span2} from "../../../../components/text";
import {Separator} from "../../../../components/input";
import {Schedule} from "../../posts/form/types";

import styles from "./styles.module.scss";

const bStyle = classNames.bind(styles);

interface PostsPreviewProps {
	post?: {
		networks: Service[];
		schedule?: Schedule;
		opengraphs: OpenGraphs;
		url?: string | null;
	};
}

const NetworkPostMap = {
	tiktok: TikTokPost,
	// linkedin: LinkedInPost,
	// facebook: FacebookPost,
	// twitter: TwitterPost,
	// instagram: InstagramPost,
};

export const PostsPreview = ({post}: PostsPreviewProps) => {
	const user = useMyUser();
	const [collapsed, setCollapsed] = useState<{[key in Service]?: boolean}>({});

	if (!post) return null;

	return (
		<div className={styles.postsPreview}>
			{post.networks.map(network => {
				const PostComponent = NetworkPostMap[network];
				const opengraphData = {
					...post.opengraphs.general,
					...post.opengraphs[network],
				};

				if (!PostComponent) return null;

				return (
					<div key={network} className={bStyle(styles.postContainer)}>
						<div className={styles.headline}>
							<Icon icon={`filled-${network}`} width={16} height={16} viewBox={"0 0 16 16"} />
							<Span1 bold>{accountNames[network]}</Span1>
							<Separator horizontal />
							<div onClick={() => setCollapsed({...collapsed, [network]: !collapsed[network]})}>
								<Icon
									className={bStyle(styles.collapseIcon)}
									icon={collapsed[network] ? "chevron-down" : "chevron-up"}
								/>
							</div>
						</div>
						{!collapsed[network] && (
							<PostComponent
								user={user}
								opengraphData={opengraphData}
								url={post.url}
								scheduledFor={post.schedule?.[network]?.scheduledFor}
							/>
						)}
					</div>
				);
			})}
			<Separator horizontal />
			<div className={styles.previewInfo}>
				<Icon icon={"information"} />
				<Span2>
					The preview gives an approximation of how your content will appear when published, but continuous
					changes by social networks may alter the final look.
				</Span2>
			</div>
		</div>
	);
};
