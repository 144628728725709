import {useCallback, useMemo, useState} from "react";
import classNames from "classnames";
import {useQuery} from "@apollo/client";

import {Button, InputRow} from "../../../components/input";
import {Span, Span4} from "../../../components/text";
import {GET_SHARE, services, UPDATE_SHARE_NETWORK, useMyUser} from "../../../data";
import {Icon} from "../../../components/images";
import {useMutationToast, useToast} from "../../../toast";
import {Loading} from "../../../components/loading";

import styles from "./select-networks.module.scss";

const bStyles = classNames.bind(styles);

interface SelectNetworksProps {
	shareId: number;
	close: () => void;
}

export const SelectNetworks = ({shareId, close}: SelectNetworksProps) => {
	const [updateShare, {loading}] = useMutationToast(UPDATE_SHARE_NETWORK);
	const {data: shareData} = useQuery(GET_SHARE, {
		variables: {id: shareId},
		fetchPolicy: "cache-first",
	});
	const share = shareData?.share;
	const toast = useToast();
	const me = useMyUser();
	const availableNetworks = useMemo(
		() => services.filter(s => me.connections[s === "facebook" ? "facebookPage" : s]?.connected),
		[me.connections]
	);
	const og = share?.opengraphs.general ?? {};
	const hasContent = Boolean(share?.url || og.video);
	const images = og.image ? [{url: og.image}, ...(og.images ?? [])] : [];
	const [selectedNetworks, setSelectedNetworks] = useState<string[]>(availableNetworks);

	const onSchedule = useCallback(() => {
		updateShare({
			variables: {
				id: shareId,
				services: selectedNetworks,
			},
		}).then(() => {
			toast({
				text: "Post scheduled successfully.",
				color: "green",
				timeout: 3,
			});
			close();
		});
	}, [updateShare, shareId, selectedNetworks, close, toast]);

	if (!share) {
		return (
			<div className={styles.loadingPost}>
				<Loading position="center" />
			</div>
		);
	}

	return (
		<div className={styles.selectNetworks}>
			<div className={styles.header}>
				<h3>Select networks to share your post to:</h3>
			</div>
			<div className={styles.body}>
				<div className={styles.networksHeader}>
					<h5>
						Share as Personal: {me.firstName} {me.lastName}
					</h5>
					<div className={styles.networks}>
						{availableNetworks.map(s => (
							<div
								key={s}
								className={bStyles(styles.network, {[styles.selected]: selectedNetworks?.includes(s)})}
								onClick={() => {
									setSelectedNetworks(prev => {
										if (prev.includes(s)) {
											return prev.filter(n => n !== s);
										}
										return [...prev, s];
									});
								}}
							>
								<Icon icon={`filled-${s}`} width={16} height={16} viewBox={"0 0 16 16"} />
							</div>
						))}
					</div>
				</div>
				<div className={styles.post}>
					{og.comment && <div>{og.comment}</div>}
					{hasContent && (
						<div className={styles.postContent}>
							{(og.image || og.video) && (
								<div className={styles.mediaContainer}>
									{og.video ? (
										<video className={styles.image} controls muted>
											<source src={og.video} />
											<track kind="captions" srcLang="en" label="english_captions" />
										</video>
									) : (
										og.image && (
											<div className={styles.image}>
												<img src={og.image} alt={og.description} />
											</div>
										)
									)}
								</div>
							)}

							<div className={styles.postContentText}>
								{share.url && (
									<a href={share.url} target="_blank" rel="noreferrer">
										{new URL(share.url).hostname}
									</a>
								)}
								<Span bold>{og.title}</Span>
								<Span4>{og.description}</Span4>
							</div>
						</div>
					)}
					{!hasContent && images.length !== 0 && (
						<div className={styles.imageList}>
							{images.map(image => (
								<div className={styles.image} key={image.url}>
									<img src={image.url} alt={og.description} />
								</div>
							))}
						</div>
					)}
				</div>
			</div>
			<InputRow position="between" className={styles.footer}>
				<Button value={"Close"} invert disabled={loading} onClick={close} />
				<Button
					value={"Schedule"}
					loading={loading}
					disabled={selectedNetworks.length === 0 || loading}
					onClick={onSchedule}
				/>
			</InputRow>
		</div>
	);
};
