import {debounce} from "lodash";
import {useCallback, useEffect, useMemo, useRef} from "react";

export const useDebounceCallback = (func, wait = 200) => {
	const debouncedChangeHandler = useMemo(() => debounce(func, wait), [func, wait]);

	useEffect(() => () => debouncedChangeHandler.cancel(), [debouncedChangeHandler]);

	return debouncedChangeHandler;
};

export const useDebounceCallbackWithPromise = <T>(func: () => Promise<T>, wait = 200) => {
	const refPromise = useRef<{resolve: (data: T) => void; reject: () => void}>();

	const debouncedHandler = useMemo(
		() => debounce(() => func().then(refPromise.current?.resolve).catch(refPromise.current?.reject), wait),
		[func, wait, refPromise]
	);

	const handler = useCallback(
		(): Promise<T> =>
			new Promise((resolve, reject) => {
				refPromise.current = {resolve, reject};
				debouncedHandler();
			}),
		[debouncedHandler]
	);

	useEffect(() => () => debouncedHandler.cancel(), [debouncedHandler]);

	return handler;
};
