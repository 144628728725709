import React, {useCallback, useState} from "react";
import classnames from "classnames";

import {Icon} from "../../../../components/images";
import {UPLOAD_IMAGE} from "../../../../data";
import {Media} from "./media";
import {Media as MediaType} from "../../../../data/media";
import {MediaButton} from "../../../../components/media/media-button";
import {useCanvaDesign} from "../../../../hooks/use-canva-design";
import {useMutationToast, useToast} from "../../../../toast";
import {Loading} from "../../../../components/loading";
import {useConfirmModal} from "../../../../modals";
import {OpenGraphImage} from "../../../../data/opengraph";

import styles from "./components.module.scss";

const bStyles = classnames.bind(styles);

export const networkMaxImages = {
	twitter: 4,
	linkedin: 20,
	facebook: 10,
	instagram: 10,
	general: 20,
	tiktok: 35,
};

interface MultiImageProps {
	onChange: (field: string, value: OpenGraphImage[] | null | undefined) => void;
	images?: OpenGraphImage[];
	image?: string;
	network: string;
	disabled?: boolean;
	canvaButton?: {
		opengraphId: undefined | number;
		postType?: "personal" | "company";
		canvaId?: string;
		opengraphNetwork?: string;
		shuffledCommentId?: string;
		imageDestination?: "OPENGRAPH" | "CANVA_LIBRARY" | "SHUFFLED_IMAGE";
	};
}
export const MultiImage = ({image, onChange, images, network, disabled, canvaButton}: MultiImageProps) => {
	const toast = useToast();
	const [uploadImage, {loading: uploading}] = useMutationToast(UPLOAD_IMAGE);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);

	const onCanvaFileSelect = useCallback(
		({url, id}) => {
			onChange("images", [...(images ?? []), {url, canvaId: id}]);
		},
		[onChange, images]
	);

	const {createDesignJob, loading: loadingCanvaMedia} = useCanvaDesign({
		onCompleted: onCanvaFileSelect,
	});
	const onCanvaItemSelect = useCallback(
		designId => {
			createDesignJob(designId);
		},
		[createDesignJob]
	);
	const onFileChange = useCallback(
		async (files: File[]) => {
			const imgFiles = files.filter(file => file.type.split("/")?.[0] === "image");

			if (!imgFiles.length) {
				return;
			}

			const results = await Promise.all(
				imgFiles
					.slice(0, networkMaxImages[network || "general"] - (images ?? []).length)
					.map(file => uploadImage({variables: {file}}))
			).catch(errors => toast({color: "red", text: errors[0], icon: "warning"}));

			if (!results) return;

			const newImages = results.map(({data}) => ({url: data?.uploadImage}));
			const allImages = [...(images ?? []), ...newImages].slice(0, networkMaxImages[network || "general"]);

			onChange("images", allImages);
		},
		[images, toast, uploadImage, onChange, network]
	);

	const handleAddImage = useCallback(
		(media: MediaType[]) => {
			const newImages = media.filter(m => m.type === "image").map(m => ({url: m.url}));

			if (!newImages.length) return;
			onChange("images", [...(images ?? []), ...newImages].slice(0, networkMaxImages[network]));
		},
		[onChange, images, network]
	);

	const {open: openConfirmRemoveImage} = useConfirmModal(
		() => ({
			title: "Remove selected image?",
			body: <div>Are you sure you want to delete the selected image?</div>,
			confirmText: "Confirm",
			onConfirm: close => {
				onChange(
					"images",
					images?.filter((_, index) => index !== selectedImageIndex)
				);
				close();
			},
		}),
		[onChange, selectedImageIndex, images]
	);

	const onRemove = useCallback(
		(index: number) => {
			setSelectedImageIndex(index);
			openConfirmRemoveImage();
		},
		[openConfirmRemoveImage, setSelectedImageIndex]
	);

	const limitedImages = images?.slice(0, networkMaxImages[network]);

	return (
		<>
			{limitedImages?.map(({url: image, canvaId}, index) => (
				<div key={index} className={styles.multiImageItem}>
					<Media
						canvaButton={{
							...canvaButton,
							canvaId,
							opengraphId: canvaButton?.opengraphId,
							imageId: image,
							imageDestination: index === 0 ? "OPENGRAPH" : "MULTI_IMAGE",
						}}
						image={image}
						border={true}
						width={96}
						height={96}
						disabled={disabled}
						onRemove={() => onRemove(index)}
					/>
				</div>
			))}
			{!disabled && (
				<MediaButton
					opengraphId={canvaButton?.opengraphId}
					ButtonComponent={({isOpen}) => (
						<div
							className={bStyles(styles.addImageBtn, {
								[styles.active]: isOpen,
								[styles.disabled]: (limitedImages?.length ?? 0) >= networkMaxImages[network] || disabled,
							})}
						>
							<div className={styles.icon}>
								<Icon icon="add" color="white" width={16} height={16} viewBox={"0 0 24 24"} />
							</div>
							{(uploading || loadingCanvaMedia) && <Loading size="small" className={styles.loading} />}
						</div>
					)}
					disabled={(limitedImages?.length ?? 0) >= networkMaxImages[network] || disabled}
					dropdownPlacement={"right-start"}
					onFileChange={onFileChange}
					onCanvaSelect={onCanvaItemSelect}
					multiple={true}
					mediaLibrary={{
						onConfirm: handleAddImage,
						disabledImages: [...(image ? [image] : []), ...(images?.map(({url}) => url) ?? [])],
						tabs: ["image"],
						multiple: true,
					}}
				/>
			)}
		</>
	);
};
