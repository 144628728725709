import {ReactElement, useMemo} from "react";
import classnames from "classnames";

import {SVG, SVGData} from "../images";
import {EmptyComponent} from "../../types";
import {accountNames, Connection as ConnectionType, Service, ShareEvent, useUser} from "../../data";
import {isURL} from "../../utils/text";
import {HoverTooltip} from "../tooltip";

import styles from "./social.module.scss";

export type SocialService = Service | "facebookPage" | "instagramPage";

const logos: Record<Service | "page", SVGData> = {
	tiktok: {
		pathing: (
			<>
				<path d="M4.57143 0C2.0467 0 0 2.0467 0 4.57143V27.4286C0 29.9533 2.0467 32 4.57143 32H27.4286C29.9533 32 32 29.9533 32 27.4286V4.57143C32 2.0467 29.9533 0 27.4286 0H4.57143Z" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={"#FFF"}
					d="M22.4557 8.52338C21.2401 7.7308 20.3631 6.46224 20.0894 4.98437C20.0303 4.66515 19.9974 4.3363 19.9974 4H16.1177L16.1112 19.5489C16.0464 21.2904 14.6132 22.6877 12.8564 22.6877C12.3103 22.6877 11.7962 22.5515 11.3435 22.3129C10.3052 21.7668 9.59453 20.6787 9.59453 19.4268C9.59453 17.6284 11.0579 16.165 12.8559 16.165C13.1918 16.165 13.5136 16.2206 13.818 16.3156V12.3549C13.5027 12.3115 13.1826 12.2853 12.8559 12.2853C8.91843 12.2853 5.71484 15.4884 5.71484 19.4268C5.71484 21.8426 6.92166 23.9808 8.76385 25.2735C9.92338 26.0884 11.3347 26.5679 12.8564 26.5679C16.7943 26.5679 19.9974 23.3643 19.9974 19.4268V11.5417C21.5191 12.6338 23.3836 13.2775 25.3957 13.2775V9.3974C24.3119 9.3974 23.3026 9.07555 22.4557 8.52338Z"
				/>
			</>
		),
		viewBox: "0 0 32 32",
		width: 56,
		height: 56,
	},
	twitter: {
		pathing: (
			<>
				<path d="M28.38,24.82L18.22,10.6L12.8,10.6L25.39,28.22L26.97,30.44L26.97,30.44L37.75,45.52L43.17,45.52L29.96,27.03L28.38,24.82Z" />
				<path d="M52,0H4C1.79,0,0,1.79,0,4V52c0,2.21,1.79,4,4,4H52c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4Zm-15.91,48l-10.91-15.53-13.65,15.53h-3.53l15.62-17.76L8,8h11.91l10.33,14.71,12.93-14.71h3.53l-14.89,16.94h0l16.2,23.06h-11.91Z" />
			</>
		),
		width: 56,
		height: 56,
	},
	facebook: {
		pathing: (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 0C1.79086 0 0 1.79086 0 4V52C0 54.2091 1.79086 56 4 56H52C54.2091 56 56 54.2091 56 52V4C56 1.79086 54.2091 0 52 0H4ZM37.0466 15.4113H32.6695C31.8026 15.4113 30.9276 16.3073 30.9276 16.9739V21.4372H37.038C36.792 24.8596 36.2869 27.9892 36.2869 27.9892H30.8964V47.3846H22.8636V27.9871H18.9534V21.4604H22.8636V16.1243C22.8636 15.1486 22.666 8.61551 31.094 8.61551H37.0468L37.0466 15.4113Z"
			/>
		),
		width: 56,
		height: 56,
	},
	linkedin: {
		pathing: (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 0C1.79086 0 0 1.79086 0 4V52C0 54.2091 1.79086 56 4 56H52C54.2091 56 56 54.2091 56 52V4C56 1.79086 54.2091 0 52 0H4ZM17.4181 46.5233H9.10966V21.5269H17.4181V46.5233ZM13.2639 18.1152H13.209C10.4219 18.1152 8.61539 16.1951 8.61539 13.7951C8.61539 11.3424 10.4747 9.47668 13.3183 9.47668C16.1625 9.47668 17.9103 11.3424 17.9647 13.7951C17.9647 16.1951 16.1625 18.1152 13.2639 18.1152ZM47.3846 46.5233H39.074V33.1502C39.074 29.7913 37.8732 27.4985 34.8654 27.4985C32.5682 27.4985 31.2032 29.0422 30.6018 30.5376C30.3833 31.0718 30.3262 31.8143 30.3262 32.5633V46.5234H22.0139C22.0139 46.5234 22.1253 23.872 22.0139 21.527H30.3262V25.069C31.4305 23.3697 33.4014 20.9402 37.8161 20.9402C43.2853 20.9402 47.3846 24.5119 47.3846 32.1892V46.5233Z"
			/>
		),
		width: 56,
		height: 56,
	},
	instagram: {
		pathing: (
			<>
				<path d="M35.2734 28C35.2734 23.9585 32.043 20.7271 28 20.7271C23.957 20.7271 20.7266 23.9585 20.7266 28C20.7266 32.0415 23.957 35.2729 28 35.2729C32.043 35.2729 35.2734 32.0415 35.2734 28Z" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M36.2383 11.6362H19.7617C15.2656 11.6362 11.6367 15.2656 11.6367 19.7612V36.2388C11.6367 40.7344 15.2656 44.3638 19.7617 44.3638H36.2383C40.7344 44.3638 44.3633 40.7344 44.3633 36.2388V19.7612C44.3633 15.2656 40.7344 11.6362 36.2383 11.6362ZM37.0898 17.2612C37.0898 16.3447 37.8242 15.6138 38.7383 15.6138C39.6562 15.6138 40.3867 16.3447 40.3867 17.2612C40.3867 18.1772 39.6562 18.9092 38.7383 18.9092C37.8242 18.9092 37.0898 18.1772 37.0898 17.2612ZM17.0898 28C17.0898 21.999 22 17.0908 28 17.0908C34 17.0908 38.9102 21.999 38.9102 28C38.9102 34.001 34 38.9092 28 38.9092C22 38.9092 17.0898 34.001 17.0898 28Z"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M56 4C56 1.79086 54.2091 0 52 0H4C1.79086 0 0 1.79086 0 4V52C0 54.2091 1.79086 56 4 56H52C54.2091 56 56 54.2091 56 52V4ZM8 19.7612C8 13.2979 13.3047 8 19.7617 8H36.2383C42.7031 8 48 13.3052 48 19.7612V36.2388C48 42.7021 42.6953 48 36.2383 48H19.7617C13.2969 48 8 42.6948 8 36.2388V19.7612Z"
				/>
			</>
		),
		width: 56,
		height: 56,
	},
	page: {
		pathing: (
			<g>
				<rect width="32" height="13" rx="4" fill="#2E394A" />
				<path
					d="M6.15619 9H5.02019V3.664H7.51619C8.67619 3.664 9.30819 4.448 9.30819 5.384C9.30819 6.312 8.66819 7.088 7.51619 7.088H6.15619V9ZM8.14819 5.376C8.14819 4.936 7.81219 4.64 7.36419 4.64H6.15619V6.112H7.36419C7.81219 6.112 8.14819 5.824 8.14819 5.376ZM15.3946 9H14.1546L13.8186 8.08H11.4346L11.0986 9H9.85062L11.9146 3.664H13.3386L15.3946 9ZM13.5066 7.096L12.6266 4.648L11.7386 7.096H13.5066ZM16.3579 6.336C16.3579 4.648 17.6379 3.576 19.1979 3.576C20.3019 3.576 20.9979 4.112 21.4059 4.744L20.4619 5.256C20.2139 4.88 19.7579 4.584 19.1979 4.584C18.2299 4.584 17.5259 5.328 17.5259 6.336C17.5259 7.344 18.2299 8.088 19.1979 8.088C19.6859 8.088 20.1179 7.888 20.3499 7.68V7.032H18.9259V6.056H21.4699V8.096C20.9259 8.704 20.1659 9.104 19.1979 9.104C17.6379 9.104 16.3579 8.016 16.3579 6.336ZM27.1478 9H23.3717V3.664H27.1478V4.64H24.5078V5.8H27.0918V6.776H24.5078V8.016H27.1478V9Z"
					fill="white"
				/>
			</g>
		),
		width: 32,
		height: 13,
	},
};

interface SocialProps extends EmptyComponent {
	name: SocialService;
	active?: boolean;
	hasError?: boolean;
	size?: "x-small" | "small" | "medium" | "large";
	onClick?: () => void;
}

export const Social = ({
	className,
	name,
	active = false,
	hasError = false,
	size = "large",
	onClick,
}: SocialProps): ReactElement => (
	<HoverTooltip text={accountNames[name]} positions={["top"]}>
		<div onClick={onClick} className={classnames(className, styles.container, onClick && styles.onClick)}>
			<div
				className={classnames(
					styles.icon,
					styles[size],
					active && styles.activeIcon,
					name.toLowerCase().includes("page") && styles.page
				)}
			>
				{hasError && <div className={styles.errorIdent}></div>}
				<SVG
					svg={logos[name.toLowerCase().includes("page") ? name.toLowerCase().replace("page", "") : name]}
				/>
				{name.toLowerCase().includes("page") && <SVG svg={logos["page"]} />}
			</div>
		</div>
	</HoverTooltip>
);

interface ConnectionProps extends Omit<SocialProps, "active" | "hasError"> {
	connections: Record<SocialService, ConnectionType | undefined>;
}

export const Connection = ({
	name,
	connections: {[name]: connection},
	size = "small",
	...props
}: ConnectionProps): ReactElement => (
	<Social name={name} active={connection?.connected} hasError={connection?.expired} size={size} {...props} />
);

export const useNetworkPostLink = (event: ShareEvent) => {
	const user = useUser(event?.share?.userId ?? event?.share?.companyId);

	return useMemo(() => {
		if (isURL(event.resultData?.permalink ?? "")) {
			return event.resultData?.permalink;
		}

		if (event?.network === "linkedin") {
			return event.resultData?.updateUrl;
		}

		if (!user?.connections?.[event?.network ?? ""]?.identifier || !event?.resultData?.id) {
			return null;
		}

		if (event?.network === "twitter") {
			return `https://x.com/${user.connections?.twitter?.identifier}/status/${event.resultData?.id || ""}`;
		}

		if (event?.network === "facebook") {
			return `https://www.facebook.com/${user.connections?.facebookPage?.identifier}/posts/${
				event.resultData?.id || ""
			}`;
		}

		if (event?.network === "tiktok") {
			return `${user.connections?.tiktok?.url}/video/${event.resultData?.id || ""}`;
		}

		return null;
	}, [event, user.connections]);
};
