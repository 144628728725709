import * as Yup from "yup";
import dayjs from "dayjs";

import {
	networkCaptionMaxLength,
	networkDescriptionMaxLength,
	networkImageFormats,
	networkImageMaxHeight,
	networkImageMaxWidth,
	networkImageMinHeight,
	networkImageMinWidth,
	networkTitleMaxLength,
	networkVideoMaxHeight,
	networkVideoMaxWidth,
	networkVideoMinHeight,
	networkVideoMinWidth,
} from "../../components";
import {PostServices, accountNames} from "../../../../data";
import {getImageMetadataFromUrl, getVideoMetadataFromUrl} from "../../../../data/media";

const validateImageSizeForTikTok = async (value, schema) => {
	const context = schema?.options?.context || {};
	const opengraph = context.opengraphs?.tiktok || context.opengraphs?.general;
	const {video} = opengraph || {};
	const minHeight = networkImageMinHeight.tiktok;
	const minWidth = networkImageMinWidth.tiktok;
	const maxWidth = networkImageMaxWidth.tiktok;
	const maxHeight = networkImageMaxHeight.tiktok;
	const image = value || (!context.perNetwork ? opengraph?.image : null);

	if (!image || context.url || video || !(maxWidth && maxHeight)) return true;

	const metadata = await getImageMetadataFromUrl(image);

	if (metadata.width < minWidth || metadata.height < minHeight) {
		return schema.createError({
			message: `Invalid image size for ${accountNames.tiktok} (min ${minWidth}x${minHeight} pixels).`,
		});
	}

	return (
		(metadata.width <= maxWidth && metadata.height <= maxHeight) ||
		schema.createError({
			message: `Invalid image size for ${accountNames.tiktok} (max ${maxWidth}x${maxHeight} pixels).`,
		})
	);
};

const validateImageFormatForTikTok = async (value, schema) => {
	const context = schema?.options?.context || {};
	const opengraph = context.opengraphs?.tiktok || context.opengraphs?.general;
	const image = value || (!context.perNetwork ? opengraph?.image : null);

	if (!image || context.url || opengraph?.video) return true;

	return (
		networkImageFormats.tiktok.includes(image?.split(".").pop()?.toLowerCase()) ||
		schema.createError({
			message: `Invalid image format for ${
				accountNames.tiktok
			}. Allowed formats are ${networkImageFormats.tiktok.join(", ").toUpperCase()}`,
		})
	);
};

const validateImagesFormatForTikTok = async (value, schema) => {
	const context = schema?.options?.context || {};
	const images = value || (!context.perNetwork ? context.opengraphs?.general?.images : null);

	if (!images?.length) return true;

	const getImgExt = img => img?.url?.split(".")?.pop()?.toLowerCase();
	const isValid = images.every(img => networkImageFormats.tiktok.includes(getImgExt(img)));

	return (
		isValid ||
		schema.createError({
			message: `Invalid image format for ${
				accountNames.tiktok
			}. Allowed formats are ${networkImageFormats.tiktok.join(", ").toUpperCase()}`,
		})
	);
};

const validateImagesSizeForTikTok = async (value, schema) => {
	const context = schema?.options?.context || {};
	const {video} = context.opengraphs?.tiktok || {};
	const minHeight = networkImageMinHeight.tiktok;
	const minWidth = networkImageMinWidth.tiktok;
	const maxWidth = networkImageMaxWidth.tiktok;
	const maxHeight = networkImageMaxHeight.tiktok;
	const images = value || (!context.perNetwork ? context.opengraphs?.general?.images : null);

	if (!images || context.url || video || !(maxWidth && maxHeight)) return true;

	const validity = await Promise.all(
		images.map(async img => {
			if (!img?.url) {
				return false;
			}

			const metadata = await getImageMetadataFromUrl(img.url);

			return metadata.width <= maxWidth && metadata.height <= maxHeight;
		})
	);

	return (
		!validity.some(isValid => !isValid) ||
		schema.createError({
			message: `Invalid image size for ${accountNames.tiktok} (min ${minWidth}x${minHeight} pixels, max ${maxWidth}x${maxHeight} pixels)`,
		})
	);
};

const validateVideoDurationForTikTok = async (value, schema) => {
	const context = schema?.options?.context || {};
	const networksOptions = context.recipient?.networksOptions || {};
	const video = value || (!context.perNetwork ? context.opengraphs?.general?.video : null);

	if (!video) {
		return true;
	}

	const metadata = await getVideoMetadataFromUrl(video);
	const {videoWidth, videoHeight, duration} = metadata;
	const minWidth = networkVideoMinWidth.tiktok;
	const minHeight = networkVideoMinHeight.tiktok;
	const maxWidth = networkVideoMaxWidth.tiktok;
	const maxHeight = networkVideoMaxHeight.tiktok;
	const maxDuration = networksOptions.tiktok?.maxVideoDuration || duration;

	if (duration > maxDuration) {
		return schema.createError({message: `Video must be less than ${maxDuration} seconds for TikTok`});
	}

	if (videoWidth > maxWidth || videoWidth < minWidth || videoHeight > maxHeight || videoHeight < minHeight) {
		return schema.createError({
			message: `Video must be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels for TikTok`,
		});
	}

	return true;
};

const validateVideoSizeForTikTok = async (value, schema) => {
	const context = schema?.options?.context || {};
	const video = value || (!context.perNetwork ? context.opengraphs?.general?.video : null);

	if (!video) {
		return true;
	}

	const metadata = await getVideoMetadataFromUrl(video);
	const {videoWidth, videoHeight} = metadata;
	const minWidth = networkVideoMinWidth.tiktok;
	const minHeight = networkVideoMinHeight.tiktok;
	const maxWidth = networkVideoMaxWidth.tiktok;
	const maxHeight = networkVideoMaxHeight.tiktok;

	if (videoWidth > maxWidth || videoWidth < minWidth || videoHeight > maxHeight || videoHeight < minHeight) {
		return schema.createError({
			message: `Video must be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels for TikTok`,
		});
	}

	return true;
};

export const opengraphValidationSchema = (network: PostServices) =>
	Yup.object().shape({
		comment: Yup.string()
			.test(
				"comment-length",
				accountNames[network] ? `Caption is required for ${accountNames[network]}` : "Caption is required",
				(value, schema) => {
					const context = schema?.options?.context || {};
					const isValid = n => ((value || schema.parent?.comment)?.length || 0) <= networkCaptionMaxLength[n];

					if (context?.perNetwork) {
						return (
							isValid(network) ||
							schema.createError({message: `Caption too long for ${accountNames[network]}`})
						);
					}

					const notValidNetworks = (context.recipient?.networks ?? [])
						.filter(n => !isValid(n))
						.map(n => accountNames[n]);

					return (
						!notValidNetworks.length ||
						schema.createError({message: `Caption too long for ${notValidNetworks[0]}`})
					);
				}
			)
			.notRequired(),
		title: Yup.string()
			.test("title-length", "Title too long", (value, schema) => {
				const context = schema?.options?.context || {};
				const isValid = n =>
					!networkTitleMaxLength[n] ||
					((value || schema.parent?.title)?.length || 0) <= networkTitleMaxLength[n];

				if (context?.perNetwork) {
					return (
						isValid(network) || schema.createError({message: `Title too long for ${accountNames[network]}`})
					);
				}

				const notValidNetworks = (context.recipient?.networks ?? [])
					.filter(n => !isValid(n))
					.map(n => accountNames[n]);

				return (
					!notValidNetworks.length ||
					schema.createError({message: `Title too long for ${notValidNetworks[0]}`})
				);
			})
			.notRequired(),
		description: Yup.string()
			.test("description-length", "Description too long", (value, schema) => {
				const context = schema?.options?.context || {};
				const isValid = n =>
					!networkDescriptionMaxLength[n] ||
					((value || schema.parent?.description)?.length || 0) <= networkDescriptionMaxLength[n];

				if (context?.perNetwork) {
					return isValid(network);
				}

				const notValidNetworks = (context.recipient?.networks ?? [])
					.filter(n => !isValid(n))
					.map(n => accountNames[n]);

				return (
					!notValidNetworks.length ||
					schema.createError({message: `Description too long for ${notValidNetworks[0]}`})
				);
			})
			.notRequired(),
	});

const getOpengraphSchema = (n: PostServices) => ({
	comment: Yup.string()
		.notRequired()
		.test(
			"comment-required",
			accountNames[n] ? `Caption is required for ${accountNames[n]}` : "Caption is required",
			(value, schema) => {
				const context = schema?.options?.context || {};
				const {image, video} = context.opengraphs?.[n] || {};
				const shareType = context.url ? "url" : image || video ? "media" : "text";
				if (shareType === "media") return true;
				return (
					!!context.url ||
					(n === "general" && context.perNetwork) ||
					!!value?.length ||
					!context.opengraphs?.[n]
				);
			}
		),
	video: Yup.string()
		.notRequired()
		.test("video-required", "Video is required", (value, schema) => {
			const context = schema?.options?.context || {};
			const opengraphs = context.opengraphs || {};
			const opengraph = opengraphs[n] || (!context.perNetwork ? opengraphs?.general : null);
			const video = value || opengraph?.video;

			if (
				!video &&
				(n === "tiktok" || (n === "general" && !context.perNetwork)) &&
				context.recipient?.networks?.includes("tiktok") &&
				!opengraph?.image
			) {
				return schema.createError({message: "Video is required for TikTok"});
			}

			return true;
		})
		.test("video-size", "Invalid video", async (value, schema) => {
			const context = schema?.options?.context || {};
			const recipientNetworks = context.recipient?.networks || [];

			if (
				recipientNetworks.includes("tiktok") &&
				(n === "tiktok" || (n === "general" && !context.perNetwork))
			) {
				return validateVideoSizeForTikTok(value, schema);
			}

			return true;
		})
		.test("video-duration", "Invalid video", async (value, schema) => {
			const context = schema?.options?.context || {};
			const recipientNetworks = context.recipient?.networks || [];

			if (
				recipientNetworks.includes("tiktok") &&
				(n === "tiktok" || (n === "general" && !context.perNetwork))
			) {
				return validateVideoDurationForTikTok(value, schema);
			}

			return true;
		}),
	image: Yup.string()
		.test(
			"image-format",
			accountNames[n] ? `Invalid image format for ${accountNames[n]}` : "Invalid image format",
			(value, schema) => {
				const context = schema?.options?.context || {};
				const recipientNetworks = context.recipient?.networks || [];

				if (
					recipientNetworks.includes("tiktok") &&
					(n === "tiktok" || (n === "general" && !context.perNetwork))
				) {
					return validateImageFormatForTikTok(value, schema);
				}

				return true;
			}
		)
		.test(
			"image-dimension",
			accountNames[n] ? `Invalid image size for ${accountNames[n]}` : "Invalid image size",
			async (value, schema) => {
				const context = schema?.options?.context || {};
				const recipientNetworks = context.recipient?.networks || [];

				if (
					recipientNetworks.includes("tiktok") &&
					(n === "tiktok" || (n === "general" && !context.perNetwork))
				) {
					return validateImageSizeForTikTok(value, schema);
				}

				return true;
			}
		)
		.notRequired(),
	images: Yup.array()
		.of(Yup.object().shape({url: Yup.string()}))
		.notRequired()
		.test(
			"images-format",
			accountNames[n] ? `Invalid image format for ${accountNames[n]}` : "Invalid image format",
			(value, schema) => {
				const context = schema?.options?.context || {};
				const recipientNetworks = context.recipient?.networks || [];

				if (
					recipientNetworks.includes("tiktok") &&
					(n === "tiktok" || (n === "general" && !context.perNetwork))
				) {
					return validateImagesFormatForTikTok(value, schema);
				}

				return true;
			}
		)
		.test(
			"images-dimension",
			accountNames[n] ? `Invalid image size for ${accountNames[n]}` : "Invalid image size",
			async (value, schema) => {
				const context = schema?.options?.context || {};
				const recipientNetworks = context.recipient?.networks || [];

				if (
					recipientNetworks.includes("tiktok") &&
					(n === "tiktok" || (n === "general" && !context.perNetwork))
				) {
					return await validateImagesSizeForTikTok(value, schema);
				}

				return true;
			}
		)
		.notRequired(),
	title: Yup.string().notRequired(),
	description: Yup.string().notRequired(),
});

type ValidationSchemaProps = {skipContentValidation?: boolean};

export const validationSchema = (props?: ValidationSchemaProps) =>
	Yup.object().shape({
		recipient: Yup.object()
			.shape({
				networks: Yup.array().of(Yup.string()).min(1, "Network is required"),
				networksOptions: Yup.object().shape({
					tiktok: Yup.object()
						.shape({
							privacyLevel: Yup.string().test(
								"privacy-level",
								"Privacy level is required",
								(value, schema) => {
									const context = schema?.options?.context || {};
									const networks = context.recipient?.networks || [];
									return !!value || !networks.includes("tiktok");
								}
							),
							brandOrganicToggle: Yup.boolean()
								.test(
									"brand-organic",
									"You need to indicate if your content promotes yourself, a third party, or both.",
									(value, schema) => {
										const context = schema?.options?.context || {};
										const networks = context.recipient?.networks || [];
										const networkOptions = context.recipient?.networksOptions?.tiktok || {};

										if (!networks.includes("tiktok") || !networkOptions.contentDisclosure) return true;

										return value || networkOptions.brandContentToggle;
									}
								)
								.notRequired(),
							brandContentToggle: Yup.boolean()
								.test(
									"brand-content",
									"You need to indicate if your content promotes yourself, a third party, or both.",
									(value, schema) => {
										const context = schema?.options?.context || {};
										const networks = context.recipient?.networks || [];
										const networkOptions = context.recipient?.networksOptions?.tiktok || {};

										if (!networks.includes("tiktok") || !networkOptions.contentDisclosure) return true;

										return value || networkOptions.brandOrganicToggle;
									}
								)
								.notRequired(),
						})
						.notRequired(),
				}),
			})
			.required("Recipient is required"),
		...(!props?.skipContentValidation
			? {
					opengraphs: Yup.object().shape({
						general: Yup.object().shape(getOpengraphSchema("general")),
						facebook: Yup.object().shape(getOpengraphSchema("facebook")).notRequired(),
						instagram: Yup.object().shape(getOpengraphSchema("instagram")).notRequired(),
						linkedin: Yup.object().shape(getOpengraphSchema("linkedin")).notRequired(),
						twitter: Yup.object().shape(getOpengraphSchema("twitter")).notRequired(),
						tiktok: Yup.object().shape(getOpengraphSchema("tiktok")).notRequired(),
					}),
			  }
			: {}),
		schedule: Yup.object().shape({
			general: Yup.object().shape({
				scheduledFor: Yup.date().notRequired().min(new Date(), "Schedule time must be in the future"),
			}),
			facebook: Yup.object()
				.shape({
					scheduledFor: Yup.date()
						.notRequired()
						.test("schedule-for", "Schedule time for Facebook must be in the future", (value, schema) => {
							const context = schema?.options?.context || {};
							const schedule = context.schedule || {};

							if (schedule.facebook?.id) {
								return true;
							}

							return !value || dayjs(value).isAfter(dayjs());
						}),
				})
				.notRequired(),
			instagram: Yup.object()
				.shape({
					scheduledFor: Yup.date()
						.notRequired()
						.test("schedule-for", "Schedule time for Instagram must be in the future", (value, schema) => {
							const context = schema?.options?.context || {};
							const schedule = context.schedule || {};

							if (schedule.instagram?.id) {
								return true;
							}

							return !value || dayjs(value).isAfter(dayjs());
						}),
				})
				.notRequired(),
			linkedin: Yup.object()
				.shape({
					scheduledFor: Yup.date()
						.notRequired()
						.test("schedule-for", "Schedule time for LinkedIn must be in the future", (value, schema) => {
							const context = schema?.options?.context || {};
							const schedule = context.schedule || {};

							if (schedule.linkedin?.id) {
								return true;
							}

							return !value || dayjs(value).isAfter(dayjs());
						}),
				})
				.notRequired(),
			twitter: Yup.object()
				.shape({
					scheduledFor: Yup.date()
						.notRequired()
						.test("schedule-for", "Schedule time for X must be in the future", (value, schema) => {
							const context = schema?.options?.context || {};
							const schedule = context.schedule || {};

							if (schedule.twitter?.id) {
								return true;
							}

							return !value || dayjs(value).isAfter(dayjs());
						}),
				})
				.notRequired(),
			tiktok: Yup.object()
				.shape({
					scheduledFor: Yup.date()
						.notRequired()
						.min(new Date(), "Schedule time for TikTok must be in the future"),
				})
				.notRequired(),
		}),
	});
