import {useCallback, useContext, useEffect} from "react";
import {useFormikContext} from "formik";

import {DragHandleProps} from "./drag-drop";
import {PostEditor} from "./post-editor";
import {PostEditorShuffle} from "./post-editor-shuffle";
import {PostCollectionFormValues} from "./post";
import {useDebounceCallbackWithPromise} from "../../../../hooks/use-debounce-callback";
import {ListSubmitPendingContext} from "./post-list";

interface PostEditorProps {
	onRemove?: () => void;
	loadingAddShuffle: boolean;
	dragHandleProps: DragHandleProps;
	onValidityChange?: (valid: boolean) => void;
}

export const PostEditorSelector = ({
	onRemove,
	loadingAddShuffle,
	dragHandleProps,
	onValidityChange,
}: PostEditorProps) => {
	const {values, isValid, submitForm, setFieldValue} = useFormikContext<PostCollectionFormValues>();
	const {shuffledComments, shuffledImages} = values;
	const listSubmitPendingRef = useContext(ListSubmitPendingContext);
	const debouncedSubmit = useDebounceCallbackWithPromise(submitForm, 2000);
	const onChange = useCallback(
		(field, value) => {
			setFieldValue(field, value);
			const promise = debouncedSubmit();

			if (listSubmitPendingRef) {
				listSubmitPendingRef.current = promise;
			}
		},
		[setFieldValue, debouncedSubmit, listSubmitPendingRef]
	);

	useEffect(() => {
		onValidityChange?.(isValid);
	}, [onValidityChange, isValid]);

	if (shuffledComments && shuffledImages)
		return (
			<PostEditorShuffle
				loadingAddShuffle={loadingAddShuffle}
				onRemove={onRemove}
				dragHandleProps={dragHandleProps}
				onChange={onChange}
			/>
		);

	return <PostEditor onRemove={onRemove} dragHandleProps={dragHandleProps} onChange={onChange} />;
};
