import {ReactElement, useEffect, createContext, useContext, useCallback, useRef} from "react";
import {BrowserAgent} from "@newrelic/browser-agent/loaders/browser-agent";
import {GenericEvents} from "@newrelic/browser-agent/features/generic_events";
import {useQuery} from "@apollo/client";

import {GET_ME} from "../data";
import config from "../config";

type NewrelicProviderProps = {
	children: ReactElement;
};

type EventAttributes = Partial<Record<string, string | number | boolean>>;

type NewrelicContextType = {
	trackCustomEvent: (eventName: string, attributes?: EventAttributes) => void;
};

const NewrelicContext = createContext<NewrelicContextType | undefined>(undefined);

export const NewrelicProvider = ({children}: NewrelicProviderProps): ReactElement => {
	const {data} = useQuery(GET_ME);
	const newrelicAgentRef = useRef<BrowserAgent | null>(null);

	const user = data?.me;
	const userId = user?.id;

	useEffect(() => {
		if (!config.newrelic.enabled || typeof window === "undefined" || newrelicAgentRef.current) {
			return;
		}

		const options = {
			init: {
				session_replay: {
					enabled: true,
					block_selector: "",
					mask_text_selector: "*",
					sampling_rate: 10.0,
					error_sampling_rate: 100.0,
					mask_all_inputs: true,
					collect_fonts: true,
					inline_images: false,
					inline_stylesheet: true,
					fix_stylesheets: true,
					mask_input_options: {},
				},
				distributed_tracing: {enabled: true},
				privacy: {cookies_enabled: true},
				ajax: {deny_list: ["bam.nr-data.net"]},
			},
			info: {
				beacon: "bam.nr-data.net",
				errorBeacon: "bam.nr-data.net",
				licenseKey: config.newrelic.key,
				applicationID: config.newrelic.applicationID,
				sa: 1,
			},
			loader_config: {
				accountID: "4452527",
				trustKey: "4452527",
				agentID: config.newrelic.applicationID,
				licenseKey: config.newrelic.key,
				applicationID: config.newrelic.applicationID,
			},
			features: [GenericEvents],
		};

		newrelicAgentRef.current = new BrowserAgent(options);
	}, []);

	// Automatically set user ID when user data is available
	useEffect(() => {
		const agent = newrelicAgentRef.current;
		if (!agent || !userId) {
			return;
		}
		agent.setUserId(String(userId));
	}, [userId]);

	const trackCustomEvent = useCallback((eventName: string, attributes?: EventAttributes) => {
		const agent = newrelicAgentRef.current;
		if (!agent) {
			return;
		}

		agent.recordCustomEvent(eventName, attributes);
	}, []);

	return <NewrelicContext.Provider value={{trackCustomEvent}}>{children}</NewrelicContext.Provider>;
};

export const useNewrelic = (): NewrelicContextType => {
	const context = useContext(NewrelicContext);
	if (!context) {
		throw new Error("useNewrelic must be used within a NewrelicProvider");
	}
	return context;
};
