import {Dispatch, ReactElement, SetStateAction, useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import classNames from "classnames";
import dayjs from "dayjs";
import {Formik, useFormikContext} from "formik";
import {useQuery} from "@apollo/client";
import useVirtual from "react-cool-virtual";

import {count} from "../../utils/text";
import {
	Button,
	InputRow,
	Option,
	Switch,
	Text,
	Checkbox,
	DropdownButton,
	Separator,
	toggle,
	SearchableSelect,
} from "../../components/input";
import {Arrow, Icon} from "../../components/images";
import {
	Group as GroupSetting,
	DELETE_GROUP,
	UPDATE_GROUP,
	Group as GroupType,
	useSimpleGroup,
	GET_GROUP,
} from "../../data/group";
import {User, useMyUser, useSimpleUsers} from "../../data/user";
import {useCompanyList} from "../../data/company";
import {useConfirmDeleteModal} from "../../modals";
import {useMutationToast} from "../../toast";
import {Badge} from "../../components/badge";
import {P, Span2, Span3, Span4} from "../../components/text";
import {ToggleGroup} from "../../components/toggle";
import {Loading} from "../../components/loading";
import {UserAvatar} from "../../components/user-avatar";

import styles from "./group.module.scss";

interface GroupItemProps {
	group: GroupSetting;
}
interface GroupComponent {
	group: GroupSetting;
	groupOptions: Option<number>[];
}

const VirtualUserList = ({
	users,
	setBulkRemove,
	bulkRemove,
	onUserRemove,
}: {
	users: Partial<User>[];
	bulkRemove: (number | undefined)[];
	setBulkRemove: Dispatch<SetStateAction<(number | undefined)[]>>;
	onUserRemove: (value: number | undefined) => void;
}) => {
	const navigate = useNavigate();
	const {outerRef, innerRef, items} = useVirtual<HTMLDivElement>({itemCount: users.length});
	return (
		<div className={classNames(styles.userList, "space")} ref={outerRef}>
			<div ref={innerRef}>
				{items.map(({index, measureRef}) => {
					const user = users[index];
					if (!user) return null;
					const {id, role, firstName, lastName, virtualAssistant} = user;
					return (
						<div className="space" key={index} ref={measureRef}>
							<InputRow key={id} className={styles.userBox}>
								<Checkbox
									value={bulkRemove.includes(id)}
									onChange={() => setBulkRemove(old => toggle(id, old))}
								/>
								<UserAvatar className="space" size="small" userId={id} />
								<div className="space">
									<P>{[firstName, lastName].join(" ")}</P>
									<Span4>{role}</Span4>
								</div>
								<div className={classNames("space", styles.userVA)}>
									{virtualAssistant && <Badge color="blue" text="VA" />}
								</div>
								<DropdownButton
									icon="ellipsis"
									invert
									color="black"
									border={false}
									options={[
										{
											label: "Open details",
											onClick: () => navigate("/users/list", {state: {id}}),
											icon: "open",
										},
										{label: "Remove from group", onClick: () => onUserRemove(id), icon: "delete"},
									]}
								/>
							</InputRow>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export const GroupItem = ({group}: GroupItemProps): ReactElement => {
	const [show, setShow] = useState(false);
	// const {val, dirty, discard, changes, inputFunc} = useDirtyCopy(group);
	const toggleShow = useCallback(() => setShow(c => !c), [setShow]);
	const [updateGroup] = useMutationToast(UPDATE_GROUP);
	const [initialValues, setInitialValues] = useState<GroupSetting>({...group});

	const handleSave = useCallback(
		(val, {resetForm}) => {
			const differences = Object.keys(val).reduce((acc, key) => {
				if (["editors", "followers", "editorsVAS", "followersVAS"].includes(key)) return acc;

				if (val[key] !== initialValues[key]) {
					if (key === "users") {
						acc[key] = val[key].map(({id}) => id);
					} else {
						acc[key] = val[key];
					}
				}

				return acc;
			}, {});

			return updateGroup({
				variables: {id: val.id, changes: differences},
			}).then(() => {
				resetForm({
					values: val,
					isSubmitting: false,
				});
			});
		},
		[initialValues, updateGroup]
	);

	return (
		<Formik initialValues={group} onSubmit={handleSave}>
			<div className={styles.card}>
				<GroupHeader show={show} toggleShow={toggleShow} />
				{show && (
					<div className={styles.bottom}>
						<Separator horizontal />
						<GroupSettings setInitialValues={setInitialValues} groupId={group.id} />
					</div>
				)}
			</div>
		</Formik>
	);
};

const GroupHeader = ({show, toggleShow}: {show: boolean; toggleShow: () => void}): ReactElement => {
	const {values, setFieldValue} = useFormikContext<GroupSetting>();

	return (
		<div className={styles.top}>
			<div className={styles.header}>
				{show ? (
					<Text placeholder={values.name} onChange={val => setFieldValue("name", val)} value={values.name} />
				) : (
					<h6 className={styles.nameText}>{values.name}</h6>
				)}
				<div>{values.vas !== 0 && <Badge color="blue" text="VA" />}</div>
				<div className={styles.textItem}>
					<h6>{count(values.editors, "Editor")}</h6>
					<Span4 color="grey">{values.editorsVAS} with Virtual Assistant</Span4>
				</div>

				<div className={classNames(styles.textItem)}>
					<h6>{count(values.followers, "Follower")}</h6>
					<Span4 color="grey">{values.followersVAS} with Virtual Assistant</Span4>
				</div>
				<div className={styles.textItem}>
					<h6>{count(values.queues, "Collection")}</h6>
					<Span4 color="grey">
						{values.lastCollection ? `Last received ${dayjs(values.lastCollection).fromNow()}` : ""}
					</Span4>
				</div>
			</div>
			<div className={styles.arrow}>
				<Arrow direction={show ? "up" : "down"} onClick={toggleShow} />
			</div>
		</div>
	);
};

const GroupSettings = ({
	groupId,
	setInitialValues,
}: {
	groupId: number;
	setInitialValues: Dispatch<SetStateAction<GroupSetting>>;
}): ReactElement => {
	const {resetForm, values} = useFormikContext<GroupSetting>();
	const {data, loading} = useQuery(GET_GROUP, {
		variables: {id: groupId},

		onCompleted: data => {
			if (data.group) {
				const updatedValues = {...values, ...data.group};
				setInitialValues(updatedValues);
				resetForm({
					values: updatedValues, // Update current values
					isSubmitting: false, // Ensure the form is not submitting
				});
			}
		},
	});
	const {groups, loading: loadingSimpleGroups} = useSimpleGroup();
	if (loading || loadingSimpleGroups || !values.users) return <Loading position="center" />;
	return <Group group={data.group} groupOptions={groups} />;
};

export const Group = ({group, groupOptions}: GroupComponent): ReactElement => {
	// const {val, update, dirty, discard, changes, inputFunc} = useDirtyCopy(group);
	const {
		values,
		dirty,
		submitForm,
		resetForm,
		isSubmitting: loading,
		setFieldValue,
	} = useFormikContext<GroupSetting>();

	const [view, setView] = useState(false);
	const [bulkRemove, setBulkRemove] = useState<(number | undefined)[]>([]);
	const me = useMyUser();

	const companies = useCompanyList();
	const {users: allUsers} = useSimpleUsers({limit: null, filter: {status: ["active"]}});
	const usersObject = useMemo(() => allUsers.reduce((acc, val) => ({...acc, [val.id]: val}), {}), [allUsers]);
	const onUserSelect = useCallback(
		value => {
			setFieldValue("users", [...values.users, usersObject[value.value]]);
			const user = usersObject[value.value];
			if (user.role === "admin") {
				setFieldValue("editors", values.editors + 1);
				if (user.virtualAssistant) setFieldValue("editorsVAS", values.editorsVAS + 1);
			} else {
				setFieldValue("followers", values.followers + 1);
				if (user.virtualAssistant) setFieldValue("followersVAS", values.followersVAS + 1);
			}
		},
		[
			values.users,
			setFieldValue,
			usersObject,
			values.editors,
			values.editorsVAS,
			values.followers,
			values.followersVAS,
		]
	);
	const onUserRemove = useCallback(
		value => {
			setFieldValue(
				"users",
				values.users.filter(({id}) => id !== value)
			);
			const user = usersObject[value];
			if (user.role === "admin") {
				setFieldValue("editors", values.editors - 1);
				if (user.virtualAssistant) setFieldValue("editorsVAS", values.editorsVAS - 1);
			} else {
				setFieldValue("followers", values.followers - 1);
				if (user.virtualAssistant) setFieldValue("followersVAS", values.followersVAS - 1);
			}
		},
		[
			values.users,
			setFieldValue,
			usersObject,
			values.editors,
			values.editorsVAS,
			values.followers,
			values.followersVAS,
		]
	);
	const selectUsers = useMemo(
		() =>
			allUsers
				.map(user => ({value: user.id, role: user.role, label: user.fullName}))
				.filter(
					({role, value}) =>
						(view ? role !== "admin" : role === "admin") && !values.users.map(({id}) => id).includes(value)
				),
		[view, allUsers, values.users]
	);
	const groupEditorsAndAdmins = useMemo(
		() => values.users.filter(({role}) => role?.toLowerCase() === "admin"),
		[values.users]
	);
	const groupFollowers = values.users.filter(({role}) => role?.toLowerCase() !== "admin");

	const onChangeView = useCallback(val => {
		setView(val);
		setBulkRemove([]);
	}, []);
	const [deleteGroup, {loading: deleting}] = useMutationToast(DELETE_GROUP);

	const handleDelete = (close: () => void) =>
		deleteGroup({
			variables: {id: group.id},
			update(cache, {data}) {
				if (data) {
					cache.evict({id: `Group:${data.deleteGroup.id}`, broadcast: false});
					cache.gc();
				}
			},
			onCompleted: close,
		});

	const {open} = useConfirmDeleteModal({
		what: "group",
		onDelete: handleDelete,
		deleting,
	});

	// const handleSave = useCallback(
	// 	() =>
	// 		updateGroup({
	// 			variables: {id: group.id, changes: {...changes, users: changes?.users?.map(({id}) => id)}},
	// 		}).then(({data}) => {
	// 			if (data) discard();
	// 		}),
	// 	[updateGroup, group, changes, discard]
	// );

	const inputFuncPrettied = useCallback(
		(key: keyof GroupType, options) => {
			const value = values[key];
			const onChange = val => setFieldValue(key, val);
			return {
				value: options.filter(({value: optionValue}) =>
					Array.isArray(value) ? value.includes(optionValue) : optionValue === value
				),
				onChange: items => {
					onChange(items.map(({value}) => value));
				},
			};
		},
		[values, setFieldValue]
	);

	return (
		<div>
			<div className={styles.columns}>
				<div className={styles.members}>
					<h4 className="space">Members</h4>
					<ToggleGroup
						options={[
							{value: false, label: "Editors"},
							{value: true, label: "Followers"},
						]}
						value={view}
						onChange={onChangeView}
					/>
					<Switch
						label="Allow subscription"
						value={!!values.allowSubscription}
						onChange={val => setFieldValue("allowSubscription", val)}
					/>
					<div className={classNames("space", styles.addUser)}>
						<Span3>Add {view ? "Followers" : "Editors"}</Span3>
						<SearchableSelect value={[]} onChange={onUserSelect} options={selectUsers} />
						<Span4>
							{view
								? "Followers receive all emails sent to this group."
								: "Only Admins can be Editors. By default Editors will follow and receive all emails sent to this group. Manage follow settings below."}
						</Span4>
					</div>

					<InputRow position="between">
						<Checkbox
							className={classNames(
								styles.checkboxAll,
								!(view ? groupFollowers : groupEditorsAndAdmins).length && styles.hidden
							)}
							value={
								bulkRemove.length === 0
									? false
									: bulkRemove.length === (view ? groupFollowers : groupEditorsAndAdmins).length
									? true
									: undefined
							}
							onChange={() => {
								if (bulkRemove.length === 0) {
									setBulkRemove(() => values.users.map(({id}) => id));
								} else {
									setBulkRemove(() => []);
								}
							}}
						/>
						<Span2
							bold
							onClick={() => {
								setFieldValue(
									"users",
									values.users.filter(({id}) => !bulkRemove.includes(id))
								);
								setBulkRemove([]);
							}}
							className={classNames(styles.removeMultiple, bulkRemove.length === 0 && styles.hidden)}
						>
							<Icon icon="remove-person" />
							Remove from group
						</Span2>
					</InputRow>
					<VirtualUserList
						users={view ? groupFollowers : groupEditorsAndAdmins}
						bulkRemove={bulkRemove}
						setBulkRemove={setBulkRemove}
						onUserRemove={onUserRemove}
					/>
				</div>
				{view ? null : <div className={styles.divider} />}
				{me.org.features.rbac && !view ? (
					<div className={styles.permissions}>
						<h5>Editor Permissions</h5>
						<div className="space">
							<Switch
								label="Allow editors to change permissions"
								value={values.manageGroups}
								onChange={val => setFieldValue("manageGroups", val)}
							/>

							<Separator horizontal />
						</div>
						<Span3 className="space">Editors in this group can do the following:</Span3>
						<Checkbox
							label="Send Collections to the entire organization"
							value={values.sendAll}
							onChange={val => setFieldValue("sendAll", val)}
						/>
						<Checkbox
							label="Make changes to branding and email templates"
							value={values.editCompanyBranding}
							onChange={val => setFieldValue("editCompanyBranding", val)}
						/>
						<Checkbox
							label="Changes the organization’s primary admin"
							value={values.setPrimaryAdmin}
							onChange={val => setFieldValue("setPrimaryAdmin", val)}
						/>
						<Checkbox
							label="Make changes social media approval requirement"
							value={values.manageSocialMediaConnection}
							onChange={val => setFieldValue("manageSocialMediaConnection", val)}
						/>
						<Checkbox
							label="Create Collections"
							value={values.createCollections}
							onChange={val => setFieldValue("createCollections", val)}
						/>
						<Checkbox
							label="Add content to Collections"
							value={values.addContent}
							onChange={val => setFieldValue("addContent", val)}
						/>
						<Checkbox
							label="Approve Collections"
							value={values.approveCollections}
							onChange={val => setFieldValue("approveCollections", val)}
						/>

						<SearchableSelect
							className={classNames(styles.flexColumn, "space")}
							label="Send Collections to:"
							isMulti
							{...inputFuncPrettied("queueGroupIds", groupOptions)}
							options={groupOptions}
							disableDropdownIndicator
						/>
						<SearchableSelect
							className={classNames(styles.flexColumn, "space")}
							label="Invite/remove users to:"
							isMulti
							{...inputFuncPrettied("addUserGroupIds", groupOptions)}
							options={groupOptions}
							disableDropdownIndicator
						/>
						<SearchableSelect
							className={classNames(styles.flexColumn, "space")}
							label="Manage these company social media accounts:"
							isMulti
							{...inputFuncPrettied("manageCompanyIds", companies)}
							options={companies}
							disableDropdownIndicator
						/>
						<SearchableSelect
							className={classNames(styles.flexColumn, "space")}
							label="Send Collections to these Corporate Accounts:"
							isMulti
							{...inputFuncPrettied("queueCompanyIds", companies)}
							options={companies}
							disableDropdownIndicator
						/>
					</div>
				) : null}
			</div>
			<InputRow position="between">
				<Button icon="delete" invert border={false} color="pink" onClick={open} value="De-activate Group" />
				<InputRow position="right">
					<Button value="Discard" invert color="pink" disabled={!dirty} onClick={resetForm} />
					<Button value="Save" disabled={!dirty} onClick={submitForm} loading={loading} />
				</InputRow>
			</InputRow>
		</div>
	);
};
