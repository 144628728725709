import {useNavigate} from "react-router-dom";
import {useContext} from "react";

import {count} from "../../utils/text";
import {useCompanyList} from "../../data";
import {Loading} from "../../components/loading";
import {ModalHook, useModal} from "../../modals";
import {Button, InputRow} from "../../components/input";
import {P, Span3} from "../../components/text";
import {Icon} from "../../components/images";
import {UserAvatar} from "../../components/user-avatar";
import {CalendarItem} from ".";
import {PostModalContext} from "../../providers/PostModalProvider";

import styles from "./schedule.module.scss";

interface EventModalProps {
	calendarItem: CalendarItem | undefined;
}

export function useEventModal<T>({calendarItem}: EventModalProps): ModalHook<T> {
	const companies = useCompanyList();
	const navigate = useNavigate();
	const {open: openPostModal} = useContext(PostModalContext);
	return useModal(
		({close}) => {
			if (!calendarItem)
				return {
					header: <h3>Loading...</h3>,
					body: <Loading />,
					footer: (
						<InputRow position="center">
							<Button value="Open Item" invert border={false} disabled onClick={() => undefined} />
						</InputRow>
					),
				};
			const {id, type, shareId, opengraph: og} = calendarItem;
			return {
				header: <h3>{og?.title || "Untitled"}</h3>,
				body: (
					<>
						<P color="grey">{og?.comment}</P>
						{calendarItem.userId ? (
							<UserAvatar userId={calendarItem.userId} name className="space" />
						) : (
							<P>{companies.find(c => c.value === calendarItem.companyId)?.label ?? "Unknown Account"}</P>
						)}
						<InputRow position="between">
							<div className={styles.information}>
								<Icon icon="calendar" height={16} />
								<Span3 bold color="grey">
									{calendarItem.date.format("ddd, MMM D")}
								</Span3>
							</div>
							<div className={styles.information}>
								<Icon icon="schedule" height={16} />
								<Span3 bold color="grey">
									{calendarItem.date.formatAs("time")}
								</Span3>
							</div>
							{type === "collection" && (
								<div className={styles.information}>
									<Icon icon="news-feed" height={16} />
									<Span3 bold color="grey">
										{count(calendarItem.posts, "Post")}
									</Span3>
								</div>
							)}
						</InputRow>
					</>
				),
				footer: (
					<InputRow position="center">
						<Button
							value={`Open ${type.uFirst()}`}
							invert
							border={false}
							onClick={() => {
								if (type === "collection") {
									navigate(`/collections/${id}`, {
										state: {from: "/schedule"},
									});
								}
								close();
								openPostModal({
									postId: shareId,
									// type === "post" ? `posts/${shareId}` :
								});
							}}
						/>
					</InputRow>
				),
			};
		},
		[calendarItem, companies, navigate, openPostModal]
	);
}
