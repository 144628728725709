import dayjs, {Dayjs} from "dayjs";
import {gql} from "@apollo/client";

import {Company} from "./company";
import {OPENGRAPH_FRAGMENT, OpenGraph} from "./opengraph";
import {loadDate} from ".";
import {Visitor, VISITOR_FRAGMENT} from "./visitor";

export type FeedPostType = "shared" | "recommended" | "created";

export interface FeedPost {
	id: number;
	userId?: number;
	url?: string;
	sharedAt?: Dayjs;
	createdAt: Dayjs;
	item: OpenGraph;
	company?: Company;
	shareStatus?: "shared" | "unshared" | "scheduled";
	clickCount?: number;
	imageCount?: number;
	visitors?: Visitor[];
	emv?: number;
	createdBy?: number;
	type?: FeedPostType;
	queuedUrlId?: number;
}

export interface SuggestedShare {
	id: number;
	userId: number;
	comment?: string;
	opengraph: OpenGraph;
	url: string;
	created: Dayjs;
}

export const FEED_POST_FRAGMENT = gql`
	fragment FeedPostFields on FeedPost {
		id
		userId
		sharedAt
		createdAt
		url
		item {
			...OpenGraphFields
		}
		company {
			id
			name
		}
		queuedUrlId
		type
		shareStatus
		clickCount
		imageCount
		visitors {
			...VisitorFields
		}
		emv
		createdBy
	}
	${OPENGRAPH_FRAGMENT}
	${VISITOR_FRAGMENT}
`;

export const SUGGESTED_SHARES_FRAGMENT = gql`
	fragment SuggestedSharesFields on SuggestedShare {
		id
		userId
		comment
		opengraph {
			...OpenGraphFields
		}
		url
		created
	}
	${OPENGRAPH_FRAGMENT}
`;

export const GET_SUGGESTION_POSTS = gql`
	query SuggestedPosts(
		$cursor: String
		$companyId: PositiveInt
		$type: FeedPostType
		$sort: FeedPostSort
		$limit: PositiveInt
	) {
		suggestedPosts(cursor: $cursor, companyId: $companyId, type: $type, sort: $sort, limit: $limit) {
			cursor
			items {
				...FeedPostFields
			}
		}
	}
	${FEED_POST_FRAGMENT}
`;

export const GET_MY_HUB = gql`
	query GetMyHub(
		$cursor: String
		$type: [FeedPostType!]
		$sort: FeedPostSort
		$limit: PositiveInt
		$search: String
	) {
		myHub(cursor: $cursor, type: $type, sort: $sort, limit: $limit, search: $search) {
			cursor
			items {
				...FeedPostFields
			}
		}
	}
	${FEED_POST_FRAGMENT}
`;

export const GET_COMPANY_SHARES = gql`
	query CompanyShares(
		$cursor: String
		$ids: [PositiveInt!]
		$sort: FeedPostSort
		$limit: PositiveInt
		$search: String
	) {
		companyShares(cursor: $cursor, ids: $ids, sort: $sort, limit: $limit, search: $search) {
			cursor
			items {
				...FeedPostFields
			}
		}
	}
	${FEED_POST_FRAGMENT}
`;

export const GET_SUGGESTED_SHARES = gql`
	query SuggestedShares($cursor: String, $id: PositiveInt, $limit: PositiveInt) {
		suggestedShares(cursor: $cursor, id: $id, limit: $limit) {
			cursor
			items {
				...SuggestedSharesFields
			}
		}
	}
	${SUGGESTED_SHARES_FRAGMENT}
`;

export const HIDE_SUGGESTED_SHARE = gql`
	mutation HideSuggestedShare($id: PositiveInt!) {
		hideSuggestedShare(id: $id) {
			id
		}
	}
`;

export const HIDE_FEED_POST = gql`
	mutation HideFeedPost($id: PositiveInt!) {
		hideFeedPost(id: $id) {
			id
		}
	}
`;
export const HIDE_RSS_FEED_POST = gql`
	mutation HideRssFeedPost($id: PositiveInt!) {
		hideRssFeedPost(id: $id) {
			id
		}
	}
`;

export const GET_YOUR_RECENT_SHARES_COUNT = gql`
	query YourRecentSharesCount {
		yourRecentSharesCount
	}
`;

export const inflateSuggestedShare = (raw): SuggestedShare => ({
	...raw,
	created: dayjs(raw.created),
});

export const inflateFeedPost = (raw): FeedPost => ({
	...raw,
	sharedAt: loadDate(raw.sharedAt),
	createdAt: dayjs(raw.createdAt),
	type: raw.type.toLowerCase(),
	shareStatus: raw.shareStatus.toLowerCase(),
	visitors: raw.visitors?.map(visitor => ({
		...visitor,
		dateVisited: raw.dateVisited ? dayjs(raw.dateVisited) : raw.dateVisited,
	})),
});
