import React, {useCallback, useMemo} from "react";
import classNames from "classnames";
import {useQuery} from "@apollo/client";

import {PostProps} from "./index";
import {P, P4, Span4} from "../../../../components/text";
import {Icon, IconType} from "../../../../components/images";
import {GET_USER_CONNECTION_PROFILES} from "../../../../data";

import styles from "./styles.module.scss";

const bStyle = classNames.bind(styles);

const PostButton = ({
	icon,
	text,
	width,
	viewBox,
}: {
	icon: IconType;
	text?: string;
	width?: number;
	viewBox?: string;
}) => (
	<div className={styles.button}>
		<Icon icon={icon} width={width || 24} color={"white"} viewBox={viewBox} />
		{text && <Span4 bold>{text}</Span4>}
	</div>
);

export const TikTokPost = ({user, url, opengraphData}: PostProps) => {
	const {title, comment, image, video} = opengraphData;
	const text = comment || title;
	const {data: profiles} = useQuery(GET_USER_CONNECTION_PROFILES, {fetchPolicy: "cache-only"});
	const userName = useMemo(
		() =>
			profiles?.userConnectionProfiles?.tiktok?.fullName ||
			user?.connections?.tiktok?.displayName ||
			user.fullName,
		[user, profiles]
	);
	const profilePic = useMemo(() => profiles?.userConnectionProfiles?.tiktok?.imageUrl || user.profilePic, [
		user,
		profiles,
	]);

	const getUserAvatar = useCallback(
		(className?: string) => (
			<div className={bStyle(styles.avatar, className)}>
				{profilePic ? (
					<>
						<img src={profilePic} alt={userName} />
						<div className={styles.opacity} />
					</>
				) : (
					<span>{`${user.firstName.substring(0, 1).toUpperCase()}${user.lastName
						.substring(0, 1)
						.toUpperCase()}`}</span>
				)}
			</div>
		),
		[user, userName, profilePic]
	);

	if (!image && !video) {
		return <div className={styles.invalidPost}>The post is not valid for TikTok</div>;
	}

	return (
		<div
			className={bStyle(styles.postCard, {
				[styles.tiktok]: true,
				[styles.border]: false,
				[styles.filled]: true,
			})}
		>
			{image && !video && <img src={image} alt={title} />}
			{!url && video && (
				<video src={video} autoPlay={true} controls={false} muted preload="metadata">
					<source src={video} />
					<track kind="captions" srcLang="en" label="english_captions" />
				</video>
			)}
			<div className={styles.postBody}>
				<div className={styles.content}>
					<P bold>@{userName}</P>
					<div className={styles.comment}>{text && <P4 trim={3}>{text}</P4>}</div>
				</div>
				<div className={styles.toolbar}>
					{getUserAvatar()}
					<PostButton icon={"heart"} viewBox={"0 0 16 16"} width={16} />
					<Icon icon={"ellipsis"} color={"white"} width={8} />
					<PostButton icon={"comment"} width={16} viewBox={"0 0 32 32"} />
					<Icon icon={"ellipsis"} color={"white"} width={8} />
					<PostButton icon={"filled-bookmark"} />
					<Icon icon={"ellipsis"} color={"white"} width={8} />
					<PostButton icon={"redo"} />
					<Icon icon={"ellipsis"} color={"white"} width={8} />
					{getUserAvatar(styles.profilePic)}
				</div>
			</div>
		</div>
	);
};
