import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import dayjs, {Dayjs} from "dayjs";

import {Option} from "../components/input";
import {Org, ORG_FRAGMENT} from "./org";
import {CONNECTIONS_FRAGMENT, Connections} from "./connection";
import {loadDate, Service} from ".";

export type Role = "admin" | "user" | "external";

export const roleOptions: Option<Role>[] = [
	{value: "admin", label: "Admin"},
	{value: "user", label: "User"},
	{value: "external", label: "Email Subscriber"},
];

export interface BaseUser {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	fullName: string;
	profilePic: string | null;
}

export type Activity = "share" | "no_login" | "no_share" | "expired_network";

export type UserStatus = "active" | "inactive" | "pending";

export type UserFilter = {
	roles?: Role[];
	activity?: Activity[];
	connected?: Service[];
	disconnected?: Service[];
	status?: UserStatus[];
	groups?: number[];
	virtualAssistant?: boolean;
	isContestOwner?: boolean;
};

export type UserVariables = {
	limit?: number | null;
	filter?: UserFilter;
	cursor?: string;
	search?: string;
};

type UserIntegrations = {
	canva: boolean;
};
export interface User extends BaseUser {
	groups: number[];
	created: Dayjs;
	lastShared?: Dayjs;
	lastLogin?: Dayjs;
	role: Role;
	status: "inactive" | "active" | "pending";
	connections: Connections;
	org: Org;
	virtualAssistant: boolean;
	emailSubscribed: boolean;
	marketingSubscribed: boolean;
	timeZone: string;
	peakTime: boolean;
	peakTimeSlots: Dayjs[];
	internal: boolean;
	followedGroups: number[];
	integrations: UserIntegrations;
	lastInvited?: Dayjs;
}

export interface NewUser {
	firstName: string;
	lastName: string;
	email: string;
	role?: Role;
	virtualAssistant?: boolean;
	groups: number[];
}

export interface LeaderBoard {
	shares: number;
	clicks: number;
	emv: number;
	score: number;
	smartScore: number;
	rank: number;
	experience: number;
}

export interface UserBadge {
	id: number;
	description: string;
	imageUrl: string;
	title: string;
	experience: number;
}

export type AdoptionStatus = "INVITED" | "SHARED_ONCE" | "SHARED_MULTIPLE" | "NO_ACTIVITY";

export interface UserAdoption {
	userId: number;
	experience: number;
	rank: number;
	status: AdoptionStatus;
	fullName: string;
	email: string;
	groups: number[];
	invitationSentOn: Dayjs;
	signupTime: Dayjs;
}

export const USER_LEADERBOARD_FRAGMENT = gql`
	fragment LeaderBoardFields on LeaderBoard {
		shares
		clicks
		emv
		score
		smartScore
		rank
		experience
		created
	}
`;

export const USER_BADGE_FRAGMENT = gql`
	fragment UserBadgeFields on UserBadge {
		id
		description
		imageUrl
		title
		experience
	}
`;

export const USER_ADOPTION_STATS_FRAGMENT = gql`
	fragment UserAdoptionFields on AdoptionStats {
		userId
		status
	}
`;

export const USER_ADOPTION_FRAGMENT = gql`
	fragment UsersAdoptionFields on UserAdoption {
		userId
		experience
		rank
		status
		fullName
		email
		groups
		invitationSentOn
		signupTime
	}
`;

export const USER_FRAGMENT = gql`
	fragment UserFields on User {
		id
		created
		org {
			...OrgFields
		}
		firstName
		lastName
		profilePic
		lastLogin
		groups {
			id
		}
		lastShared
		role
		status
		email
		virtualAssistant
		marketingSubscribed
		emailSubscribed
		timeZone
		peakTime
		peakTimeSlots
		created
		connections {
			...ConnectionsFields
		}
		integrations {
			canva
		}
		internal
		followedGroups
		lastInvited
	}
	${ORG_FRAGMENT}
	${CONNECTIONS_FRAGMENT}
`;

export const BASE_USER_FRAGMENT = gql`
	fragment BaseUserFields on User {
		id
		firstName
		lastName
		profilePic
		role
	}
`;
export const SIMPLE_USER_FRAGMENT = gql`
	fragment SimpleUserFields on User {
		id
		firstName
		lastName
		profilePic
		role
		status
		virtualAssistant
	}
`;

export const USER_LEADERBOARD_ITEMS_FRAGMENT = gql`
	fragment UserLeaderboardItemsFields on UserLeaderboard {
		leaderBoard {
			...LeaderBoardFields
		}
		badges {
			...UserBadgeFields
		}
		userId
	}
	${USER_LEADERBOARD_FRAGMENT}
	${USER_BADGE_FRAGMENT}
`;

export const GET_USERS = gql`
	query Users($cursor: String, $limit: PositiveInt, $search: String, $sort: UserSort, $filter: UserFilter) {
		users(limit: $limit, cursor: $cursor, search: $search, sort: $sort, filter: $filter) {
			cursor
			items {
				...UserFields
			}
		}
	}
	${USER_FRAGMENT}
`;

export const GET_SIMPLE_USERS = gql`
	query SimpleUsers(
		$cursor: String
		$limit: PositiveInt
		$search: String
		$sort: UserSort
		$filter: UserFilter
	) {
		users(limit: $limit, cursor: $cursor, search: $search, sort: $sort, filter: $filter) {
			cursor
			items {
				...SimpleUserFields
			}
		}
	}
	${SIMPLE_USER_FRAGMENT}
`;

export const GET_MENTIONABLE_USERS = gql`
	query MentionableUsers(
		$search: String
		$userFilter: UserFilter
		$companyFilter: CompanyFilter
		$limit: PositiveInt
	) {
		users(filter: $userFilter, search: $search, limit: $limit) {
			items {
				...BaseUserFields
				connections {
					...ConnectionsFields
				}
			}
		}
		companies(search: $search, filter: $companyFilter) {
			id
			name
			connections {
				...ConnectionsFields
			}
		}
	}
	${BASE_USER_FRAGMENT}
	${CONNECTIONS_FRAGMENT}
`;

export const GET_BASE_USER = gql`
	query BaseUser($id: PositiveInt!) {
		user(id: $id) {
			...BaseUserFields
		}
	}
	${BASE_USER_FRAGMENT}
`;

export const GET_PENDING_USERS = gql`
	query PendingUsers {
		pendingUsers {
			...BaseUserFields
			email
			lastInvited
		}
	}
	${BASE_USER_FRAGMENT}
`;

export const GET_EXPIRED_SOCIAL_MEDIA_USERS = gql`
	query ExpiredSocialMediaUsers {
		expiredSocialMediaUsers {
			...BaseUserFields
		}
	}
	${BASE_USER_FRAGMENT}
`;

export const GET_USER_LIST = gql`
	query {
		users(limit: null) {
			items {
				...BaseUserFields
			}
		}
	}
	${BASE_USER_FRAGMENT}
`;

export const GET_USER = gql`
	query User($id: PositiveInt!) {
		user(id: $id) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;
export const GET_ME = gql`
	query Me {
		me {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const GET_MY_STATS = gql`
	query MyStats {
		myStats {
			id
			contestsCount
		}
	}
`;

export const GET_RELEASE_CHANNEL = gql`
	query ReleaseChannel {
		releaseChannel {
			name
			domain
		}
	}
`;
export const UPDATE_ME = gql`
	mutation UpdateMe($changes: UpdateMeChanges!) {
		updateMe(changes: $changes) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const CONNECT_FACEBOOK_PAGE = gql`
	mutation ConnectFacebookPage($id: String!, $accessToken: String!) {
		connectFacebookPage(id: $id, accessToken: $accessToken) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const CONNECT_INSTAGRAM_PAGE = gql`
	mutation ConnectInstagramPage($id: String!, $accessToken: String!) {
		connectInstagramPage(id: $id, accessToken: $accessToken) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const DEACTIVATE_USERS = gql`
	mutation DeactivateUsers($ids: [PositiveInt!], $file: Upload) {
		deactivateUsers(ids: $ids, file: $file)
	}
`;

export const REINVITE_USERS = gql`
	mutation ReinviteUsers($ids: [PositiveInt!]!) {
		reinviteUsers(ids: $ids)
	}
`;

export const REMIND_RECONNECT = gql`
	mutation RemindReconnect($ids: [PositiveInt!]!) {
		remindReconnect(ids: $ids)
	}
`;

export const DISCONNECT_USER_SOCIAL = gql`
	mutation Disconnect($type: ConnectionType!) {
		disconnect(type: $type) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const UPDATE_USER = gql`
	mutation UpdateUser($id: PositiveInt!, $changes: UserChanges!) {
		updateUser(id: $id, changes: $changes) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const ADD_USER = gql`
	mutation AddUser($newUser: NewUser!) {
		addUser(newUser: $newUser) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const UPLOAD_USERS = gql`
	mutation AddUser($file: Upload!) {
		uploadUsers(file: $file) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

export const GET_LEADER_BOARD = gql`
	query LeaderBoard($userId: PositiveInt) {
		leaderBoard(userId: $userId) {
			...LeaderBoardFields
		}
	}
	${USER_LEADERBOARD_FRAGMENT}
`;

export const GET_USERS_LEADER_BOARD = gql`
	query UserLeaderBoardPage(
		$cursor: String
		$search: String
		$groupId: PositiveInt
		$start: DateTime
		$end: DateTime
		$global: Boolean
	) {
		usersLeaderBoard(
			cursor: $cursor
			search: $search
			groupId: $groupId
			start: $start
			end: $end
			global: $global
		) {
			items {
				...UserLeaderboardItemsFields
			}
			cursor
		}
	}
	${USER_LEADERBOARD_ITEMS_FRAGMENT}
`;

export const GET_DAILY_LEADERBOARD = gql`
	query LeaderBoard($start: DateTime!, $end: DateTime!, $userId: PositiveInt) {
		dailyLeaderBoard(start: $start, end: $end, userId: $userId) {
			...LeaderBoardFields
		}
	}
	${USER_LEADERBOARD_FRAGMENT}
`;
export const GET_DAILY_LEADERBOARD_RANK = gql`
	query LeaderBoardRank($start: DateTime, $end: DateTime, $userId: PositiveInt) {
		dailyLeaderBoardRank(start: $start, end: $end, userId: $userId) {
			rank
		}
	}
`;

export const GET_USER_BADGES = gql`
	query UserBadges($limit: PositiveInt, $userId: PositiveInt) {
		earnedBadges(limit: $limit, userId: $userId) {
			...UserBadgeFields
		}
	}
	${USER_BADGE_FRAGMENT}
`;

export const SEND_EMAIL_CSV = gql`
	mutation EmailResponse(
		$startDate: DateTime
		$endDate: DateTime
		$type: EmailType!
		$groupIds: [PositiveInt!]
		$companyIds: [PositiveInt!]
	) {
		csvEmail(
			startDate: $startDate
			endDate: $endDate
			type: $type
			groupIds: $groupIds
			companyIds: $companyIds
		) {
			message
		}
	}
`;

export const GET_USER_ADOPTION_STATS = gql`
	query AdoptionStats($start: DateTime!, $end: DateTime!) {
		adoptionStats(start: $start, end: $end) {
			...UserAdoptionFields
		}
	}
	${USER_ADOPTION_STATS_FRAGMENT}
`;

export const GET_TOTAL_ACTIVE_USERS = gql`
	query TotalActiveUsers($start: DateTime, $end: DateTime) {
		totalActiveUsers(start: $start, end: $end)
	}
`;

export const GET_USERS_ADOPTION = gql`
	query UsersAdoption(
		$groups: [PositiveInt!]
		$status: AdoptionStatus
		$start: DateTime
		$end: DateTime
		$sort: UserSort
		$cursor: String
		$limit: PositiveInt
	) {
		usersAdoption(
			groups: $groups
			status: $status
			start: $start
			end: $end
			sort: $sort
			cursor: $cursor
			limit: $limit
		) {
			items {
				...UsersAdoptionFields
			}
			cursor
		}
	}
	${USER_ADOPTION_FRAGMENT}
`;

export const SEND_FEEDBACK = gql`
	mutation SendFeedback($message: String!, $changes: UpdateMeChanges!) {
		sendFeedback(message: $message) {
			message
		}
		updateMe(changes: $changes) {
			...UserFields
		}
	}
	${USER_FRAGMENT}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadUser = (user: Record<string, any>): User =>
	({
		...user,
		fullName: [user.firstName, user.lastName].join(" "),
		lastLogin: loadDate(user.lastLogin),
		lastShared: loadDate(user.lastShared),
		lastInvited: loadDate(user.lastInvited),
		created: dayjs(user.created),
		role: user.role.toLowerCase(),
		status: user.status.toLowerCase(),
		...(user?.org
			? {
					org: {
						...user.org,
						created: dayjs(user.org.created),
					},
			  }
			: {}),
		groups: user.groups.map(g => g.id),
		timeZone: user.timeZone ?? dayjs.tz.guess(),
		peakTimeSlots: user.peakTimeSlots.map(d => dayjs(d)),
	} as User);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadBaseUser = (user: Record<string, any>): User =>
	({
		...user,
		role: user?.role?.toLowerCase(),
		fullName: [user.firstName, user.lastName].join(" "),
	} as User);

export const useMyUser = (): User => {
	const {data} = useQuery(GET_ME);

	return useMemo(() => loadUser(data.me), [data]);
};

export const filterToUpperCase = (filter: UserFilter | undefined): UserFilter => {
	if (!filter || Object.keys(filter).length === 0) return {};
	const cpy = Object.keys(filter).reduce((acc, key) => {
		if (key === "virtualAssistant" && filter[key]) {
			acc[key] = filter[key];
		}
		if (filter?.[key]?.length > 0) {
			acc[key] = filter[key].map(el => (typeof el === "string" ? el.toUpperCase() : el));
		} else if (typeof filter[key] === "boolean") {
			acc[key] = filter[key];
		}
		return acc;
	}, {});

	return cpy;
};

export const useUser = (userId?: number): User => {
	const query = userId ? GET_USER : GET_ME;
	const variables = userId ? {id: userId} : undefined;
	const {data, loading, error} = useQuery(query, {variables});

	return useMemo(() => {
		const emptyUser = {
			id: 0,
			email: "",
			groups: [],
			created: dayjs(),
			firstName: "",
			lastName: "",
			fullName: "",
			profilePic: "",
			role: "user",
			status: "active",
			connections: {},
			org: {
				created: dayjs(),
			},
			virtualAssistant: false,
			emailSubscribed: false,
			marketingSubscribed: false,
			timeZone: "",
			peakTime: false,
			peakTimeSlots: [],
			followedGroups: [],
		};

		const loadingUser = {
			...emptyUser,
			firstName: "Loading",
			profilePic: "/user-loading.png",
		};

		const errorUser = {
			...emptyUser,
			firstName: "Unknown",
			lastName: "User",
			profilePic: "/user-error.png",
		};

		const user = userId ? data?.user : data?.me;

		return loadUser(error ? errorUser : loading ? loadingUser : user);
	}, [userId, data, error, loading]);
};

export const useBaseUser = (userId?: number): BaseUser => {
	const query = userId ? GET_BASE_USER : GET_ME;
	const variables = userId ? {id: userId} : undefined;
	const {data, loading, error} = useQuery(query, {variables});

	return useMemo(() => {
		const emptyUser = {
			id: userId || 0,
			firstName: "",
			lastName: "",
			fullName: "",
			profilePic: null,
		};

		if (loading)
			return {
				...emptyUser,
				firstName: "Loading",
				profilePic: "/user-loading.png",
			};

		if (error)
			return {
				...emptyUser,
				firstName: "Unknown",
				lastName: "User",
				profilePic: "/user-error.png",
			};

		const user = userId ? data?.user : data?.me;
		return {...user, fullName: `${user.firstName} ${user.lastName}`};
	}, [userId, data, error, loading]);
};

export const sortUser = (a: User, b: User): number =>
	a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName);

export const useUsers = (): User[] => {
	const {data, loading, error} = useQuery(GET_USERS, {variables: {limit: null}});

	return useMemo(() => {
		if (loading || error || !data?.users.items) {
			return [];
		}

		return data.users.items.map(loadUser).sort(sortUser);
	}, [data, loading, error]);
};

export const useSimpleUsers = (variables?: UserVariables): {users: User[]; loading: boolean} => {
	const {data, loading, error} = useQuery(GET_SIMPLE_USERS, {
		variables: variables
			? {
					...variables,
					filter: filterToUpperCase(variables.filter),
			  }
			: {limit: null},
	});

	return {
		users: useMemo(() => {
			if (loading || error || !data?.users.items) {
				return [];
			}

			return data.users.items.map(loadBaseUser).sort(sortUser);
		}, [data, loading, error]),
		loading,
	};
};

export const usePendingUsers = (): User[] => {
	const {data, loading, error} = useQuery(GET_PENDING_USERS);

	return useMemo(() => {
		if (loading || error) return [];
		return data.pendingUsers.map(loadBaseUser);
	}, [data, loading, error]);
};

export const useExpiredSocialMediaUsers = (): User[] => {
	const {data, loading, error} = useQuery(GET_EXPIRED_SOCIAL_MEDIA_USERS);

	return useMemo(() => {
		if (loading || error) return [];
		return data.expiredSocialMediaUsers.map(loadBaseUser);
	}, [data, loading, error]);
};

export const useUserList = (role?: Role): {users: Option<number>[]; loading: boolean} => {
	const {data, loading, error} = useQuery(GET_USER_LIST);

	return useMemo(() => {
		if (loading || error) return {users: [], loading};

		const users = [...data.users.items]
			.filter(user => !role || user.role.toLowerCase() === role)
			.sort(sortUser)
			.map(user => ({value: user.id, label: [user.firstName, user.lastName].join(" ")}));

		return {users, loading};
	}, [data, loading, error, role]);
};

export const LEVEL_XP = [0, 250, 750, 1500, 2500, 5000, 10000, 20000, 35000, 50000];
export const getUserRank = (experience?: number) => {
	if (!experience) return 0;
	return experience < LEVEL_XP[LEVEL_XP.length - 1]
		? LEVEL_XP.findIndex(level => experience <= level)
		: LEVEL_XP.length;
};

export interface UsersLeaderBoard {
	leaderBoard: LeaderBoard;
	badges: UserBadge[];
	userId: number;
}
