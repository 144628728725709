import classnames from "classnames";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {useCallback, useState} from "react";

import {Card} from "../../../components/card";
import {P} from "../../../components/text";
import {Button, InputRow} from "../../../components/input";
import {CANVA_INTEGRATION, DISCONNECT_CANVA} from "../../../data/canva";
import {useMutationToast} from "../../../toast";
import {useConfirmModal} from "../../../modals";
import config from "../../../config";

import styles from "./integrations.module.scss";

export const CanvaCard = () => {
	const [reconnect, setReconnect] = useState(false);
	const {data: canvaIntegrationData, loading: loadingCanvaIntegration} = useQuery(CANVA_INTEGRATION, {
		onError: () => {
			setReconnect(true);
		},
	});
	const [disconnect, {loading: loadingDisconnect}] = useMutationToast(DISCONNECT_CANVA, {
		onCompleted: () => {
			setReconnect(false);
		},
	});

	const connected = canvaIntegrationData?.canvaIntegration?.connected ?? false;
	const onConnect = useCallback(() => {
		window.location.href = `${config.appUrl.replace(
			"localhost",
			"127.0.0.1"
		)}/canva-auth?referer=${encodeURIComponent(window.location.href)}`;
	}, []);

	const confirmDeleteModal = useConfirmModal(
		() => ({
			title: `Disconnect Canva`,
			body: `Are you sure you want to disconnect the Canva integration?`,
			onConfirm: close => {
				disconnect();
				close();
			},
			confirming: loadingDisconnect,
		}),
		[disconnect, loadingDisconnect]
	);

	return (
		<Card space={false} className={classnames(styles.integrationsCard)}>
			<div className={classnames(styles.header, "space")}>
				<Link to={"https://www.canva.com/"} target="_blank">
					<img src={"/svgs/canva.svg"} />
				</Link>
				<h5>Canva</h5>
			</div>
			<P color="grey">
				Connect your Canva account to easily import designs and use them in your social media posts. While
				design edits remain within Canva, this integration lets you quickly access and share your creations,
				streamlining your content workflow. <br />
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://help.clearviewsocial.com/integrating-with-canva"
				>
					Learn more
				</a>{" "}
				about the Canva integration.
			</P>
			{reconnect && (
				<P color="red">
					Your Canva connection has expired. Please reconnect to continue using the integration.
				</P>
			)}
			<InputRow className={styles.bottom}>
				{reconnect && (
					<Button
						value="Reconnect"
						loading={loadingCanvaIntegration}
						onClick={onConnect}
						icon="refresh"
						invert
					/>
				)}
				{connected || reconnect ? (
					<Button value="Disconnect" loading={loadingDisconnect} invert onClick={confirmDeleteModal.open} />
				) : (
					<Button icon="open" loading={loadingCanvaIntegration} value="Connect" onClick={onConnect} />
				)}
			</InputRow>
		</Card>
	);
};
