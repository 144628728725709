import {FC, useCallback, useMemo} from "react";
import classNames from "classnames";

import {accountNames, Connections, getAvailableServices, Service, useMyUser} from "../../data";
import {Modal, ModalData} from "../../modals/new";
import {Social} from "../../components/social";
import {Span2, Span3} from "../../components/text";
import {HoverTooltip} from "../../components/tooltip";
import {InputRow} from "../../components/input";
import config from "../../config";
import {Icon} from "../../components/images";

import styles from "./networks-modal.module.scss";

export interface NetworksModalProps {
	modal: ModalData;
	connections: Connections;
}

export const NetworksModal: FC<NetworksModalProps> = ({modal, connections}) => {
	const user = useMyUser();
	const updatedModal = useMemo<ModalData>(
		() => ({
			...modal,
			close: () => {
				modal.close();
			},
		}),
		[modal]
	);

	const onNetworkClick = useCallback((network: Service) => {
		let url = config[`${network}Url`];

		if (["facebook", "instagram"].includes(network)) {
			url = config.facebookUrl;
		}

		window.open(`${url}?redirect_override=settings&service=${network}`, "_self");
	}, []);

	const availableNetworks = useMemo(() => getAvailableServices(user), [user]);

	return (
		<Modal modal={updatedModal}>
			<div className={styles.container}>
				<Icon icon={"socialNetworks"} width={128} height={128} viewBox={"0 0 128 128"} />
				<h1 className="space">Uh oh!</h1>
				<Span2>
					Connect your social account below to properly use Clearview Social. Add at least one network to get
					started.
				</Span2>
				<InputRow className={styles.networksContainer}>
					{connections &&
						Object.entries(connections)
							.filter(([key]) => availableNetworks.includes(key as Service))
							.map(([key, value], index) => (
								<HoverTooltip
									text={`Click to ${value?.connected ? "refresh" : "connect"} ${accountNames[key]} account`}
									positions={["bottom"]}
									key={index}
								>
									<div
										className={classNames(
											styles.networks,
											value?.connected && styles.active,
											value?.expired && styles.expired
										)}
										onClick={() => onNetworkClick(key as Service)}
									>
										<Social name={key as Service} active={value?.connected} size="medium" />
										<Span3 color={value?.expired ? "pink" : value?.connected ? "black" : "grey"}>
											{value?.expired ? "Expired" : value?.connected ? "Connected" : "Disconnected"}
										</Span3>
									</div>
								</HoverTooltip>
							))}
				</InputRow>
			</div>
		</Modal>
	);
};
