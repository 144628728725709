import {ReactElement, useCallback} from "react";

import {Component} from "../../types";
import {RssFeed, UPDATE_RSS_FEED, DELETE_RSS_FEED, feedImage, useMyUser} from "../../data";
import {useDirtyCopy} from "../../dirty-copy";
import {Collapsible} from "../../components/card";
import {Button, Checkbox, InputRow, Select, Text, MultiColumn} from "../../components/input";
import {Badge} from "../../components/badge";
import {DayOfWeek} from "../../components/input/date-time";
import {Span, Span2, Span4} from "../../components/text";
import {useConfirmDeleteModal} from "../../modals";
import {useToastApi} from "../../api";
import {useMutationToast, useToast} from "../../toast";
import {Icon} from "../../components/images";
import {HoverTooltip} from "../../components/tooltip";
import {useSimpleGroup} from "../../data/group";

import styles from "./rss-feed.module.scss";

export interface RSSItemProps extends Component {
	feed: RssFeed;
}

export const RSSItem = ({feed}: RSSItemProps): ReactElement => {
	const {post} = useToastApi();
	const {groups} = useSimpleGroup();
	const user = useMyUser();
	const toast = useToast();
	const [updateRssFeed, {loading}] = useMutationToast(UPDATE_RSS_FEED);
	const [deleteRssFeed, {loading: deleting}] = useMutationToast(DELETE_RSS_FEED);

	const handleDelete = useCallback(close => deleteRssFeed({variables: {id: feed.id}}).then(close), [
		deleteRssFeed,
		feed.id,
	]);

	const {open} = useConfirmDeleteModal({what: "this feed", onDelete: handleDelete, deleting});
	const {val, dirty, discard, changes, inputFunc} = useDirtyCopy(feed);

	const handleRefresh = () => {
		if (!user) return;
		post("refresh feed", `/orgs/${user.org.id}/feeds/${feed.id}/process`, undefined, () => {
			toast({
				color: "green",
				text: "It'll be a few moments while we parse this feed. Check back soon.",
				icon: "rss-feed",
			});
		});
	};

	const handleSave = () =>
		updateRssFeed({variables: {id: feed.id, changes}}).then(({data}) => {
			if (data) discard();
		});

	const header = (
		<>
			<img className={styles.rssIcon} src={feedImage(feed)} alt="Feed Image" />
			<div className={styles.rssData}>
				<Span2>{feed.title}</Span2>
				<Span4 color={feed.groups.length ? "grey" : "blue"}>
					{feed.groups.length
						? feed.groups.map(id => groups.find(g => g.value === id)?.label).join(", ")
						: "No Auto-Collection Groups assigned"}
				</Span4>
			</div>
			{feed.status === "stopped" ? (
				<HoverTooltip text="Feed content is out of date or has no content available.">
					<Icon icon="warning" />
				</HoverTooltip>
			) : (
				feed.status === "error" && (
					<HoverTooltip text="There was an error parsing this feed.  Please check that the URL is valid.">
						<Icon icon="warning" color="pink" />
					</HoverTooltip>
				)
			)}
			{feed.autoFeed && (
				<>
					<Badge text="Auto Send" color="blue" />
					<DayOfWeek
						label="Weekly Send Days"
						value={feed.dayOfWeek}
						className={styles.dotw}
						size={20}
						multi
					/>
				</>
			)}
		</>
	);

	return (
		<Collapsible
			header={header}
			icons={[{icon: "refresh", onClick: handleRefresh}]}
			headerClassName={styles.itemHeader}
		>
			<MultiColumn>
				<Text label="Feed Name" {...inputFunc("title")} placeholder={feed.title} />
				<Checkbox
					{...inputFunc("autoFeed")}
					label={
						<>
							Automatically create and send Collections using content from this feed.
							<br />
							<Span color="grey">
								Collections will be sent from the primary admin&apos;s account to selected groups at scheduled
								times.
							</Span>
						</>
					}
				/>
				<Text label="Feed URL" {...inputFunc("url")} placeholder={feed.url} />
				{val.autoFeed ? (
					<Select label="Groups" options={groups} multi pills {...inputFunc("groups")} />
				) : (
					<div />
				)}
				<Checkbox
					label={
						<>
							Show in Industry News feed?
							<br />
							<Span color="grey">Display in relevant industry feeds.</Span>
						</>
					}
					{...inputFunc("showIndustryNews")}
				/>
				{val.autoFeed ? (
					<DayOfWeek label="Select Weekly Send Days" multi {...inputFunc("dayOfWeek")} />
				) : (
					<div />
				)}
			</MultiColumn>
			<InputRow position="between">
				<Button value="Delete Feed" invert color="pink" icon="delete" onClick={open} border={false} />
				<Button value="Save" disabled={!dirty} onClick={handleSave} loading={loading} />
			</InputRow>
		</Collapsible>
	);
};
