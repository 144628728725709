import {FC, ReactElement, useCallback, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {Link, useNavigate} from "react-router-dom";
import classnames from "classnames";
import {useLazyQuery, useQuery} from "@apollo/client";

import {CollapsibleCard} from "../../components/card";
import {Span, Span1, Span2, Span4, P, Text as TextComponent} from "../../components/text";
import {
	Category,
	AnalyticsCollection as AnalyticsCollectionType,
	GET_ANALYTICS_COLLECTIONS,
	GET_COLLECTIONS_EXPORT,
	GET_COLLECTION_STATS,
	RESEND_COLLECTION,
	transformAnalyticsCollection,
	Post,
	useUserList,
	useCategories,
	GET_COLLECTION,
	GET_COLLECTIONS_ANALYTICS_COUNT,
	loadCollection,
} from "../../data";
import {Button, InputRow, Select, Separator, SmallButton, Text} from "../../components/input";
import {AnalyticsCards} from "./card";
import {
	DateIntervalPicker,
	DateIntervalType,
	useCustomRange,
} from "../../components/input/date-time/date-interval-picker";
import {Icon} from "../../components/images";
import {usePageScroll} from "../../layout";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {useMutationToast, useToast} from "../../toast";
import {useModal} from "../../modals";
import {UserAvatar, UserSpan} from "../../components/user-avatar";
import {Tab} from "../../components/tabs/tab";
import {Loading} from "../../components/loading";
import {useDebounce} from "../../debounce";
import {downloadCsv} from "../../utils/csv";
import {stripHtmlTags} from "../../utils/text";
import {nFormatter} from "../../format";
import {usePostSharesModal} from "../collections/post-shares-modal";
import {useSimpleGroup} from "../../data/group";

import styles from "./collections.module.scss";

const getEmv = collection =>
	new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	}).format(collection.postsStats?.emv ?? 0);

const CollectionHeader: FC<{collection: AnalyticsCollectionType}> = ({collection}) => {
	const {groups} = useSimpleGroup();
	const toast = useToast();
	const [resendCollection, {loading: loadingResend}] = useMutationToast(RESEND_COLLECTION);
	const navigate = useNavigate();
	const [getCollectionStats, {data, loading}] = useLazyQuery(GET_COLLECTION_STATS);
	const [getCollection, {data: collectionData, loading: collectionLoading}] = useLazyQuery(GET_COLLECTION);
	const [selectedUserTypes, setSelectedUserTypes] = useState<"opened" | "unopened">("opened");
	const openedPercentage = useMemo(
		() =>
			Math.round(
				(data?.collectionStats.emailedUserIds.length * 100) /
					(data?.collectionStats.emailedUserIds.length + data?.collectionStats.unopenedUserIds.length)
			),
		[data?.collectionStats]
	);
	const unopenedPercentage = useMemo(
		() =>
			Math.round(
				(data?.collectionStats.unopenedUserIds.length * 100) /
					(data?.collectionStats.emailedUserIds.length + data?.collectionStats.unopenedUserIds.length)
			),
		[data?.collectionStats]
	);
	const handleOpenStats = () => getCollectionStats({variables: {collectionId: collection.id}}).then(open);
	const {open, close} = useModal(() => {
		const handleResend = () =>
			resendCollection({variables: {id: collection.id}}).then(() => {
				close();
				toast({
					color: "green",
					text: "Collection resent successfully",
				});
			});
		const userList = (list: number[]) => (
			<div className={styles.userList}>
				{list.map(userId => (
					<div key={userId}>
						<UserAvatar key={userId} className={styles.userItem} userId={userId} size="extraSmall" name />
						<Separator horizontal className={styles.separator} />
					</div>
				))}
			</div>
		);

		const header = <h3>{collection.title}</h3>;
		const body = !loading ? (
			<>
				<div className={styles.statsModal}>
					<div className={styles.tabs}>
						<Tab
							onClick={() => setSelectedUserTypes("opened")}
							active={selectedUserTypes === "opened"}
							title={"Opened" + (openedPercentage ? ` (${openedPercentage}%)` : " (0%)")}
						/>
						<Tab
							onClick={() => setSelectedUserTypes("unopened")}
							active={selectedUserTypes === "unopened"}
							title={"Unopened" + (unopenedPercentage ? ` (${unopenedPercentage}%)` : " (0%)")}
						/>
					</div>

					{selectedUserTypes === "opened" && userList(data?.collectionStats.emailedUserIds ?? [])}
					{selectedUserTypes === "unopened" && userList(data?.collectionStats.unopenedUserIds ?? [])}
				</div>
				<InputRow position="center">
					<Button
						value="Resend to Unopened"
						disabled={loading || data?.collectionStats.unopenedUserIds.length === 0}
						onClick={handleResend}
						loading={loadingResend}
					/>
				</InputRow>
			</>
		) : loading ? (
			<Loading position="center" />
		) : (
			<></>
		);

		return {
			header,
			body,
		};
	}, [
		collection.title,
		collection.id,
		loading,
		selectedUserTypes,
		data?.collectionStats.emailedUserIds,
		data?.collectionStats.unopenedUserIds,
		loadingResend,
		resendCollection,
		toast,
		openedPercentage,
		unopenedPercentage,
	]);

	const {open: openPostSharesModal} = usePostSharesModal({
		posts: collectionData?.collection?.posts ?? [],
		loading: collectionLoading,
	});
	const onOpenPostSharesModalClick = useCallback(() => {
		getCollection({variables: {id: collection.id}}).catch(() => null);
		openPostSharesModal();
	}, [getCollection, openPostSharesModal, collection.id]);

	const collectionEmv = useMemo(() => getEmv(collection), [collection]);

	return (
		<div className={styles.collectionHeader}>
			<div className={styles.info}>
				{collection.postsStats?.postsCount > 0 && (
					<div className={styles.collectionHeaderImages}>
						{collection.postsStats?.postImage ? (
							<img className={styles.image} key={collection.id} src={collection.postsStats?.postImage} />
						) : collection.postsStats?.postVideo ? (
							<video className={styles.video} controls muted>
								<source src={collection.postsStats?.postVideo} />
								<track kind="captions" srcLang="en" label="english_captions" />
							</video>
						) : (
							<Span1 className={styles.text}>{collection.postsStats?.postTitle}</Span1>
						)}
					</div>
				)}

				<div className={styles.collectionText}>
					<div className={styles.title}>
						<Span2 bold trim={1} className={styles.titleText}>
							{collection.title}
						</Span2>
						<SmallButton
							icon="open"
							onClick={() =>
								navigate(`/collections/${collection.id}`, {state: {from: "/analytics/collections"}})
							}
							value="Open"
							border={false}
							invert
						/>
					</div>
					<Span4>
						{collection.postsStats?.postsCount} {collection.postsStats?.postsCount === 1 ? "Post" : "Posts"}
						{" • "}
						Delivery Time: {collection.sent?.formatAs("numDate")}
					</Span4>
					<Span4>
						{groups
							.filter(g => collection.to.groupIds.includes(g.value))
							.map(g => g.label)
							.join(", ")}
					</Span4>
					<UserSpan size={4} color="grey" userId={collection.owner} before="Created by: " />
				</div>
			</div>

			<div className={classnames(styles.analytics, styles.applyBorder)}>
				<div className={classnames(styles.row, styles.clickable)} onClick={onOpenPostSharesModalClick}>
					<Span1 bold>{collection.postsStats?.usersCount}</Span1>
					<Span color="grey">Users</Span>
				</div>

				<div className={classnames(styles.row, styles.clickable)} onClick={onOpenPostSharesModalClick}>
					<Span1 bold>{collection.postsStats?.sharesCount}</Span1>
					<Span color="grey">Shares</Span>
				</div>

				<div className={styles.row}>
					<Span1 bold>{collectionEmv}</Span1>
					<div className={styles.emvContainer}>
						<Span color="blue">EMV</Span>
						<Icon
							color="blue"
							icon="information"
							onClick={() =>
								window.open(
									"https://cvssupport.wpenginepowered.com/article/how-does-earned-media-value-work",
									"_blank"
								)
							}
						/>
					</div>
				</div>

				<div className={styles.row}>
					<SmallButton
						onClick={handleOpenStats}
						value="View open rate"
						loading={loading}
						border={false}
						invert
					/>
				</div>
			</div>
		</div>
	);
};

const CollectionPost: FC<{post: Post}> = ({post}) => {
	const {open} = usePostSharesModal({posts: [post]});
	const general = post.opengraphs?.general ?? {};

	return (
		<div className={styles.collectionHeader}>
			<div className={classnames(styles.info, styles.postInfo)}>
				<div className={classnames(styles.collectionHeaderImages, styles.post)}>
					{general.video ? (
						<video className={styles.video} controls muted>
							<source src={general.video} />
							<track kind="captions" srcLang="en" label="english_captions" />
						</video>
					) : general.image ? (
						<img className={styles.image} src={general.image} />
					) : (
						<Span1 className={styles.text}>{general.title}</Span1>
					)}
				</div>
				<div className={styles.collectionText}>
					{post.url ? (
						<Link to={post.url} target="_blank">
							<Span2>{general.title}</Span2>
						</Link>
					) : (
						<Span2 color="grey" className={styles.titleText}>
							{general.title}
						</Span2>
					)}
				</div>
			</div>
			<div className={styles.analytics}>
				<div className={classnames(styles.row, styles.clickable)} onClick={open}>
					<Span1 bold>
						{post.shares?.filter(
							(share, index) =>
								share.successCount > 0 && post.shares?.findIndex(sh => sh.userId === share.userId) === index
						)?.length ?? 0}
					</Span1>
					<Span color="grey">Users</Span>
				</div>

				<div className={classnames(styles.row, styles.clickable)} onClick={open}>
					<Span1 bold>{post.shares?.reduce((ac, cur) => ac + cur.successCount, 0) ?? 0}</Span1>
					<Span color="grey">Shares</Span>
				</div>

				<div className={styles.row}>
					<Span1 bold>
						{new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(post.shares?.reduce((ac, cur) => ac + cur?.mediaValueSum, 0) ?? 0)}
					</Span1>
					<div className={styles.emvContainer}>
						<Span color="blue">EMV</Span>
						<Icon
							color="blue"
							icon="information"
							onClick={() =>
								window.open(
									"https://cvssupport.wpenginepowered.com/article/how-does-earned-media-value-work",
									"_blank"
								)
							}
						/>
					</div>
				</div>
				<div className={styles.row}>
					{post.expiresAt &&
						(dayjs().isSameOrAfter(post.expiresAt) ? (
							<Span bold color="pink">
								Expired
							</Span>
						) : (
							<div>
								<Span bold color="pink">
									Expires:
								</Span>{" "}
								<Span color="grey">{post.expiresAt.formatAs("numDate")}</Span>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

const CollectionPosts: FC<{collectionId: number}> = ({collectionId}) => {
	const categories = useCategories({});
	const {data, loading} = useQuery(GET_COLLECTION, {variables: {id: collectionId}});
	const collection = useMemo(() => (data?.collection ? loadCollection(data.collection) : null), [
		data?.collection,
	]);
	const postCategories: Category[] = useMemo(
		() =>
			collection?.categories?.length
				? (collection.posts
						.map(
							post =>
								categories.find(cat => cat.id === post.categoryId) ?? categories.find(cat => cat.default)
						)
						.filter((cat, index, arr) => cat && arr.findIndex(c => c?.id === cat.id) === index) as Category[])
				: [],
		[categories, collection?.posts, collection?.categories]
	);
	return (
		<div className={styles.postsContainer}>
			{loading ? (
				<Loading position="center" />
			) : postCategories?.length > 0 ? (
				postCategories.map(category => (
					<div key={category.id} className={styles.category}>
						<Span1 bold>{category.name}</Span1>
						{collection?.posts
							.filter(post => post.categoryId === category.id)
							.map(post => (
								<CollectionPost key={post.id} post={post} />
							))}
					</div>
				))
			) : (
				collection?.posts?.map(post => <CollectionPost key={post.id} post={post} />)
			)}
		</div>
	);
};
const CollectionItem: FC<{collection: AnalyticsCollectionType}> = ({collection}) => (
	<CollapsibleCard
		showInitial={false}
		key={collection.id}
		disabled={!collection.postsStats?.postsCount}
		header={<CollectionHeader collection={collection} />}
	>
		<CollectionPosts collectionId={collection.id} />
	</CollapsibleCard>
);

const CollectionStats: FC<{
	search: string;
	groups: number[];
	creator: number;
	start: Dayjs;
	end: Dayjs;
}> = ({search, groups, creator, start, end}) => {
	const debouncedSearch = useDebounce(search, 300);
	const variables = useMemo(
		() => ({
			search: debouncedSearch,
			groups: groups.length > 0 ? groups : undefined,
			start,
			end,
			owner: creator > 0 ? [creator] : undefined,
		}),
		[debouncedSearch, groups, start, creator, end]
	);

	const {data: cardsStatsData, loading: cardsStatsLoading, error, refetch} = useQuery(
		GET_COLLECTIONS_ANALYTICS_COUNT,
		{
			variables,
			notifyOnNetworkStatusChange: true,
		}
	);

	const cards = useMemo(() => {
		const values = cardsStatsData?.collectionsAnalyticsCount ?? {};

		return [
			{
				value: values.collectionsCount || 0,
				label: "Collections",
				lastValue: 0,
				lastLabel: "",
			},
			{
				value: values.postsCount || 0,
				label: "Posts",
				lastValue: 0,
				lastLabel: "",
			},
			{
				value: values.usersCount || 0,
				label: "Users",
				lastValue: 0,
				lastLabel: "",
			},
			{
				value: values.sharesCount || 0,
				label: "Shares",
				lastValue: 0,
				lastLabel: "",
			},
			{
				value: values.emvValue ? `$${nFormatter(Math.round(values.emvValue), 1)}` : "$0",
				label: "EMV",
				lastValue: 0,
				lastLabel: "",
			},
		];
	}, [cardsStatsData]);

	return (
		<>
			{error ? (
				<TextComponent type="p">
					There was an error loading the data, please{" "}
					<TextComponent
						type="span"
						color="blue"
						onClick={() => {
							refetch();
						}}
					>
						{" "}
						try again
					</TextComponent>
				</TextComponent>
			) : (
				<AnalyticsCards cards={cards} showComparison={false} loading={cardsStatsLoading} />
			)}
		</>
	);
};

const renderItem = (collection: AnalyticsCollectionType) => (
	<CollectionItem key={collection.id} collection={collection} />
);

const ExportButton: FC<{
	search: string;
	groups: number[];
	creator: number;
	start: Dayjs;
	end: Dayjs;
}> = ({search, groups, creator, start, end}) => {
	const debouncedSearch = useDebounce(search, 300);
	const variables = useMemo(
		() => ({
			search: debouncedSearch,
			groups: groups.length > 0 ? groups : undefined,
			start,
			end,
			owner: creator > 0 ? [creator] : undefined,
			sort: "SENT",
			status: ["SENT"],
			limit: 20,
		}),
		[debouncedSearch, groups, start, creator, end]
	);
	const [getCollectionsExport, {loading: exportLoading}] = useLazyQuery(GET_COLLECTIONS_EXPORT, {
		fetchPolicy: "no-cache", // Add this line to disable caching
		onCompleted: data => {
			const columns = [
				"Post ID",
				"Category",
				"Collection ID",
				"Collection Title",
				"Sent",
				"Collection Message",
				"Url",
				"Post Title",
				"Post Description ",
				"Post Comment",
				"Post Image",
				"Post Video",
				"Shares",
				"EMV",
				"Clicks",
			];
			const csvData = data.exportCollections.map(post => [
				post.id,
				post.category,
				post.collection.id,
				post.collection.title,
				post.collection.sent,
				stripHtmlTags(post.collection.message ?? ""),
				post.url,
				post.openGraph.title,
				post.openGraph.description,
				post.openGraph?.comment,
				post.openGraph.image,
				post.openGraph.video,
				post.success_count,
				getEmv({postsStats: {emv: post.emv}}),
				post.click_count,
			]);
			downloadCsv(csvData, columns, "analytics-collections.csv", true);
		},
	});
	const handleExport = useCallback(() => {
		getCollectionsExport({
			variables,
		});
	}, [getCollectionsExport, variables]);

	return <Button onClick={handleExport} loading={exportLoading} icon="export" value="Export" />;
};

export const PaginatedList: FC<{
	search: string;
	groups: number[];
	creator: number;
	start: Dayjs;
	end: Dayjs;
}> = ({search, groups, creator, start, end}) => {
	const navigate = useNavigate();
	const handleNavigate = useCallback(() => navigate("/collections"), [navigate]);
	const debouncedSearch = useDebounce(search, 300);
	const variables = useMemo(
		() => ({
			search: debouncedSearch,
			groups: groups.length > 0 ? groups : undefined,
			start,
			end,
			owner: creator > 0 ? [creator] : undefined,
			sort: "SENT",
			status: ["SENT"],
			limit: 20,
		}),
		[debouncedSearch, groups, start, creator, end]
	);
	const Empty = useMemo(
		() => (
			<div className={styles.emptyContainer}>
				<P>You don’t have any queues to review…</P>
				<P>Get started on your first queue below:</P>
				<Button value="Create Collection" invert border={false} onClick={handleNavigate} />
			</div>
		),
		[handleNavigate]
	);

	const {handleScroll, render} = usePaginatedQuery<AnalyticsCollectionType>(GET_ANALYTICS_COLLECTIONS, {
		loadingProps,
		variables,
		renderItem,
		inflateItem: transformAnalyticsCollection,
		empty: Empty,
	});
	usePageScroll(handleScroll);

	return render;
};
export const Collection = (): ReactElement => {
	const [search, setSearch] = useState("");
	const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
	const [selectedUser, setSelectedUser] = useState(0);
	const [customRange, setCustomRange] = useState<DateIntervalType>([dayjs().subtract(1, "month"), dayjs()]);
	const {groups} = useSimpleGroup();
	const {users} = useUserList();
	const dateRange = useCustomRange(customRange || "Max", dayjs().subtract(1, "month"));
	const {start = dayjs(), end = dayjs()} = dateRange || {};

	const handleSetSelectedGroups = (groups: number[]) => {
		if (groups.length > 0) {
			setSelectedUser(0);
		}
		setSelectedGroups(groups);
	};

	const handleSetSelectedUser = (user: number) => {
		if (user > 0) {
			setSelectedGroups([]);
		}

		setSelectedUser(user);
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h3>Collections</h3>
				<InputRow position="right" className={classnames(styles.headerFilters, styles.headerRow)}>
					<Text value={search} placeholder="Search" icon="search" onChange={setSearch} />
					<Select
						placeholder="All Groups"
						placeholderColor="black"
						multi
						pills
						options={groups}
						onChange={handleSetSelectedGroups}
						value={selectedGroups}
					/>
					<Select
						placeholder="All Users"
						options={[
							{
								label: "All Users",
								value: 0,
							},
							...users,
						]}
						onChange={handleSetSelectedUser}
						value={selectedUser}
					/>
					<DateIntervalPicker
						min={dayjs().add(-1, "year").startOf("year").startOf("day")}
						max={dayjs().add(2, "day").startOf("day")}
						showClear={true}
						value={customRange}
						onChange={setCustomRange}
					/>
					<ExportButton
						search={search}
						start={start}
						end={end}
						groups={selectedGroups}
						creator={selectedUser}
					/>
				</InputRow>
			</div>
			<CollectionStats
				search={search}
				start={start}
				end={end}
				groups={selectedGroups}
				creator={selectedUser}
			/>
			<PaginatedList search={search} groups={selectedGroups} creator={selectedUser} start={start} end={end} />
		</div>
	);
};
