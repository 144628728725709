import {createContext, ReactElement, useCallback, useState} from "react";
import {useLocalStorage, useSearchParam} from "react-use";
import {useLocation, useNavigate} from "react-router-dom";

import {PostModal} from "../containers/builder/posts/post-modal";
import {useModal} from "../modals/new";

export interface PostProps {
	postId?: number;
	postType?: "personal" | "company";
	url?: string;
	fromPostId?: number;
	rss?: boolean;
}

interface ModalContext {
	open: (vals: PostProps) => void;
	isOpen: boolean;
	close: () => void;
}

export const PostModalContext = createContext<ModalContext>({
	open: () => undefined,
	isOpen: false,
	close: () => undefined,
});

const PostModalProvider = ({children}: {children: ReactElement}): ReactElement => {
	const post = useSearchParam("post");
	const [postId, setPostId] = useState<number | undefined>(post && post !== "new" ? Number(post) : undefined);
	const [postType, setPostType] = useState<"personal" | "company" | undefined>("personal");
	const [url, setUrl] = useState<string | undefined>();
	const [fromPostId, setFromPostId] = useState<number | undefined>();
	const [rss, setRss] = useState<boolean | undefined>();
	const [newPostCookie, setNewPostCookie] = useLocalStorage<"personal" | "company" | undefined>(
		`new-post-type`,
		"personal"
	);
	const location = useLocation();
	const navigate = useNavigate();

	const onClose = useCallback(() => {
		const currentParams = new URLSearchParams(location.search);
		currentParams.delete("post");
		navigate(location.pathname + "?" + currentParams.toString());
	}, [location.pathname, location.search, navigate]);

	const {modal, open, close} = useModal({
		defaultOpen: !!post,
		onClose,
	});

	const openPostModal = useCallback(
		({postId, postType, url, fromPostId, rss}: PostProps) => {
			setPostId(postId);
			setPostType(postType ?? "personal");
			setNewPostCookie(postType ?? "personal");
			setUrl(url);
			setFromPostId(fromPostId);
			setRss(rss);
			const postRoute = postId ? `${postId}` : "new";
			const currentParams = new URLSearchParams(location.search);
			currentParams.set("post", postRoute);
			navigate(location.pathname + "?" + currentParams.toString());
			open();
		},
		[open, setNewPostCookie, location.search, location.pathname, navigate]
	);

	return (
		<PostModalContext.Provider
			value={{
				open: openPostModal,
				isOpen: modal.open,
				close: close,
			}}
		>
			{children}
			<PostModal
				modal={modal}
				postId={postId}
				postType={newPostCookie ?? postType}
				url={url}
				fromPostId={fromPostId}
				rss={rss}
			/>
		</PostModalContext.Provider>
	);
};

export default PostModalProvider;
