export * from "./editor";
export * from "./media";
export * from "./share-preview";
export * from "./social-score";

export const networkCaptionMaxLength = {
	twitter: 280,
	tiktok: 255,
	linkedin: 3000,
	facebook: 3000,
	instagram: 2000,
	general: 3000,
};

export const networkTitleMaxLength = {
	linkedin: 165,
	twitter: 94,
	facebook: 100,
	instagram: 100,
	tiktok: 100,
	general: undefined,
};

export const networkDescriptionMaxLength = {
	linkedin: undefined,
	twitter: undefined,
	facebook: 300,
	instagram: undefined,
	tiktok: undefined,
	general: 300,
};

export const networkVideoMinWidth = {
	tiktok: 360,
};

export const networkVideoMinHeight = {
	tiktok: 360,
};

export const networkVideoMaxWidth = {
	tiktok: 4096,
};

export const networkVideoMaxHeight = {
	tiktok: 4096,
};

export const networkImageMinWidth = {
	tiktok: 360,
};

export const networkImageMinHeight = {
	tiktok: 360,
};

export const networkImageMaxWidth = {
	tiktok: 1080,
};

export const networkImageMaxHeight = {
	tiktok: 1080,
};

export const networkImageFormats = {
	tiktok: ["jpeg", "webp"],
};
