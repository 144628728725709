import {Social} from "../../../../components/social";
import {accountNames, Service} from "../../../../data";
import {Button, InputRow} from "../../../../components/input";
import {Icon} from "../../../../components/images";
import {P2, Span3} from "../../../../components/text";
import {NewModal as Modal} from "../../../../modals";
import {UserShareEventsResult} from "../../../../data/share";
import {ModalData} from "../../../../modals/new";

import styles from "./styles.module.scss";

interface SharingResultsProps {
	modal: ModalData;
	results?: UserShareEventsResult;
	onClose: () => void;
}

export const SharingResults = ({modal, results, onClose}: SharingResultsProps) => {
	const eventsResult = Object.entries(results ?? []).filter(([key, post]) => key !== "__typename" && post);
	const allPosted = eventsResult.every(([, {success}]) => success);

	if (!results) return null;

	return (
		<Modal className={styles.sharingResultsModal} modal={modal} onClose={onClose} size={"fit-content"}>
			<div className={styles.body}>
				<h2 className={styles.title}>
					{allPosted ? (
						<>
							<h2>{`You’ve done it!`}</h2>{" "}
							{`The share was posted successfully${eventsResult.length > 1 ? " to all the networks" : ""}.`}
						</>
					) : (
						`Sorry, we’re unable to post to${eventsResult.length > 1 ? " all of" : ""} your networks.`
					)}
				</h2>
				<div className={styles.content}>
					{results?.tiktok?.success && (
						<Span3 className={styles.postingInfo}>
							It may take a few minutes for the content to be processed and appear on your profile.
						</Span3>
					)}
					<div className={styles.socialNetworkIcons}>
						{eventsResult.map(([key, post], i) => (
							<Social
								name={key as Service}
								active={post.success}
								size="large"
								key={i}
								onClick={post.success && post.url ? () => window.open(post.url, "_blank") : undefined}
							/>
						))}
					</div>
					<div className={styles.errors}>
						{!allPosted &&
							eventsResult
								.filter(([, {success}]) => !success)
								.map(([network, post], i) => (
									<InputRow key={i}>
										<Icon icon="information" color="pink" />
										&nbsp;
										<P2 color="pink">{`${accountNames[network]}: ${post.message}`}</P2>
									</InputRow>
								))}
					</div>
				</div>
				<InputRow position={"right"}>
					<Button value="Back to Posts" onClick={onClose} />
				</InputRow>
			</div>
		</Modal>
	);
};
