import {useCallback, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import dayjs from "dayjs";

import {Modal, ModalData} from "../../../modals/new";
import {clearTypename, GET_SHARE, loadShare, Share, useMyUser} from "../../../data";
import {PersonalPost} from "./personal-post";
import {useToast} from "../../../toast";
import {GET_RSS_FEED_POST} from "../../../data/rssFeed";
import {CompanyPost} from "./company-post";
import {Loading} from "../../../components/loading";

import styles from "./post-modal.module.scss";

interface PostProps {
	postId?: number;
	shareAction?: string;
	postType?: "personal" | "company";
	url?: string;
	fromPostId?: number;
	rss?: boolean;
}

export const PostModal = ({
	modal,
	postId,
	postType,
	url,
	fromPostId,
	rss,
	shareAction,
}: {modal: ModalData} & PostProps) => (
	<Modal modal={{...modal, maxWidth: "1200px", size: "fit-content"}} className={styles.postModal}>
		<div className={styles.postContainer}>
			<Post
				close={modal.close}
				postId={postId}
				postType={postType}
				url={url}
				fromPostId={fromPostId}
				rss={rss}
				shareAction={shareAction}
			/>
		</div>
	</Modal>
);

const clearOpengraphTypename = ogs => {
	if (!ogs) return {};
	const ogsCleared = clearTypename(ogs);
	return Object.keys(ogsCleared).reduce((acc, el) => {
		if (!ogsCleared[el]) return acc;
		const og = {...ogsCleared[el]};
		if (og.images) {
			og.images = og.images.map(el => clearTypename(el));
		}
		return {...acc, [el]: og};
	}, {});
};

const deleteOgIds = ogs => {
	const cpy = Object.keys(ogs).reduce((acc, el) => {
		const og = {...ogs[el]};
		delete og.id;
		acc[el] = og;
		return acc;
	}, {});

	return cpy;
};

const Post = ({
	postId,
	postType,
	url,
	fromPostId,
	rss,
	shareAction,
	close,
}: PostProps & {close: () => void}) => {
	const isShareAction = shareAction === "share";
	const id = postId ?? "new";
	const isNew = id === "new" || isShareAction;
	const toast = useToast();
	const [shareFromUrl, setShareFromUrl] = useState<string | undefined>(url);
	const me = useMyUser();

	const {data, loading} = useQuery(GET_SHARE, {
		variables: {id},
		fetchPolicy: "cache-first",
		skip: isNew,
		onError: error => toast({color: "red", text: error.message}),
	});
	const {data: parentPost, loading: loadingParentPost} = useQuery(rss ? GET_RSS_FEED_POST : GET_SHARE, {
		variables: {id: fromPostId},
		fetchPolicy: "network-only",
		skip: !fromPostId,
		onError: error => toast({color: "red", text: error.message}),
	});
	const parentPostUrl = useMemo(
		() => (parentPost ? (rss ? parentPost.rssFeedPost?.url : parentPost.share.url) : undefined),
		[parentPost, rss]
	);
	const parentPostOpengraph = useMemo(
		() =>
			parentPost ? (rss ? {general: parentPost.rssFeedPost?.item} : parentPost.share?.opengraphs) : undefined,
		[parentPost, rss]
	);

	const loadedShare = useMemo(
		() =>
			(data?.share && !isShareAction
				? loadShare(data.share)
				: {
						url: parentPostUrl,
						opengraphs: {
							general: {
								comment: "",
							},
							...deleteOgIds(clearOpengraphTypename(parentPostOpengraph)),
						},
						shareEvents: [],
				  }) as Share,
		[data?.share, parentPostOpengraph, parentPostUrl, isShareAction]
	);
	const isExpired = useMemo(() => data?.share?.expiresAt && dayjs(data.share.expiresAt).isBefore(dayjs()), [
		data?.share?.expiresAt,
	]);
	const personal = postType === "personal";
	const goBack = useCallback(() => close(), [close]);
	const isLoading = useMemo(() => loading || (fromPostId ? loadingParentPost : false), [
		loading,
		loadingParentPost,
		fromPostId,
	]);

	if (isLoading)
		return (
			<div className={styles.loadingPost}>
				<Loading position="center" />
			</div>
		);
	return (
		<>
			{personal ? (
				<PersonalPost
					askLoadUnsaved={!(url || rss || fromPostId || id)}
					share={loadedShare}
					shareFromUrl={shareFromUrl}
					setShareFromUrl={setShareFromUrl}
					onComplete={goBack}
					disabled={isExpired}
					disableContentEditing={
						me.role === "user" &&
						me.org.options?.preventPostEditing &&
						(data?.share?.fromCollection || parentPost?.share?.fromCollection)
					}
				/>
			) : (
				<CompanyPost
					askLoadUnsaved={!(url || rss || fromPostId || id)}
					share={loadedShare}
					shareFromUrl={shareFromUrl}
					setShareFromUrl={setShareFromUrl}
					onComplete={goBack}
					disabled={isExpired}
				/>
			)}
		</>
	);
};
