import {useEffect} from "react";
import {useFormikContext} from "formik";

import {FormValues} from "./types";

export const FormChangesListener = ({onChange}: {onChange?: (values: FormValues) => void}) => {
	const {values} = useFormikContext<FormValues>();

	useEffect(() => {
		onChange?.(values);
	}, [values, onChange]);

	return null;
};
