import React, {useCallback, useMemo} from "react";
import {useFormikContext} from "formik";

import {getPostEmv, useMyUser, Post, PostKeyword} from "../../../../data";
import {Button} from "../../../../components/input";
import {PostCollectionFormValues} from "./post";
import {useKeywordsModal} from "../../../../modals/collections/keywords";

import styles from "./styles.module.scss";

export const PostEmv = () => {
	const me = useMyUser();
	const {values, setFieldValue, submitForm} = useFormikContext<PostCollectionFormValues>();
	const {url, keywords} = values;
	const isEMVEnabled = me.org.options.emvQueueEnabled;
	const handleKeywordsUpdate = useCallback(
		(keywords: PostKeyword[]) => {
			setFieldValue("keywords", keywords);
			submitForm();
		},
		[setFieldValue, submitForm]
	);
	const selectedKeys = useMemo(() => keywords || [], [keywords]);
	const keywordsModal = useKeywordsModal({
		onConfirm: handleKeywordsUpdate,
		selectedKeys: selectedKeys,
	});

	const emvValue = useMemo(
		() =>
			new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",
			}).format(getPostEmv({url, keywords} as Post)),
		[url, keywords]
	);

	if (!url) return null;

	return (
		<div className={styles.emv}>
			<Button
				color="black"
				invert
				disabled={!isEMVEnabled}
				border={false}
				icon="information"
				value={`EMV: ${emvValue} Saved`}
				onClick={keywordsModal.open}
			/>
		</div>
	);
};
