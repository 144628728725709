import {Config} from "./index";

const baseUrl = process.env.REACT_APP_HOST;

export const local: Partial<Config> = baseUrl
	? {
			appUrl: `${process.env.REACT_APP_HOST}`,
			apiHost: `${process.env.REACT_APP_HOST}/api`,
			graphqlUrl: `${process.env.REACT_APP_HOST}/graphql`,
			linkedinUrl: `${process.env.REACT_APP_HOST}/api/auth/connect/linkedin2`,
			facebookUrl: `${process.env.REACT_APP_HOST}/api/auth/connect/facebook`,
			twitterUrl: `${process.env.REACT_APP_HOST}/api/auth/connect/twitter2`,
			tiktokUrl: `${process.env.REACT_APP_HOST}/api/auth/redirect/tiktok`,
	  }
	: {};
