import {Fragment, ReactElement, useState} from "react";
import {useNavigate} from "react-router";
import {useQuery} from "@apollo/client";

import {LOGIN, RESET_PASSWORD, SAML_LOGIN} from "../../data/auth";
import {Button, InputRow, Text, validEmail, required, SmallButton, Validate} from "../../components/input";
import {P2} from "../../components/text";
import {config} from "../../config";
import {useMutationToast, useToast} from "../../toast";
import {GET_ME} from "../../data/user";

import styles from "./login.module.scss";

type ShowState = "hide" | "other" | "sso" | "recover";
const showText: Record<ShowState, string> = {
	hide: "Login With:",
	other: "Login With:",
	sso: "Single Sign-on:",
	recover: "Recover Password:",
};

const LoginButton = props => <Button invert border large {...props} />;

export const Login = (): ReactElement => {
	const {data} = useQuery(GET_ME);
	const navigate = useNavigate();
	const toast = useToast();
	const [login, {loading}] = useMutationToast(LOGIN);
	const [samlLogin, {loading: samling}] = useMutationToast(SAML_LOGIN);
	const [resetPassword, {loading: resetting}] = useMutationToast(RESET_PASSWORD);
	const [show, setShow] = useState<ShowState>("hide");
	const [valid, setValid] = useState(true);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	if (data?.me) {
		navigate("/feed/hub", {replace: true});
	}

	const handleLogin = () =>
		login({variables: {email, password}, onCompleted: () => navigate("/feed/hub", {replace: true})});

	const handleSAML = () =>
		samlLogin({variables: {email}, onCompleted: ({samlLogin}) => (window.location.href = samlLogin)});

	const handlePasswordReset = () =>
		resetPassword({
			variables: {email},
			onCompleted: () =>
				toast({
					color: "blue",
					text:
						"If that e-mail address has an account associated with it, you should receive an e-mail with instructions for resetting your password.",
				}),
		});

	const SocialMediaButtons = (
		<>
			<LoginButton
				color="black"
				icon="twitter"
				onClick={() => (window.location.href = config.twitterUrl)}
				value="Login with X"
			/>
			<LoginButton
				icon="facebook"
				onClick={() => (window.location.href = config.facebookUrl)}
				value="Login with Facebook"
			/>
			{/*<LoginButton*/}
			{/*	color="black"*/}
			{/*	icon="tiktok"*/}
			{/*	onClick={() => (window.location.href = config.tiktokUrl)}*/}
			{/*	value="Login with TikTok"*/}
			{/*/>*/}
		</>
	);

	return (
		<div className={styles.container}>
			<div>
				<img src="/svgs/clearview-logo-full.svg" alt="ClearView Social" className="space" />

				<P2 bold>{showText[show]}</P2>
				<Validate setStatus={setValid}>
					{show === "hide" ? (
						<Fragment key="hide">
							<LoginButton
								icon="linkedin"
								onClick={() => (window.location.href = config.linkedinUrl)}
								value="Login with LinkedIn"
								autoFocus
							/>
							<LoginButton color="pink" icon="key" onClick={() => setShow("sso")} value="Login with SSO" />
						</Fragment>
					) : show === "sso" ? (
						<Fragment key="sso">
							<Text
								label="Email:"
								value={email}
								onChange={setEmail}
								validate={[required, validEmail]}
								autoFocus
							/>
							<LoginButton
								color="pink"
								icon="check"
								onClick={handleSAML}
								value="Log in"
								disabled={!valid}
								loading={samling}
							/>
						</Fragment>
					) : show === "other" ? (
						<Fragment key="other">
							<Text
								label="Email:"
								value={email}
								onChange={setEmail}
								validate={[required, validEmail]}
								autoFocus
							/>
							<Text
								type="password"
								label="Password:"
								value={password}
								onChange={setPassword}
								validate={[required]}
							/>
							<LoginButton
								color="pink"
								icon="check"
								onClick={handleLogin}
								value="Log in"
								disabled={!valid}
								loading={loading}
							/>
							<InputRow position="right">
								<SmallButton
									onClick={() => setShow("recover")}
									value="Forgot Password?"
									border={false}
									invert
								/>
							</InputRow>
							{SocialMediaButtons}
						</Fragment>
					) : (
						<Fragment key="recover">
							<Text
								label="Email:"
								value={email}
								onChange={setEmail}
								validate={[required, validEmail]}
								autoFocus
							/>
							<LoginButton
								color="pink"
								icon="refresh"
								onClick={handlePasswordReset}
								value="Reset Password"
								disabled={!valid}
								loading={resetting}
							/>
							{SocialMediaButtons}
						</Fragment>
					)}
				</Validate>
				<LoginButton
					color="black"
					icon="ellipsis"
					onClick={() => setShow(c => (c === "hide" ? "other" : "hide"))}
					value="Other Login Options"
				/>
			</div>
		</div>
	);
};
