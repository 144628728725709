import {useLocalStorage, useSearchParam} from "react-use";
import {useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import {useMyUser} from "../data";
import {useCanvaDesign} from "./use-canva-design";
import {useMutationToast} from "../toast";
import {UPDATE_IMAGE} from "../data/canva";

interface ReturnState {
	designId: string;
	opengraphNetwork: string;
	activeNetwork?: string;
	preview?: boolean;
	originPage: string;
	opengraphId: string;
	shuffledCommentId: string;
	imageDestination: string;
	newPostType: string;
	imageId: string;
}

const updateCacheOpenGraph = ({
	updatePost,
	opengraphNetwork,
	post,
	url,
	canvaId,
	imageId,
	imageDestination,
}) => {
	const postCpy = {...post};
	const og = postCpy.opengraphs[opengraphNetwork] ?? postCpy.opengraphs.general;

	if (imageDestination === "MULTI_IMAGE") {
		og.images = og.images.map(image => {
			if (image.url === imageId) {
				return {
					...image,
					url,
					canvaId,
				};
			}
			return image;
		});
	}
	if (imageDestination === "OPENGRAPH") {
		og.image = url;
		og.canvaId = canvaId;
	}
	updatePost(postCpy);
};

export default function useCanvaUpdate() {
	const state = useSearchParam("state");
	const me = useMyUser();
	const [hasError, setHasError] = useState(false);
	const decodedState = state ? JSON.parse(atob(state)) : null;

	const [returnState] = useLocalStorage<ReturnState>(`canva-return-state-${me.id}`);

	const {
		designId: returnStateDesignId,
		originPage,
		opengraphId,
		shuffledCommentId,
		preview,
		imageDestination,
		opengraphNetwork = "general",
		newPostType,
		imageId,
		activeNetwork,
	} = useMemo<ReturnState>(() => returnState ?? ({} as ReturnState), [returnState]);

	const idMap = useMemo(
		() => ({
			OPENGRAPH: opengraphId,
			SHUFFLED_IMAGE: shuffledCommentId,
			MULTI_IMAGE: imageId,
		}),
		[opengraphId, shuffledCommentId, imageId]
	);
	const designId = useMemo(() => decodedState?.design_id ?? returnStateDesignId, [
		decodedState,
		returnStateDesignId,
	]);
	const navigate = useNavigate();
	const [newPostCookie, setNewPost] = useLocalStorage(
		`new-post-${newPostType ?? "personal"}-${me.id}`,
		null,
		{
			raw: false,
			serializer: JSON.stringify,
			deserializer: JSON.parse,
		}
	);

	const [updateImage, {data, loading: loadingUpdateImage}] = useMutationToast(UPDATE_IMAGE);
	const onCompleted = useCallback(
		({url, id}) => {
			// case 1 - updating local storage
			if (["OPENGRAPH", "MULTI_IMAGE"].includes(imageDestination) && !opengraphId) {
				updateCacheOpenGraph({
					updatePost: setNewPost,
					opengraphNetwork,
					post: newPostCookie,
					url,
					canvaId: id,
					imageId,
					imageDestination,
				});

				navigate("", {
					state: {
						action: "share",
						type: newPostType,
						...(preview
							? {
									canvaImageUrl: url,
									preview: true,
							  }
							: {}),
					},
				});
				window.location.href = originPage;
			} else {
				// case 2 - updating data from database
				updateImage({
					variables: {
						id: idMap[imageDestination],
						imageUrl: url,
						...(opengraphId ? {opengraphId} : {}),
						designId: designId,
						imageDestination: imageDestination,
					},
					onError: () => setHasError(true),
				}).then(() => {
					navigate("", {
						state: {
							focusedOpengraphId: opengraphId,
							...(preview
								? {
										canvaImageUrl: url,
										preview: true,
								  }
								: {}),
						},
					});
					window.location.href = originPage;
				});
			}
		},
		[
			designId,
			idMap,
			imageDestination,
			newPostCookie,
			newPostType,
			opengraphId,
			opengraphNetwork,
			originPage,
			updateImage,
			preview,
			navigate,
			setNewPost,
			imageId,
		]
	);

	const {createDesignJob, loadingExport, loadingUpload, step, status} = useCanvaDesign({
		onError: () => setHasError(true),
		onCompleted,
	});
	const startUpdate = useCallback(() => {
		setHasError(false);
		if (!imageDestination) {
			setHasError(true);
			return;
		}
		if (imageDestination === "CANVA_LIBRARY") {
			navigate(originPage, {
				state: {
					canvaLibrary: true,
					...(opengraphId ? {focusedOpengraphId: opengraphId} : {}),
					...(activeNetwork ? {activeNetwork} : {}),
					...(newPostType ? {type: newPostType} : {}),
				},
			});
			window.location.href = originPage;
			return;
		}
		if (designId && imageDestination !== "CANVA_LIBRARY") {
			createDesignJob(designId);
		}
	}, [
		designId,
		createDesignJob,
		imageDestination,
		opengraphId,
		originPage,
		navigate,
		newPostType,
		activeNetwork,
	]);

	const finnishedDesignUpload = loadingUpdateImage || data;

	return {
		loadingExport,
		step,
		status,
		loadingUpload,
		finnishedDesignUpload,
		loadingUpdateImage,
		finnishedUpdate: !!data,
		imageDestination,
		startUpdate,
		hasError,
		backButtonUrl: originPage ?? "/",
	};
}
