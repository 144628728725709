import {ReactElement, useState, useCallback, useMemo} from "react";

import {
	Button,
	DropdownButton,
	InputHeader,
	Text,
	Group,
	GroupedDropdown,
	Validate,
	required,
} from "../../components/input";
import {EmptyList} from "../../components/empty-list";
import {CREATE_GROUP, GET_GROUPS, Group as GroupType} from "../../data";
import {GroupItem} from "./group";
import {Loading} from "../../components/loading";
import {NewModal as Modal, useNewModal as useModal} from "../../modals";
import {useMutationToast, useToast} from "../../toast";
import {usePaginatedQuery} from "../../paginated-query";
import {usePageScroll} from "../../layout";
import {SEND_EMAIL_CSV} from "../../data/user";
import {useDebounce} from "../../debounce";

import styles from "./group.module.scss";

const sortOptions = [
	{
		value: ["name", "ASC"],
		label: "A to Z Name",
		sort: (a: GroupType, b: GroupType) => a.name.localeCompare(b.name),
	},
	{
		value: ["name", "DESC"],
		label: "Z to A Name",
		sort: (a: GroupType, b: GroupType) => b.name.localeCompare(a.name),
	},
	{
		value: ["users", "ASC"],
		label: "Users Ascending",
		sort: (a: GroupType, b: GroupType) => a.users.length - b.users.length,
	},
	{
		// value: "usersDesc",
		value: ["users", "DESC"],

		label: "Users Descending",
		sort: (a: GroupType, b: GroupType) => b.users.length - a.users.length,
	},
	{
		value: ["vas", "ASC"],
		label: "Virtual Assistants Ascending",
		sort: (a: GroupType, b: GroupType) => a.vas - b.vas,
	},
	{
		value: ["vas", "DESC"],
		label: "Virtual Assistants Descending",
		sort: (a: GroupType, b: GroupType) => b.vas - a.vas,
	},
	{
		value: ["queues", "ASC"],
		label: "Collections Ascending",
		sort: (a: GroupType, b: GroupType) => a.queues - b.queues,
	},
	{
		value: ["queues", "DESC"],
		label: "Collections Descending",
		sort: (a: GroupType, b: GroupType) => b.queues - a.queues,
	},
] as const;

type FilterTypes =
	| "create_collections"
	| "approve_collections"
	| "add_content"
	| "send_all"
	| "manage_groups"
	| "set_primary_admin"
	| "allow_subscription";

const filterOptions: Group<FilterTypes, string | number>[] = [
	{
		label: "Create Collections",
		value: "create_collections",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Approve Collections",
		value: "approve_collections",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Add Content To Collections",
		value: "add_content",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Send Collections To Everyone",
		value: "send_all",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Manage Groups",
		value: "manage_groups",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Set Primary Admin",
		value: "set_primary_admin",
		options: [{value: "enabled", label: "Enabled"}],
	},
	{
		label: "Allow Subscription",
		value: "allow_subscription",
		options: [{value: "enabled", label: "Enabled"}],
	},
];

export const Groups = (): ReactElement => {
	const [createGroup, {loading: adding}] = useMutationToast(CREATE_GROUP);
	const [csvEmail, {loading: exporting}] = useMutationToast(SEND_EMAIL_CSV);
	const toast = useToast();
	const [valid, setValid] = useState(true);
	const [name, setName] = useState("");
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 300);
	const [sort, setSort] = useState<typeof sortOptions[number]["value"]>(sortOptions[0].value);
	const [filter, setFilter] = useState<Record<FilterTypes, (string | number)[]>>({
		create_collections: [],
		approve_collections: [],
		add_content: [],
		send_all: [],
		manage_groups: [],
		set_primary_admin: [],
		allow_subscription: [],
	});
	const {modal, open, close} = useModal({});
	const handleAdd = useCallback(
		() =>
			createGroup({variables: {name}}).then(() => {
				setName("");
				close();
			}),
		[close, createGroup, name]
	);

	const renderItem = useCallback(group => <GroupItem key={group.id} group={group} />, []);
	const loading = useMemo(() => <Loading position="center" />, []);
	const empty = useMemo(() => <EmptyList text={search ? "No matches found" : "No items"} />, [search]);

	const handleExport = useCallback(() => {
		csvEmail({variables: {type: "GROUPS"}}).then(() => {
			toast({
				color: "blue",
				text: "Exporting your results into a csv file. It should arrive in your inbox in a few minutes.",
			});
		});
	}, [csvEmail, toast]);
	const variables = useMemo(
		() => ({
			search: debouncedSearch,
			sort: {
				field: sort[0].toUpperCase(),
				direction: sort[1],
			},
			filter: Object.keys(filter).reduce<string[]>((acc, key) => {
				if (filter[key].length !== 0) acc.push(key.toUpperCase());
				return acc;
			}, []),
		}),
		[debouncedSearch, sort, filter]
	);

	const {render, handleScroll} = usePaginatedQuery(GET_GROUPS, {
		variables,
		renderItem,
		loading,
		empty,
	});
	usePageScroll(handleScroll);

	return (
		<div>
			<InputHeader>
				<>
					<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />

					<GroupedDropdown groups={filterOptions} onChange={setFilter} value={filter} icon="filter" invert />

					<DropdownButton
						options={sortOptions.map(o => ({
							label: o.label,
							onClick: () => setSort(o.value),
							selected: sort === o.value,
						}))}
						icon="sort"
						invert
					/>
				</>
				<>
					<Button onClick={open} value="Create Group" icon="create-group" color="pink" />
					<Button onClick={handleExport} loading={exporting} value="Export" icon="export" />
				</>
			</InputHeader>
			<div className={styles.list}>{render}</div>

			<Modal title="Add New Group" modal={modal}>
				<Validate setStatus={setValid}>
					<Text label="Group Name" onChange={setName} value={name} validate={required} autoFocus />
					<Button large value="Add New Group" disabled={!valid} loading={adding} onClick={handleAdd} />
				</Validate>
			</Modal>
		</div>
	);
};
