import {useEffect, useState, useCallback, useMemo} from "react";
import {useLazyQuery} from "@apollo/client";
import classnames from "classnames";

import {Loading} from "../../components/loading";
import {
	Button,
	Checkbox,
	InputRow,
	Select,
	Separator,
	SmallButton,
	Text,
	required,
} from "../../components/input";
import {REFRESH_CODE, SELF_ENROLLMENT, SelfEnrollment, UPDATE_SELF_ENROLLMENT} from "../../data";
import {useDirtyCopy} from "../../dirty-copy";
import {useMutationToast} from "../../toast";
import {P} from "../../components/text";
import {NewModal as Modal} from "../../modals";
import {ModalData} from "../../modals/new";
import config from "../../config";
import {useSimpleGroup} from "../../data/group";

import styles from "./user.module.scss";

export const EnrollModal = ({modal}: {modal: ModalData}) => {
	const [load, {data, loading}] = useLazyQuery(SELF_ENROLLMENT);
	const [updateEnrollment, {loading: updating}] = useMutationToast(UPDATE_SELF_ENROLLMENT);
	const [refreshCode, {loading: refreshing}] = useMutationToast(REFRESH_CODE);
	const {groups: groupList} = useSimpleGroup();
	const dirtyCopy = useDirtyCopy<SelfEnrollment>(data?.selfEnrollment ?? {domains: [], code: ""});
	const [optOut, setOptOut] = useState(false);
	const [showCopyIcon, setShowCopyIcon] = useState(false);

	const {changes, discard, update, val} = dirtyCopy;
	useEffect(() => {
		if (modal.open) load().then(({data: {selfEnrollment}}) => setOptOut(!selfEnrollment.domains.length));
		if (!modal.open) discard();
	}, [modal.open, load, discard]);
	const handleOptOut = () => {
		if (!optOut) return setOptOut(true);
		setOptOut(false);
		if (!val.domains.length) update({domains: [{domain: "", group: undefined}]});
	};
	const canSave = optOut || changes?.domains?.every(d => d.domain);

	const domains = useMemo(
		() => (optOut ? [] : changes?.domains?.map(d => ({domain: d.domain, group: d.group}))),
		[optOut, changes]
	);

	const handleSave = () => {
		if (!canSave) return;
		updateEnrollment({variables: {domains}}).then(() => modal.close());
	};
	const link = `${config.appUrl}/enrollment/${val.code}`;

	const handleRefresh = useCallback(async () => {
		if (canSave) {
			await updateEnrollment({variables: {domains}});
		}

		refreshCode();
	}, [refreshCode, updateEnrollment, canSave, domains]);

	const handleCopyLink = useCallback(() => {
		navigator.clipboard.writeText(link);
		setShowCopyIcon(true);
	}, [link, setShowCopyIcon]);

	useEffect(() => {
		if (!showCopyIcon) return;
		const timer = setTimeout(() => {
			setShowCopyIcon(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCopyIcon]);

	return (
		<Modal
			title="Self-Enrollment Link"
			modal={modal}
			footer={
				<InputRow position="between">
					<Checkbox
						label="Opt out of self-enrollment"
						value={optOut}
						onChange={handleOptOut}
						disabled={loading}
					/>
					<Button value="Save" onClick={handleSave} loading={updating} disabled={loading || !canSave} />
				</InputRow>
			}
		>
			<>
				{loading ? (
					<Loading position="center" />
				) : (
					<>
						{optOut && <P color="grey">Self-enrollment link is disabled</P>}
						{!optOut && (
							<>
								<div className={classnames(styles.domains, "space")}>
									{val.domains.map((d, i) => (
										<div key={i}>
											<Text
												label="Enrollment Domain"
												placeholder="clearviewsocial.com"
												value={d.domain}
												onChange={v =>
													update((base, c) => {
														const domains = [...(c?.domains ?? base.domains)];
														domains[i] = {...domains[i], domain: v};
														return {domains};
													})
												}
												validate={required}
											/>
											<Select
												options={groupList}
												label="Default Enrollment Group Assignment"
												placeholder="Select group"
												value={d.group}
												onChange={v =>
													update((base, c) => {
														const domains = [...(c?.domains ?? base.domains)];
														domains[i] = {...domains[i], group: v};
														return {domains};
													})
												}
												disabled={updating}
											/>
											<InputRow position="right">
												<SmallButton
													icon="delete"
													color="black"
													onClick={() =>
														update((base, c) => {
															const domains = [...(c?.domains ?? base.domains)];
															domains.splice(i, 1);
															return {domains};
														})
													}
													border={false}
													invert
												/>
											</InputRow>
										</div>
									))}
								</div>
								<Button
									invert
									icon="add"
									value="Add New Domain"
									onClick={() =>
										update((base, c) => ({
											domains: [...(c?.domains ?? base.domains), {domain: "", group: undefined}],
										}))
									}
									disabled={updating || optOut}
								/>
								<Separator horizontal />
								<P color={optOut ? "grey" : undefined}>
									Send this link out to your team to have them self-enroll:
								</P>
								<P color={optOut ? "grey" : undefined}>{link}</P>
								<InputRow>
									<Button
										invert
										border={false}
										color="black"
										icon="refresh"
										value="Refresh Link"
										onClick={handleRefresh}
										disabled={updating || optOut}
										loading={refreshing}
									/>
									<Button
										icon={showCopyIcon ? "check" : undefined}
										value="Copy Link"
										onClick={handleCopyLink}
										disabled={optOut}
									/>
								</InputRow>
							</>
						)}
					</>
				)}
			</>
		</Modal>
	);
};
