import {useCallback, useContext, useMemo, useState} from "react";
import classNames from "classnames";
import {Link, useLocation, useNavigate} from "react-router-dom";

import {CREATE_COLLECTION, useMyUser} from "../data";
import {useMutationToast, useToast} from "../toast";
import {Button, InputRow, Separator, Text} from "../components/input";
import {Icon} from "../components/images";
import {Span2, Span4} from "../components/text";
import {useDropdown} from "../components/input/dropdown";
import {Modal, useModal} from "../modals/new";
import {Loading} from "../components/loading";
import {SUGGEST_URL} from "../data/rssFeed";
import {HoverTooltip} from "../components/tooltip";
import {CREATE_SHARE_LINK} from "../data/share";
import {PostModalContext} from "../providers/PostModalProvider";

import styles from "./quick-add.module.scss";

function isValidHttpUrl(string) {
	let url;

	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
}

export const QuickAdd = () => {
	const [text, setText] = useState<string>("");
	const [error, setError] = useState<boolean>(false);
	const toast = useToast();
	const navigate = useNavigate();
	const me = useMyUser();
	const {modal, open: openModal, close} = useModal({zIndex: 1001});

	const [createCollection, {loading}] = useMutationToast(CREATE_COLLECTION, {
		onCompleted: ({createCollection: {id}}) => navigate(`/collections/${id}`),
	});
	const [suggestUrl, {loading: loadingSuggest}] = useMutationToast(SUGGEST_URL, {
		onCompleted: () => {
			setMessage("");
			setText("");
			close();
			toast({color: "green", text: "Article suggested to admins."});
		},
	});

	const [createShareLink, {loading: loadingShareLink}] = useMutationToast(CREATE_SHARE_LINK);

	const isAdmin = me.role.includes("admin");

	const validLink = text.startsWith("http") ? text : `https://${text}`;

	const {open: openPostBuilder} = useContext(PostModalContext);
	const {pathname} = useLocation();
	const onCompanyOpen = useCallback(() => {
		openPostBuilder({postType: "company"});
	}, [openPostBuilder]);
	const onPersonalOpen = useCallback(() => {
		openPostBuilder({postType: "personal"});
	}, [openPostBuilder]);

	const isPostBuilder = useMemo(() => pathname === "/collections/posts/new", [pathname]);

	const onCopy = useCallback(
		close => {
			if (!isValidHttpUrl(text)) {
				setError(true);
				return;
			} else {
				setError(false);
			}

			createShareLink({variables: {url: text}}).then(async ({data}) => {
				if (!document.hasFocus()) {
					window.focus();
				}
				navigator.clipboard
					.writeText(data.createShareLink)
					.then(() => {
						setText("");
						toast({color: "green", text: "Link copied to clipboard.", timeout: 2});
						close();
					})
					.catch(() => {
						toast({color: "red", text: "Couldn't copy link. Please refocus the window and try again."});
					});
			});
		},
		[text, setError, toast, createShareLink, setText]
	);

	const popup = useCallback(
		({close}) => {
			const PersonalPost = (
				<>
					<Icon icon="posts" color="grey" />
					<Span2 bold>New {isAdmin && "Personal"} Post</Span2>
				</>
			);
			const CompanyPost = (
				<>
					<Icon icon="office" color="grey" />
					<Span2 bold>New Company Post</Span2>
				</>
			);

			return (
				<div className={styles.quickAddContainer}>
					<div>
						<InputRow>
							<Text value={text} onChange={setText} placeholder="Paste an article link to share" />{" "}
							{text && (
								<div className="space">
									<HoverTooltip text="Copy Share Link">
										<Button
											icon="link"
											onClick={() => {
												onCopy(close);
											}}
											className={styles.shortLink}
											invert
											loading={loadingShareLink}
										/>
									</HoverTooltip>
								</div>
							)}
						</InputRow>
						{text && error && <Span4 color="red">Please enter a valid URL</Span4>}
					</div>
					{text ? (
						<InputRow>
							<Button
								value="Share"
								onClick={() => {
									if (!isValidHttpUrl(text)) {
										setError(true);
										return;
									} else {
										setError(false);
									}
									close();
									openPostBuilder({postType: "personal", url: text});
								}}
							/>
							<Button
								value="Suggest to Admins"
								onClick={() => {
									if (!isValidHttpUrl(text)) {
										setError(true);
										return;
									} else {
										setError(false);
									}
									close();
									openModal();
								}}
							/>
						</InputRow>
					) : (
						<div className={styles.links}>
							{isPostBuilder ? (
								<Link
									to="/collections/posts/new"
									onClick={close}
									state={{type: "company", askLoadUnsaved: true}}
									className={styles.quickMenuLink}
								>
									{CompanyPost}
								</Link>
							) : (
								<Span2
									onClick={() => {
										close();
										onCompanyOpen();
									}}
									className={classNames(styles.quickMenuLink, loading && styles.disabled)}
								>
									{CompanyPost}
								</Span2>
							)}

							{isPostBuilder ? (
								<Link
									to="/collections/posts/new"
									onClick={close}
									state={{type: "personal", askLoadUnsaved: true}}
									className={styles.quickMenuLink}
								>
									{PersonalPost}
								</Link>
							) : (
								<Span2
									onClick={() => {
										close();
										onPersonalOpen();
									}}
									className={classNames(styles.quickMenuLink, loading && styles.disabled)}
								>
									{PersonalPost}
								</Span2>
							)}

							{isAdmin && (
								<Span2
									onClick={createCollection}
									className={classNames(styles.quickMenuLink, loading && styles.disabled)}
								>
									{loading && <Loading position="absolute" size="small" />}
									<Icon icon="collections" color="grey" />
									<Span2 bold>New Collection</Span2>
								</Span2>
							)}
							{isAdmin && (
								<Link
									to="/contests"
									state={{new: true}}
									className={classNames(styles.quickMenuLink, loading && styles.disabled)}
								>
									<Icon icon="trophy" color="grey" />
									<Span2 bold>New Contest</Span2>
								</Link>
							)}
						</div>
					)}
				</div>
			);
		},
		[
			text,
			createCollection,
			loading,
			openModal,
			error,
			isAdmin,
			loadingShareLink,
			onCopy,
			onCompanyOpen,
			onPersonalOpen,
			openPostBuilder,
			isPostBuilder,
		]
	);

	const {open, portal, reference} = useDropdown({
		popup,
		portalClassName: styles.menu,
	});
	const [message, setMessage] = useState<string>("");
	const suggest = useCallback(() => {
		suggestUrl({
			variables: {url: validLink, comment: message},
		});
	}, [suggestUrl, message, validLink]);

	return (
		<div className={styles.quickAdd}>
			{portal}
			<HoverTooltip text="Quick add">
				<Button ref={reference} onClick={open} icon="edit" />
			</HoverTooltip>
			<Modal modal={modal} title="Suggest Article to Admins">
				<div>
					Article link:
					<br />
					<Span2>
						<a href={validLink} target="_blank" rel="noreferrer">
							{validLink}
						</a>
					</Span2>
				</div>
				<br />
				<Text
					label="Add a message to your admins"
					value={message}
					onChange={setMessage}
					type="textarea"
					autoFocus
				/>
				<Separator horizontal />
				<Button onClick={suggest} loading={loadingSuggest} value="Suggest" />
			</Modal>
		</div>
	);
};
