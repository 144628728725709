import {Fragment, useCallback, useContext, useEffect, useMemo} from "react";
import {useFormikContext} from "formik";
import classnames from "classnames";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

import {Button, InputRow} from "../../../../components/input";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";
import {FormValues} from "./types";
import {PostEditor} from "./post-editor";
import {postServices, Service, Share, useMyUser} from "../../../../data";
import {Span, Span2} from "../../../../components/text";
import {SharePreview, SocialScore} from "../../components";
import {HoverTooltip} from "../../../../components/tooltip";
import {useConfirmModal} from "../../../../modals";
import {SharingOptions} from "./sharing-options";
import {PostModalContext} from "../../../../providers/PostModalProvider";

import styles from "./styles.module.scss";

const bStyles = classnames.bind(styles);

interface EditPostFormProps {
	share: Share;
	socialScore?: {
		estimatedValue: number;
		actualValue: number;
		potential?: number;
		suggestion?: string;
	};
	disableContentEditing?: boolean;
	onClose?: () => void;
}

export const EditPostForm = ({share, socialScore, disableContentEditing, onClose}: EditPostFormProps) => {
	const {
		submitForm,
		isValid,
		values,
		isSubmitting,
		setFieldValue,
		setSubmitting,
		dirty,
	} = useFormikContext<FormValues>();
	const me = useMyUser();
	const navigate = useNavigate();
	const onEditClose = useCallback(async () => {
		await submitForm();
		if (onClose) onClose();
		else navigate("/collections/posts");
	}, [navigate, submitForm, onClose]);
	const debouncedSubmit = useDebounceCallback(submitForm, 2000);
	const {open: openPostBuilder} = useContext(PostModalContext);
	const onShareAgain = useCallback(
		() =>
			openPostBuilder({
				fromPostId: share?.id,
				postType: "personal",
			}),
		[share?.id, openPostBuilder]
	);
	const {open: openShareNowModal} = useConfirmModal(
		() => ({
			title: "Share all posts now",
			body:
				"All posts that have not already been shared will be immediately shared on social media. This action cannot be undone.",
			confirming: isSubmitting,
			onConfirm: close => {
				close();
				setSubmitting(true);

				const schedule = Object.keys(values.schedule || {}).reduce(
					(acc, network) => {
						acc[network] = {...values.schedule?.[network], scheduledFor: dayjs().add(5, "minutes")};

						return acc;
					},
					{immediately: true}
				);

				setFieldValue("schedule", schedule);
			},
		}),
		[isSubmitting, setSubmitting, setFieldValue, values]
	);

	const futureShares = useMemo(
		() =>
			share.shareEvents.filter(
				se =>
					(se.scheduledFor.isAfter() && (!se.sharedAt || !se.result)) ||
					(se.scheduledFor.isBefore() && !se.result)
			),
		[share.shareEvents]
	);

	const pastShares = useMemo(
		() => share.shareEvents.filter(se => (se.sharedAt || se.scheduledFor.isBefore()) && se.result),
		[share.shareEvents]
	);

	useEffect(() => {
		dirty && debouncedSubmit();
	}, [dirty, values, debouncedSubmit]);

	const scSuggestion = useMemo(() => {
		const messages = Object.entries(share.smartScoreSuggestions)
			.filter(([key, suggestions]) => postServices.includes(key as Service) && !!suggestions)
			.flatMap(([, suggestions]) => suggestions.map(s => s.message));

		return (
			<>
				{Array.from(new Set(messages)).map((message, index) => index === 0 && <p key={index}>{message}</p>)}
			</>
		);
	}, [share]);

	return (
		<div className={bStyles(styles.postForm, styles.editMode)}>
			<div className={styles.postBody}>
				{!!futureShares.length && (
					<div className={styles.sharesGroup}>
						<div className={styles.groupHeader}>
							<div>
								<div className={styles.titleContainer}>
									<Span className={styles.title}>Not shared yet</Span>
									<Span2>These social media posts have not been shared yet.</Span2>
								</div>
								{!!socialScore && (
									<div className={styles.socialScoreContainer}>
										<SocialScore
											socialScore={socialScore.estimatedValue ?? 0}
											potentialSocialScore={socialScore.potential}
										/>
									</div>
								)}
							</div>
							<HoverTooltip text={scSuggestion} positions={["top", "bottom"]}>
								<div className={styles.socialScoreSuggestion}>{socialScore?.suggestion}</div>
							</HoverTooltip>
						</div>
						{futureShares.map((se, index) => (
							<Fragment key={`${se.id}-${index}`}>
								<PostEditor
									shareEvent={se}
									totalShareEvents={futureShares.length}
									disableContentEditing={disableContentEditing}
								/>
								{se.network === "tiktok" && <SharingOptions />}
							</Fragment>
						))}
					</div>
				)}
				{!!futureShares.length && !!pastShares.length && <div className={styles.separator} />}
				{!!pastShares.length && (
					<div className={styles.sharesGroup}>
						<div className={styles.groupHeader}>
							<div className={styles.titleContainer}>
								<Span className={styles.title}>Shared</Span>
								<Span2>These social media posts were already shared.</Span2>
							</div>
							{!!socialScore && (
								<div className={styles.socialScoreContainer}>
									<SocialScore
										socialScore={socialScore.actualValue ?? 0}
										potentialSocialScore={socialScore.potential}
									/>
								</div>
							)}
						</div>
						{pastShares.map((se, index) => (
							<SharePreview key={`${se.id}-${index}`} shareEvent={se} share={share} />
						))}
					</div>
				)}
			</div>
			<InputRow className={styles.formBottomToolbar} position={"between"}>
				<Button value={"Close"} onClick={onEditClose} invert />
				<InputRow>
					{!!pastShares.length && !me.org?.options?.blockDuplicatePost && (
						<Button value={"Share Again"} invert onClick={onShareAgain} loading={isSubmitting} />
					)}
					<Button
						value={"Share Now"}
						onClick={openShareNowModal}
						loading={isSubmitting}
						disabled={!isValid}
					/>
				</InputRow>
			</InputRow>
		</div>
	);
};
