/* eslint-disable import/order */
import {StrictMode, useEffect} from "react";
import ReactDOM from "react-dom/client";
import {createRoutesFromChildren, matchRoutes, useNavigationType} from "react-router-dom";
import {useLocation} from "react-router";

import {ApolloClient, ApolloProvider, from} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import * as Sentry from "@sentry/react";

// Up here so these rules are loaded first and have less precedence.
import "./index.scss";

import {ModalProvider} from "./modals";
import {ToastProvider} from "./toast";
import {config} from "./config";

import {PageProvider, AppRouterProvider} from "./layout";
import {cache} from "./cache";

import "./dayjs";
import "./chart.js";
import {AppcuesProvider} from "./providers/AppcuesProvider";
import {GainsightProvider} from "./providers/GainsightProvider";
import {NewrelicProvider} from "./providers/NewrelicProvider";

declare global {
	interface String {
		uFirst(): string;
	}
}

String.prototype.uFirst = function (this: string): string {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

Sentry.init({
	...config.sentry,
	environment: process.env.REACT_APP_ENV || "dev",
	integrations: [
		Sentry.browserTracingIntegration({tracePropagationTargets: config.tracePropagationTargets}),
		// Sentry.replayIntegration(),
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
	],
});

const errorLink = onError(({graphQLErrors}) => {
	if (
		graphQLErrors?.some(error => error?.extensions?.code === "UNAUTHENTICATED") &&
		window.location.pathname !== "/login"
	) {
		window.location.href = "/login";
	}
});

const transportLink = createUploadLink({
	uri: config.graphqlUrl,
	credentials: "include",
});
const client = new ApolloClient({
	link: from([errorLink, transportLink]),
	cache,
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<StrictMode>
		<ApolloProvider client={client}>
			<QueryClientProvider client={queryClient}>
				<ToastProvider>
					<NewrelicProvider>
						<ModalProvider>
							<DndProvider backend={HTML5Backend}>
								<PageProvider>
									<AppcuesProvider>
										<GainsightProvider>
											<AppRouterProvider />
										</GainsightProvider>
									</AppcuesProvider>
								</PageProvider>
							</DndProvider>
						</ModalProvider>
					</NewrelicProvider>
				</ToastProvider>
			</QueryClientProvider>
		</ApolloProvider>
	</StrictMode>
);
